<template>
  <div class="modal-mask">
    <div id="critical-builder-modal" class="modal-wrapper" aria-labelledby="critical_load_builder" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="closeForm()" type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Critical Load Builder</h4>
          </div>
          <div class="modal-body">
            <div id="critical-load-builder">
              <critical-build-form :isModal='true'
                v-on:update-selected-critical-build="emitUpdateSelectedCriticalBuild">
              </critical-build-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CriticalBuildForm from './CriticalBuildForm'

  export default {
    components: {
      CriticalBuildForm: CriticalBuildForm
    },

    computed: {
      ...mapGetters([
        'showCriticalBuildForm'
      ])
    },

    methods: {
      closeForm: function() {
        this.$store.commit('updateObject', { object: 'showCriticalBuildForm', value: false });
      },
      emitUpdateSelectedCriticalBuild: function(event, payload) {
        this.$emit('update-selected-critical-build', event, payload);
      }
    }
  }
</script>
