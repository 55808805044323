<template>
  <div style="padding-left: 10px; padding-right: 10px;">
    <select
      v-model="run[association_1][association_2][attribute]"
      :disabled="isDisabled"
      @change="$emit('input-change')"
      @blur="validate()"
      class="form-control select optional"
      :name="inputName"
      :id="inputID">
      <option v-for="option in options" :value="option['value']">
        {{ option['label'] }}
      </option>
    </select>
    <p v-if="attribute == 'doe_reference_name'" class="building-details">
      <a href="#" rel="tooltip" :title="tooltips.site.load_profile.building_details" target="_blank">
        Building Details
      </a>
    </p>
    <span v-if="validateError" class="help-block">
      {{ validateErrorMessage }}
    </span>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  
  export default {
    components: {
      Tooltip
    },
  
    props: [
      'association_1',
      'association_2',
      'attribute',
      'required',
      'index',
      'run'
    ],
  
    data: function() {
      return {
        validateError: false,
      }
    },
  
    computed: {
      ...mapGetters([
        'runsClientSideValidations',
        'labels',
        'portfolio',
        'selectOptions',
        'tooltips'
      ]),
      label: function() {
        return this.labels[this.association_1][this.association_2][this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.attribute}`;
      },
      isDisabled: function() {
        if (this.association_2 == "pv" && (this.attribute == "module_type" || this.attribute == "array_type" || this.attribute == "macrs_option_years" || this.attribute == "macrs_bonus_fraction") && this.index != 0) {
          return true
        } else if (this.association_2 == "storage" && (this.attribute == "can_grid_charge" || this.attribute == "macrs_option_years" || this.attribute == "macrs_bonus_fraction") && this.index != 0) {
          return true
        } else {
          return false
        }
      },
      inputID: function() {
        if (this.index >= 0) {
          return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}_${this.index + 1}`;
        } else {
          return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}`;
        }
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.attribute}]`;
      },
      options: function() {
        return this.selectOptions[this.association_1][this.association_2][this.attribute]["options"];
      },
      validateErrorMessage: function() {
        return this.portfolio.runsClientSideValidations.run[this.association_1][this.association_2][this.attribute];
      },
    },
  
    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.association_2][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.association_2][this.attribute]) {
          this.validateError = false;
        }
      }
    },
  
    watch: {
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[this.index].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[this.index].classList.remove('has-error');
        }
      }
    }
  }
</script>
  