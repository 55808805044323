<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_installed_cost_per_kw">
            {{ labels.site.pv.installed_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="installed_cost_per_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='installed_cost_per_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- this for one input that shows up for all runs -->
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='installed_cost_per_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3"></th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <input
          v-model="run.site.pv.existing_pv"
          @click="toggleDisableExistingKw(index)"
          type="checkbox"
          :id="`existing_pv_chk_${index}`" />
        <label style="font-weight: normal" :for="`existing_pv_chk_${index}`">
          Existing PV system?
        </label>
      </td>
      <td class="col-sm-3"></td>
    </tr>
    <br>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_existing_kw">
            {{ labels.site.pv.existing_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="existing_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='existing_kw'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_min_kw">
            {{ labels.site.pv.min_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="min_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='min_kw'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_max_kw">
            {{ labels.site.pv.max_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="max_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='max_kw'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Advanced inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">PV Costs</h4>
      </td>
    </tr>
    <br>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_om_cost_per_kw">
            {{ labels.site.pv.om_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="om_cost_per_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='om_cost_per_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='om_cost_per_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">PV System Characteristics</h4>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_module_type">
            {{ labels.site.pv.module_type }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="module_type" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_module_type">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='module_type'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_pv_module_type">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='module_type'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_array_type">
            {{ labels.site.pv.array_type }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="array_type" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_array_type">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='array_type'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_pv_array_type">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='array_type'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_azimuth">
            {{ labels.site.pv.azimuth }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="azimuth" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='azimuth'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_tilt">
            {{ labels.site.pv.tilt }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="tilt" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='tilt'
          :index="index"
          :run="portfolioRun" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='tilt'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_dc_ac_ratio">
            {{ labels.site.pv.dc_ac_ratio }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="dc_ac_ratio" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='dc_ac_ratio'
          :index="index"
          :run="portfolioRun" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='dc_ac_ratio'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_losses">
            {{ labels.site.pv.losses }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="losses" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='losses'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='losses'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_radius">
            {{ labels.site.pv.radius }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="radius" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='radius'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='radius'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">PV Incentives and Tax Treatment</h4>
        <h5 style="font-weight: lighter;">
          Capital Cost or System Size Based Incentives
          <tooltip 
            association_1="incentives" 
            attribute="capital_costs" />
        </h5>
        <a target="blank" style="font-size: 12px" href="http://www.dsireusa.org/">Database of state incentives for renewables</a>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td>
        <portfolio-incentives-table
          association_1='site'
          association_2='pv' />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5 style="font-weight: lighter;">
          Production Based Incentives
          <a
            href="#"
            rel="tooltip"
            title="Federal, State, and Utility production incentives can be entered here. If there is more than one production based incentive offered, the combined value can be entered and should be discounted back to year one if the incentive duration differs. For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </a>
        </h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td>
        <portfolio-incentives-table-2
          association_1='site'
          association_2='pv' />
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5 style="font-weight: lighter;">Tax Treatment</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_macrs_option_years">
            {{ labels.site.pv.macrs_option_years }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="macrs_option_years" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_macrs_option_years">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_option_years'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_pv_macrs_option_years">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_option_years'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_macrs_bonus_fraction">
            {{ labels.site.pv.macrs_bonus_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="macrs_bonus_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_macrs_bonus_fraction">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_pv_macrs_bonus_fraction">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';
  import PortfolioSelectInputNested2 from './inputs/PortfolioSelectInputNested2';
  import PortfolioIncentivesTable from './inputs/PortfolioIncentivesTable';
  import PortfolioIncentivesTable2 from './inputs/PortfolioIncentivesTable2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
      PortfolioSelectInputNested2,
      PortfolioIncentivesTable,
      PortfolioIncentivesTable2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'year',
        'portfolioRun',
        'portfolio'
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },

      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'pv',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      updateTilt: function() {
        if(this.portfolioRun.site.pv.array_type == "Ground Mount, Fixed" || this.portfolioRun.site.pv.array_type == "Rooftop, Fixed") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'tilt', value: "20" });
        } else if(this.portfolioRun.site.pv.array_type == "Ground Mount, 1-Axis Tracking") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'tilt', value: "0" });
        }
      },

      toggleDisableExistingKw: function(index) {
        if($(`#existing_pv_chk_${index}`)[0].checked) {
          $(`#run_site_attributes_pv_attributes_existing_kw_${index + 1}`).attr('disabled', false);
        } else {
          $(`#run_site_attributes_pv_attributes_existing_kw_${index + 1}`).attr('disabled', true);
          this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'pv', attribute: 'existing_kw', value: '', index: index });
        }
      },
    },

    watch: {
      'portfolioRun.site.pv.installed_cost_per_kw': function() {
        this.updateInputValueForRuns('installed_cost_per_kw', this.portfolioRun.site.pv.installed_cost_per_kw);
      },

      'portfolioRun.site.pv.om_cost_per_kw': function() {
        this.updateInputValueForRuns('om_cost_per_kw', this.portfolioRun.site.pv.om_cost_per_kw);
      },

      'portfolioRun.site.pv.module_type': function() {
        this.updateInputValueForRuns('module_type', this.portfolioRun.site.pv.module_type);
      },

      'portfolioRun.site.pv.array_type': function() {
        this.updateInputValueForRuns('array_type', this.portfolioRun.site.pv.array_type);
        this.updateTilt();
      },

      'portfolioRun.site.pv.dc_ac_ratio': function() {
        this.updateInputValueForRuns('dc_ac_ratio', this.portfolioRun.site.pv.dc_ac_ratio);
      },

      'portfolioRun.site.pv.losses': function() {
        this.updateInputValueForRuns('losses', this.portfolioRun.site.pv.losses);
      },

      'portfolioRun.site.pv.azimuth': function() {
        this.updateInputValueForRuns('azimuth', this.portfolioRun.site.pv.azimuth);
      },

      'portfolioRun.site.pv.tilt': function() {
        this.updateInputValueForRuns('tilt', this.portfolioRun.site.pv.tilt);
      },

      'portfolioRun.site.pv.radius': function() {
        this.updateInputValueForRuns('radius', this.portfolioRun.site.pv.radius);
      },

      'portfolioRun.site.pv.federal_itc_fraction': function() {
        this.updateInputValueForRuns('federal_itc_fraction', this.portfolioRun.site.pv.federal_itc_fraction);
      },

      'portfolioRun.site.pv.federal_rebate_per_kw': function() {
        this.updateInputValueForRuns('federal_rebate_per_kw', this.portfolioRun.site.pv.federal_rebate_per_kw);
      },

      'portfolioRun.site.pv.state_ibi_fraction': function() {
        this.updateInputValueForRuns('state_ibi_fraction', this.portfolioRun.site.pv.state_ibi_fraction);
      },

      'portfolioRun.site.pv.state_ibi_max': function() {
        this.updateInputValueForRuns('state_ibi_max', this.portfolioRun.site.pv.state_ibi_max);
      },

      'portfolioRun.site.pv.state_rebate_per_kw': function() {
        this.updateInputValueForRuns('state_rebate_per_kw', this.portfolioRun.site.pv.state_rebate_per_kw);
      },

      'portfolioRun.site.pv.state_rebate_max': function() {
        this.updateInputValueForRuns('state_rebate_max', this.portfolioRun.site.pv.state_rebate_max);
      },

      'portfolioRun.site.pv.utility_ibi_fraction': function() {
        this.updateInputValueForRuns('utility_ibi_fraction', this.portfolioRun.site.pv.utility_ibi_fraction);
      },

      'portfolioRun.site.pv.utility_ibi_max': function() {
        this.updateInputValueForRuns('utility_ibi_max', this.portfolioRun.site.pv.utility_ibi_max);
      },

      'portfolioRun.site.pv.utility_rebate_per_kw': function() {
        this.updateInputValueForRuns('utility_rebate_per_kw', this.portfolioRun.site.pv.utility_rebate_per_kw);
      },

      'portfolioRun.site.pv.utility_rebate_max': function() {
        this.updateInputValueForRuns('utility_rebate_max', this.portfolioRun.site.pv.utility_rebate_max);
      },

      'portfolioRun.site.pv.production_incentive_per_kwh': function() {
        this.updateInputValueForRuns('production_incentive_per_kwh', this.portfolioRun.site.pv.production_incentive_per_kwh);
      },

      'portfolioRun.site.pv.pbi_years': function() {
        this.updateInputValueForRuns('pbi_years', this.portfolioRun.site.pv.pbi_years);
      },

      'portfolioRun.site.pv.production_incentive_max_benefit': function() {
        this.updateInputValueForRuns('production_incentive_max_benefit', this.portfolioRun.site.pv.production_incentive_max_benefit);
      },

      'portfolioRun.site.pv.pbi_system_max_kw': function() {
        this.updateInputValueForRuns('pbi_system_max_kw', this.portfolioRun.site.pv.pbi_system_max_kw);
      },

      'portfolioRun.site.pv.macrs_option_years': function() {
        this.updateInputValueForRuns('macrs_option_years', this.portfolioRun.site.pv.macrs_option_years);
      },

      'portfolioRun.site.pv.macrs_bonus_fraction': function() {
        this.updateInputValueForRuns('macrs_bonus_fraction', this.portfolioRun.site.pv.macrs_bonus_fraction);
      },
    },

    mounted: function() {
      this.updateTilt();
    }
  }
</script>
