<template>
  <div id="ghp-cop-performance" class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="closeTable()" type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">GHP Coefficient of Performance Table</h4>
          </div>
          <div class="modal-body">
            <table>
              <thead>
                <th>Entering Fluid Temperature (F&deg;)</th>
                <th>Cooling COP (kWt/kWe)</th>
                <th>Heating COP (kWt/kWe)</th>
              </thead>
              <tbody>
                <tr v-for="(temp, i) in ghpCOPCustomData.fluidTemp">
                  <td class="fluid-temp">{{ temp }}</td>
                  <td class="cooling-cop">{{ ghpCOPCustomData.coolingCOP[i] }}</td>
                  <td class="heating-cop">{{ ghpCOPCustomData.heatingCOP[i] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters([
        'ghpCOPCustomData'
      ])
    },

    methods: {
      closeTable: function() {
        this.$store.commit('updateObject', { object: 'showGHPCOPCustomTable', value: false });
      }
    }
  }
</script>
