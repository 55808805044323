<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_analysis_years">
            {{ labels.site.financial.analysis_years }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="analysis_years" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='analysis_years'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='analysis_years'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_offtaker_discount_rate_fraction">
            {{ labels.site.financial.offtaker_discount_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="offtaker_discount_rate_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_discount_rate_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_discount_rate_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_elec_cost_escalation_rate_fraction">
            {{ labels.site.financial.elec_cost_escalation_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="elec_cost_escalation_rate_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='elec_cost_escalation_rate_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='elec_cost_escalation_rate_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3"></th>
      <td colspan="2" class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed; text-align: left;">
        <input
          v-model="portfolioRun.site.financial.third_party_ownership"
          class="checkbox-inline"
          type="checkbox"
          value="1"
          name="run[site_attributes][financial_attributes][third_party_ownership]"
          id="run_site_attributes_financial_attributes_third_party_ownership" />
        <label class="custom-electricity-rate-label" for="run_site_attributes_financial_attributes_third_party_ownership">Use third-party ownership model</label>
        <tooltip
          :association_1="'site'"
          :association_2="'financial'"
          :attribute="'third_party_ownership'" />
      </td>
    </tr>
    <br><br>
    <tr>
      <th class="col-sm-3" style="padding-left: 5px;" v-if="portfolioRun.site.financial.third_party_ownership">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_owner_discount_rate_fraction">
            {{ labels.site.financial.owner_discount_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="owner_discount_rate_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="portfolioRun.site.financial.third_party_ownership"
          association_1='site'
          association_2="financial"
          attribute='owner_discount_rate_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" v-if="portfolioRun.site.financial.third_party_ownership" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2='financial'
          attribute='owner_discount_rate_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3" v-if="portfolioRun.site.financial.third_party_ownership">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_owner_tax_rate_fraction">
            {{ labels.site.financial.owner_tax_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="owner_tax_rate_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="portfolioRun.site.financial.third_party_ownership"
          association_1='site'
          association_2="financial"
          attribute='owner_tax_rate_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" v-if="portfolioRun.site.financial.third_party_ownership" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2='financial'
          attribute='owner_tax_rate_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_offtaker_tax_rate_fraction">
            {{ labels.site.financial.offtaker_tax_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="offtaker_tax_rate_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_tax_rate_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_tax_rate_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_financial_om_cost_escalation_rate_fraction">
            {{ labels.site.financial.om_cost_escalation_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="om_cost_escalation_rate_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='om_cost_escalation_rate_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='om_cost_escalation_rate_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
    },

    data: function() {
      return {}
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'portfolioRun',
        'portfolio'
      ]),
    },

    methods: {
      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'financial',
            attribute: attr,
            value: value,
            index: i,
          }

          self.$store.commit('updateRunsValues', payload);
        })
      },
    },

    watch: {
      'portfolioRun.site.financial.analysis_years': function() {
        this.updateInputValueForRuns("analysis_years", this.portfolioRun.site.financial.analysis_years);
      },
      'portfolioRun.site.financial.offtaker_discount_rate_fraction': function() {
        this.updateInputValueForRuns("offtaker_discount_rate_fraction", this.portfolioRun.site.financial.offtaker_discount_rate_fraction);
      },
      'portfolioRun.site.financial.elec_cost_escalation_rate_fraction': function() {;
        this.updateInputValueForRuns("elec_cost_escalation_rate_fraction", this.portfolioRun.site.financial.elec_cost_escalation_rate_fraction);
      },
      'portfolioRun.site.financial.third_party_ownership': function() {
        this.updateInputValueForRuns("third_party_ownership", this.portfolioRun.site.financial.third_party_ownership);
      },
      'portfolioRun.site.financial.owner_discount_rate_fraction': function() {
        this.updateInputValueForRuns("owner_discount_rate_fraction", this.portfolioRun.site.financial.owner_discount_rate_fraction);
      },
      'portfolioRun.site.financial.owner_tax_rate_fraction': function() {
        this.updateInputValueForRuns("owner_tax_rate_fraction", this.portfolioRun.site.financial.owner_tax_rate_fraction);
      },
      'portfolioRun.site.financial.offtaker_tax_rate_fraction': function() {
        this.updateInputValueForRuns("offtaker_tax_rate_fraction", this.portfolioRun.site.financial.offtaker_tax_rate_fraction);
      },
      'portfolioRun.site.financial.om_cost_escalation_rate_fraction': function() {
        this.updateInputValueForRuns("om_cost_escalation_rate_fraction", this.portfolioRun.site.financial.om_cost_escalation_rate_fraction);
      },
    },
  }
</script>
