<template>
  <div class="form-group select optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="select optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip
        :association_1="this.association_1"
        :association_2="this.association_2"
        :attribute="this.attribute" />
    </div>
    <div class="col-sm-4">
      <select
        v-model="run[association_1][association_2][attribute]"
        @change="$emit('input-change')"
        @blur="validate()"
        :disabled="isDisabled"
        class="form-control select optional"
        v-bind:class="{ 'disabled': isDisabled }"
        :name="inputName"
        :id="inputID">
        <option v-for="option in options" :value="option['value']">
          {{ option['label'] }}
        </option>
        <option v-if="attribute == 'prime_mover_type' && run.site.chp.boiler.existing_boiler_production_type == 'steam'" value='steam_turbine' >
          Steam turbine
        </option>
      </select>
      <p v-if="attribute == 'doe_reference_name'" class="building-details">
        <a href="#" rel="tooltip" :title="tooltips.site.load_profile.building_details" target="_blank">
          Building Details
        </a>
      </p>
      <span v-if="validateError" class="help-block">
        {{ validateErrorMessage }}
      </span>
      <p v-else-if="attribute == 'emissions_factor_hourly_source'" class="help-block">
        {{ emissions_factor_hourly_source_default }}
      </p>
      <p v-else-if="((association_2 == 'chp') || association_2 == 'prime_generator') && attribute == 'size_class' && chpHeuristicSize" class="help-block">
        Electric capacity sized based on the average load for all 8,760 hours of the year = {{ formattedCHPMaxSize }} kW (Size Class {{ chpHeuristicSizeClass }})
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'association_1',
      'association_2',
      'attribute',
      'required',
    ],

    data: function() {
      return {
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'chpHeuristicSize',
        'chpHeuristicSizeClass',
        'clientSideValidations',
        'labels',
        'run',
        'selectOptions',
        'tooltips',
        'placeholders'
      ]),
      label: function() {
        return this.labels[this.association_1][this.association_2][this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.attribute}]`;
      },
      options: function() {
        return this.selectOptions[this.association_1][this.association_2][this.attribute]["options"];
      },
      emissions_factor_hourly_source_default: function() {
        if(this.placeholders.site.electric_tariff.emissions_factor_hourly_source) {
          return `default = US EPA AVERT ${this.placeholders.site.electric_tariff.emissions_factor_hourly_source} Region`;
        } else {
          return '';
        }
      },
      isDisabled: function() {
        if ((this.association_2 == "chp" || this.association_2 == "prime_generator") && (this.attribute == "prime_mover_type" || this.attribute == "size_class")) {
          if (this.run.site.chp.custom_prime_mover || this.run.site.prime_generator.custom_prime_mover) {
            return false
          } else {
            return true
          }
        } else if(this.association_2 == "ghp" && this.attribute == "hybrid_ghx_sizing_method") {
          if (this.ratioHeatingToCooling >= 0.1 && this.ratioHeatingToCooling <= 10) {
            return false
          } else {
            return true
          }
        } else {
          return false;
        }
      },
      validateErrorMessage: function() {
        return this.clientSideValidations.run[this.association_1][this.association_2][this.attribute];
      },
      formattedCHPMaxSize: function() {
        return this.$store.getters.formatAsIntegerWithCommas({ value: this.chpHeuristicSize, decimals: 0 });
      },
    },

    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.association_2][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.association_2][this.attribute]) {
          this.validateError = false;
        }
      }
    },

    watch: {
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    }
  }
</script>
