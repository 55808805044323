<template>
  <div class="form-group grouped_select required run_site_electric_tariff_urdb_label">
    <div class="col-sm-6 control-label">
      <label class="grouped_select required" for="run_site_attributes_electric_tariff_attributes_urdb_label">
        <abbr title="Required" class="required">*</abbr>
        Electricity rate
      </label>
      <tooltip
        association_1="site"
        association_2="electric_tariff"
        attribute="urdb_label" />
    </div>
    <div class="col-sm-4">
      <div v-if="utilityRatesLoading">
        <span class="fas fa-sync-alt fa-spin" aria-hidden="true"></span>
        Loading utility rate options...
      </div>
      <select
        v-else-if="utilityRates.length == 0 && !checkedForRates && !run.site.electric_tariff.custom_electricity_rate"
        @blur="validate()"
        class="form-control grouped_select required"
        required="required"
        aria-required="true"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]">
        <option value=""></option>
        <optgroup label="Enter a Site location above first"></optgroup>
      </select>
      <select
        v-else-if="utilityRates.length == 0 && checkedForRates && !run.site.electric_tariff.custom_electricity_rate"
        @blur="validate()"
        disabled
        class="form-control grouped_select required"
        required="required"
        aria-required="true"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]">
        <option value=""></option>
      </select>
      <select
        v-else
        v-model="run.site.electric_tariff.urdb_label"
        @blur="validate()"
        :disabled="run.site.electric_tariff.custom_electricity_rate"
        class="form-control grouped_select required"
        required="required"
        aria-required="true"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]">
        <option value=""></option>
        <optgroup v-for="array in utilityRates" :label="array[0]">
          <option v-for="rate in array[1]" :value="rate[1]">
            {{ rate[0] }}
          </option>
        </optgroup>
      </select>
      <a
        v-if="run.site.electric_tariff.urdb_label"
        target="_blank" rel="noopener noreferrer"
        style="font-size:12px;"
        :href="rateUrl">
        Rate Details
      </a>
      <div v-if="utilityRates.length == 0 && checkedForRates && !run.site.electric_tariff.custom_electricity_rate" class="has-error">
        <p class="help-block">
          No electricity rates were found for the location you entered. Please try another location nearby.
        </p>
      </div>
    </div>
    <div v-if="validateError" class="col-sm-2 help-block">
      {{ validateErrorMessage }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    data: function() {
      return {
        checkedForRates: false,
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'utilityRates',
        'utilityRatesLoading',
        'run',
        'urlRoot'
      ]),
      validateErrorMessage: function() {
        return this.clientSideValidations.run.site.electric_tariff.urdb_label;
      },
      rateUrl: function() {
        return `https://openei.org/apps/IURDB/rate/view/${this.run.site.electric_tariff.urdb_label}`;
      }
    },

    methods: {
      validate: function() {
        if(!this.run.site.electric_tariff.urdb_label) {
          this.validateError = true;
        } else if(this.run.site.electric_tariff.urdb_label) {
          this.validateError = false;
        }
      },
      fetchUtilityRates: function() {
        this.$store.commit('updateObject', { object: 'utilityRatesLoading', value: true });

        var self = this;
        $.ajax({
          url: self.urlRoot + '/utility-rates',
          data: {
            latitude: self.run.site.latitude,
            longitude: self.run.site.longitude,
            location: self.run.site.address
          },
          success: function handleResults(data) {
            self.$store.commit('updateObject', { object: 'utilityRates', value: data });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });
            self.checkedForRates = true;
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'utilityRates', value: [] });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });

            alert('An unexpected error occurred while fetching the utility rates. Please try again or contact us for assistance.');
          },
        })
      }
    },

    watch: {
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName('run_site_electric_tariff_urdb_label')[0].classList.add('has-error');
        } else {
          document.getElementsByClassName('run_site_electric_tariff_urdb_label')[0].classList.remove('has-error');
        }
      },
      'run.site.electric_tariff.custom_electricity_rate': function() {
        if(!this.run.site.electric_tariff.custom_electricity_rate && !this.run.site.electric_tariff.urdb_label) {
          document.getElementsByClassName('run_site_electric_tariff_urdb_label')[0].classList.add('has-error');
          this.validateError = true;
        } else {
          document.getElementsByClassName('run_site_electric_tariff_urdb_label')[0].classList.remove('has-error');
          this.validateError = false;
        }
      }
    },

    mounted: function() {
      if(this.run.site.electric_tariff.urdb_label) {
        this.fetchUtilityRates();
      }
    }
  }
</script>
