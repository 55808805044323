<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div style="padding-left: 10px; padding-right: 10px;">
      <input
        v-model="portfolioRun[attribute]"
        :disabled="isDisabled"
        @change="$emit('input-change');"
        class="form-control string optional"
        :placeholder="placeholder"
        type="text"
        :name="inputName"
        :id="inputID" />
    </div>
    <div v-if="placeholder && displayDefault" class="col-sm-2 default-value-block">
      <div
        class="default-value"
        :data-default-value="placeholder"
        :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'attribute',
      'index',
    ],

    data: function() {
      return {
        displayDefault: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'placeholders',
        'run',
        'portfolioRun',
        'multiRunParams',
      ]),
      property :function() {
        return this.portfolioRun[this.attribute];
      },
      isDisabled: function() {
        if (this.attribute == 'optimality_tolerance_techs' && this.index != 0) {
          return true
        } else {
          return false
        }
      },
      label: function() {
        return this.labels[this.attribute];
      },
      placeholder: function() {
        return this.placeholders[this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.attribute}`;
      },
      inputID: function() {
        if (this.index >= 0) {
          return `run_attributes_${this.attribute}_${this.index + 1}`;
        } else {
          return `run_attributes_${this.attribute}`;
        }
        
      },
      inputName: function() {
        return `run[${this.attribute}]`;
      }
    },

    watch: {
      'property': function() {
        if(this.property) {
          this.displayDefault = true;
        } else {
          this.displayDefault = false;
        }
      }
    },

    mounted: function() {
      // needed for when user enters value, then section disappears, then reappears
      // should display value in field & `default = XYZ` in side column
      if(this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    }
  }
</script>
  