<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div style="padding-left: 10px; padding-right: 10px;">
      <input
        v-model="run[association_1][attribute]"
        @change="$emit('input-change');"
        @focus="$emit('focus')"
        @blur="validate()"
        class="form-control string optional"
        :placeholder="placeholder"
        type="text"
        :name="inputName"
        :id="inputID" />
        <span v-if="validateError" class="help-block">
          {{ validateErrorMessage }}
        </span>
    </div>
    <!-- <div v-if="run.single_run && attribute == 'address'" class="col-sm-2">
      <div class="form-control-static">
        <a :href="sampleSiteParams"
          @click="useSampleSite()"
          class="sample-site hint-link no-visit-color">
          <i class="fas fa-globe-americas" aria-hidden="true"></i>Use sample site
        </a>
      </div>
    </div> -->
    <div v-if="displayInputDefault" class="default-value-block" style="display: block;">
      <div
        class="default-value"
        :data-default-value="placeholder"
        :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  
  export default {
    components: {
      Tooltip
    },
  
    props: [
      'association_1',
      'attribute',
      'required',
      'index',
      'run'
    ],
  
    data: function() {
      return {
        displayDefault: false,
        validateError: false,
      }
    },
  
    computed: {
      ...mapGetters([
        'runsClientSideValidations',
        'labels',
        'placeholders',
        'runsPlaceholders',
        // 'sampleSiteParams',
      ]),
      property :function() {
        return this.run[this.association_1][this.attribute];
      },
      label: function() {
        return this.labels[this.association_1][this.attribute];
      },
      placeholder: function() {
        if (this.attribute == 'description') {
          return this.runsPlaceholders[this.index][this.association_1][this.attribute];
        } else {
          return this.placeholders[this.association_1][this.attribute];
        }
        
      },
        formGroupClass: function() {
        return `run_${this.association_1}_${this.attribute}`;
      },
        inputID: function() {
        return `run_${this.association_1}_attributes_${this.attribute}_${this.index + 1}`;
      },
        inputName: function() {
        return `run[${this.association_1}_attributes][${this.attribute}]`;
      },
        displayInputDefault: function() {
        return this.placeholder && this.displayDefault && this.attribute != 'description' && this.attribute != 'address';
      },
      validateErrorMessage: function() {
        return this.runsClientSideValidations.run[this.association_1][this.attribute];
      }
    },
  
    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.attribute]) {
          this.validateError = false;
        }
      },
      // useSampleSite: function() {
      //   var confirmText = 'Are you sure you want to replace all the inputs with a sample site?\n\n*PV, Battery, and CHP (if selected) will be the only technologies selected for the sample site. Other technology inputs will be lost.'
      //   if(!confirm(confirmText)) {
      //     return false;
      //   }
  
      //   // If you're already on the sample site link, we have to manually force a
      //   // reload of the page, since otherwise the page won't normally reload (due
      //   // to the anchor tag, it thinks it just needs to scroll you to the anchor).
      //   // So handle the link click manually so we can deal with clicking on the
      //   // sample site link once you're already there.
      //   // var href = $(this).attr('href');
      //   if(window.location.href.indexOf(this.sampleSiteParams) !== -1) {
      //     window.location.reload(true);
      //   } else {
      //     window.location.href = this.sampleSiteParams;
      //   }
      // }
    },
  
    watch: {
      'property': function() {
        if(this.property) {
          this.displayDefault = true;
        } else {
          this.displayDefault = false;
        }
      },
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[this.index].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[this.index].classList.remove('has-error');
        }
      }
    },
  
    mounted: function() {
      // needed for when user enters value, then section disappears, then reappears
      // should display value in field & `default = XYZ` in side column
      if(this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    }
  }
</script>
  