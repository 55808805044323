<template>
  <table class="fixed-incentive" style="width: 190%;">
    <tr>
      <th class="col-sm-3"></th>
      <th class="col-sm-3">
        Incentive based on percentage of cost (%)
        <tooltip
          association_1="incentives"
          attribute="federal_itc_fraction" />
      </th>
      <th class="col-sm-3">
        Maximum dollar amount for incentive based on percentage of cost ($)
        <tooltip
          association_1="incentives"
          attribute="federal_ibi_max_us_dollars" />
      </th>
      <th class="col-sm-3">
        Rebate based on system size ($/ton)
        <tooltip
          association_1="incentives"
          attribute="federal_rebate_per_kw" />
      </th>          
      <th class="col-sm-3">
        Maximum dollar amount for rebate based on system size ($)
        <tooltip
          association_1="incentives"
          attribute="federal_rebate_max_us_dollars" />
      </th>
    </tr>
    <tr>
      <th class="col-sm-3">
        <label class="control-label"> Federal </label>
      </th>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="federal_itc_fraction" />
      </td>
      <td class="col-sm-3">
        <p class="control-label help-block" style="text-align: center;">Unlimited</p>
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="federal_rebate_per_kw" />
      </td>
      <td class="col-sm-3">
        <p class="control-label help-block" style="text-align: center;">Unlimited</p>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <label class="control-label"> State </label>
      </th>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="state_ibi_fraction" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="state_ibi_max" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="state_rebate_per_kw" />         
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="state_rebate_max" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <label class="control-label"> Utility </label>
      </th>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="utility_ibi_fraction" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="utility_ibi_max" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="utility_rebate_per_kw" />           
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="utility_rebate_max" />
      </td>
    </tr>
  </table>  
</template>

<script>
  import Tooltip from "../Tooltip";
  import PortfolioIncentivesTableStringInputNested2 from "./PortfolioIncentivesTableStringInputNested2";

  export default {
  components: {
    Tooltip,
    PortfolioIncentivesTableStringInputNested2,
  },

  props: ["association_1", "association_2"],
  }
</script>