<template>
  <div class="form-horizontal">
    <h4>Absorption Chilling</h4>
    <horizontal-string-input-nested-3
      association_1='site'
      association_2='chp'
      association_3='absorption_chiller'
      attribute='min_ton' />
    <horizontal-string-input-nested-3
      association_1='site'
      association_2='chp'
      association_3='absorption_chiller'
      attribute='max_ton' />
    <horizontal-string-input-nested-3
      association_1='site'
      association_2='chp'
      association_3='absorption_chiller'
      attribute='installed_cost_per_ton' />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>Absorption Chiller Costs</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-3
          association_1='site'
          association_2='chp'
          association_3='absorption_chiller'
          attribute='om_cost_per_ton' />
      </div>

      <h4>Absorption Chiller System Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-3
          association_1='site'
          association_2='chp'
          association_3='absorption_chiller'
          attribute='cop_thermal' />
        <horizontal-string-input-nested-3
          association_1='site'
          association_2='chp'
          association_3='absorption_chiller'
          attribute='cop_electric' />
      </div>

      <h5>Tax Treatment</h5>
      <div class="form-horizontal">
        <horizontal-select-input-nested-3
          association_1='site'
          association_2='chp'
          association_3='absorption_chiller'
          attribute='macrs_option_years' />
        <horizontal-select-input-nested-3
          association_1='site'
          association_2='chp'
          association_3='absorption_chiller'
          attribute='macrs_bonus_fraction' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  import HorizontalSelectInputNested3 from './inputs/HorizontalSelectInputNested3';
  import Tooltip from './Tooltip'

  export default {
    components: {
      // nested 3 b/c run.site.chp.absorption_chiller - measured from run
      HorizontalStringInputNested3,
      HorizontalSelectInputNested3,
      Tooltip
    },

    data: function() {
      return {
        displayAdvancedInputs: false
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'selectOptions'
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      resetInputs: function(event) {
        if(confirm("Are you sure you want to reset the inputs in the Absorption Chiller section to their default values?")) {
          if(this.run.site.chp.absorption_chiller.min_ton) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'min_ton', value: '' });
          if(this.run.site.chp.absorption_chiller.max_ton) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'max_ton', value: '' });
          if(this.run.site.chp.absorption_chiller.installed_cost_per_ton) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'installed_cost_per_ton', value: '' });

          if(this.run.site.chp.absorption_chiller.om_cost_per_ton) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'om_cost_per_ton', value: '' });
          if(this.run.site.chp.absorption_chiller.cop_thermal) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'cop_thermal', value: '' });
          if(this.run.site.chp.absorption_chiller.cop_electric) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'cop_electric', value: '' });
          if(this.run.site.chp.absorption_chiller.macrs_option_years != 0) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'macrs_option_years', value: 0 });
          if(this.run.site.chp.absorption_chiller.macrs_bonus_fraction != 0) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'macrs_bonus_fraction', value: 0 });

          window.scrollTo(0, document.getElementById('abs_chiller_heading').offsetTop);
        }
      }
    }
  }
</script>
