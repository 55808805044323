<template>
  <div>
    <table class="table cost-escalation-rates-table-inputs tax-incentives-inputs">
      <thead>
        <tr>
        <th scope="col"></th>
        <th scope="col" style="width:17.4%;text-align:center;">
          CO₂e
        </th>
        <th scope="col" style="width:17.4%;text-align:center;">
          NOx
        </th>
        <th scope="col" style="width:17.4%;text-align:center;">
          SO₂
        </th>
        <th scope="col" style="width:17.4%;text-align:center;">
          PM2.5
        </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label">
              Emissions cost escalation rate, nominal (%)
            </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'CO2_cost_escalation_rate_fraction'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'NOx_cost_escalation_rate_fraction'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'SO2_cost_escalation_rate_fraction'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'PM25_cost_escalation_rate_fraction'" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
  <script>
    import { mapGetters } from 'vuex';
    import Tooltip from '../Tooltip';
    import IncentivesTableStringInputNested2 from './IncentivesTableStringInputNested2';
  
    export default {
      components: {
        Tooltip,
        IncentivesTableStringInputNested2,
      },
  
      computed: {
        ...mapGetters([
          'run',
        ])
      },
    }
  </script>
  