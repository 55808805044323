<template>
  <div>
    <div v-if="errors.length !== 0" class="alert alert-danger alert-form-errors">
      <h4>Oops! Please check the errors below.</h4>
      <div v-for="row_errors in errors">
        <ul>
          <li v-for="row_error in row_errors">{{ row_error }}</li>
        </ul>
      </div>
    </div>

    <div id="critical-build-form">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="critical-build-name" class="col-sm-2 control-label"><span class="required-field">*</span> Name</label>
          <div class="col-sm-6">
            <input v-model="criticalBuild.name" id="critical-build-name" class="form-control" type='text'/>
          </div>
        </div>

        <div class="form-group">
          <label for="critical-build-descr" class="col-sm-2 control-label">&nbsp;&nbsp;&nbsp;Description</label>
          <div class="col-sm-6">
            <textarea v-model="criticalBuild.description" id="critical-build-descr" class="form-control"/>
          </div>
        </div>

        <div class="form-group" id="select-component-container">
          <label for="base-components" class="col-sm-2 control-label"><span class="required-field">*</span> Load Type <tooltip :association_1="'site'" :association_2="'critical_build'" :attribute="'load_type'"></tooltip></label>
          <div class="col-sm-4">
            <select v-model="baseComponent" id="base-components" class="form-control">
              <option v-for="baseComponent in baseComponents" :value="baseComponent">{{ baseComponent.name }}</option>
            </select>
          </div>
          <div class="col-sm-4">
            <button id="add-component-to-build" v-on:click="addComponent(baseComponent, $event)" :disabled="addComponentDisabled" type="button" class="btn btn-primary">Add Load Component</button>
          </div>
        </div><br>

        <div v-if="loadComponentsAttributes.length > 1" class="form-group">
          <label for="sort-options" class="col-sm-2 control-label">&nbsp;&nbsp;&nbsp;<strong>Sort By </strong></label>
          <div class="col-sm-6">
            <select v-model="selectedSort" id="sort-options" @change="sortLoadComponents()" class="form-control">
              <option v-for="(option, index) in sortOptions" v-bind:value="sortOptionsValues[index]">{{ option }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <table id="critical-builder-table">
            <thead>
              <th scope="col">Load Type</th>
              <th scope="col">Power (W)<tooltip :association_1="'site'" :association_2="'critical_build'" :attribute="'power'"></tooltip></th>
              <th scope="col" style="word-wrap: break-word">Quantity</th>
              <th scope="col">Start Hr.<tooltip :association_1="'site'" :association_2="'critical_build'" :attribute="'start_hr'"></tooltip></th>
              <th scope="col">End Hr.<tooltip :association_1="'site'" :association_2="'critical_build'" :attribute="'end_hr'"></tooltip></th>
              <th scope="col">Start Month</th>
              <th scope="col">End Month<tooltip :association_1="'site'" :association_2="'critical_build'" :attribute="'end_month'"></tooltip></th>
              <th><div></div></th>
            </thead>
            <tbody>
              <tr v-if="loadComponentsAttributes.length === 0">
                <td colspan="7" id="no-components-row">Select a load type to begin building your critical load profile.</td>
                <td></td>
              </tr>
              <tr v-for="(load_component, index) in loadComponentsAttributes" class="load-component">
                <td><textarea v-model="load_component.name" class="form-control"/></td>
                <td><input v-model="load_component.power" type="number" class="form-control" min="0" style="min-width:85px"></td>
                <td><input v-model="load_component.quantity" type="number" class="form-control" min="0"></td>
                <td>
                  <select v-model="load_component.start_hr" class="form-control comp-start-hr">
                    <option v-for="n in 24" v-bind:value="n - 1">{{ n - 1 }}</option>
                  </select>
                </td>
                <td>
                  <select v-model="load_component.end_hr" class="form-control comp-end-hr">
                    <option v-for="n in 24" v-bind:value="n">{{ n }}</option>
                  </select>
                </td>
                <td>
                  <select v-model="load_component.start_month" class="form-control">
                    <option v-for="(month, index) in months" v-bind:value="index + 1">{{ month }}</option>
                  </select>
                </td>
                <td>
                  <select v-model="load_component.end_month" class="form-control">
                    <option v-for="(month, index) in months" v-bind:value="index + 1">{{ month }}</option>
                  </select>
                </td>
                <td scope="row"><font-awesome-icon  icon="window-close" @click="removeComponent(index)" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div v-if="isModal" class="modal-footer">
          <div class="col-sm-7">
            After saving this custom critical load, it will be available for selection from the Critical Load Profile dropdown.
          </div>
          <div class="col-sm-5" style="margin-top:15px;">
            <button @click="saveCriticalBuild($event)" type="button" class="btn btn-primary btn-lg">Save Critical Build</button>
          </div>
        </div>
        <div v-else-if="!editPage" class="col-sm-6 save-build">
          <button @click="saveCriticalBuild($event)" type="button" class="btn btn-primary btn-lg">Save Critical Build</button>
        </div>
        <div v-else-if="editPage" class="col-sm-6 save-build">
          <button @click="updateCriticalBuild($event)" type="button" class="btn btn-primary btn-lg">Update Critical Build</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './../run/Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'isModal'
    ],

    data: function() {
      return {
        baseComponent: {},
        errors: [],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        sortOptions: ['', 'Load Type', 'Power', 'Quantity', 'Start Month'],
        sortOptionsValues: ['', 'name', 'power', 'quantity', 'start_month'],
        selectedSort: '',
      }
    },

    computed: {
      ...mapGetters([
        'baseComponents',
        'criticalBuild',
        'loadComponentsAttributes',
        'urlRoot'
      ]),

      addComponentDisabled: function() {
        return Object.keys(this.baseComponent).length === 0 ? true : false
      },
      editPage: function() {
        return window.location.href.split("/").slice(-1)[0] === 'edit';
      }
    },

    methods: {
      addComponent: function(baseComponent, event) {
        if(!baseComponent.name) return false; //for blank option on initial page load
        this.loadComponentsAttributes.unshift({
          base_component_id: baseComponent.id,
          name: baseComponent.name,
          power: baseComponent.power,
          quantity: baseComponent.quantity,
          start_hr: baseComponent.start_hr,
          end_hr: baseComponent.end_hr,
          start_month: baseComponent.start_month,
          end_month: baseComponent.end_month,
        });
      },
      removeComponent: function(index) {
        this.loadComponentsAttributes.splice(index, 1);
      },
      saveCriticalBuild: function(event) {
        this.errors = this.checkForm(); //client side validations
        if(this.errors.length !== 0) {
          if(this.isModal) {
            document.getElementById('critical-builder-modal').animate({ scrollTop: 0 }, 'slow');
          } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
          }

          return false ;
        }

        // set nested attributes as expected by rails params
        this.criticalBuild.load_components_attributes = this.loadComponentsAttributes;

        var payload = { critical_build: this.criticalBuild };
        if(this.isModal) payload["fromModal"] = true;

        var self = this; // needed to access data from within ajax (i.e. this.errors)
        $.post(`${self.urlRoot}/critical_builds`, payload)
          .done(function(newCriticalBuild) {
            // if this component is a child component (from modal in run new view) then close modal and update select options
            if(self.isModal) {
              self.$emit('update-selected-critical-build', event, newCriticalBuild)
            } else {
              // otherwise this is from critical_build new page and user directed to index
              window.location = `${self.urlRoot}/critical_builds`;
            }
          })
          .fail(function(response) {
            if(response.responseJSON) {
              self.errors = response.responseJSON;
            } else {
              self.errors = [[ 'An unexpected error occurred. Please try again.' ]];
            }
          })
      },
      checkForm: function() {
        var errors = [];
        if (!this.criticalBuild.name) errors.push(["Critical load name cannot be blank"])
        if (this.loadComponentsAttributes.length === 0) errors.push(["Must select at least one load type and add to load profile"])
        this.loadComponentsAttributes.forEach(function(loadComponent) {
          var row_errors = [];
          if (loadComponent.name === "") {
            row_errors.push(`Emergency Load Type cannot be empty`);
          }
          if (Number(loadComponent.power) < 0) {
            row_errors.push(`${loadComponent.name}: Power cannot be negative`);
          }
          if (Number(loadComponent.quantity) < 0) {
            row_errors.push(`${loadComponent.name}: Quantity cannot be negative`);
          }
          if (Number(loadComponent.start_hr) < 0 || Number(loadComponent.start_hr > 23)) {
            row_errors.push(`${loadComponent.name}: Start hour must be greater than or equal to 0 and less than or equal to 23`);
          }
          if (Number(loadComponent.end_hr < 1) || Number(loadComponent.end_hr > 24)) {
            row_errors.push(`${loadComponent.name}: End hour must be greater than or equal to 1 and less than or equal to 24`);
          }
          if (Number(loadComponent.start_month < 1) || Number(loadComponent.start_month > 12)) {
            row_errors.push(`${loadComponent.name}: Start month must be betweem 1 and 12`);
          }
          if (Number(loadComponent.end_month) < 1 || Number(loadComponent.end_month > 12)) {
            row_errors.push(`${loadComponent.name}: End month must be betweem 1 and 12`);
          }
          if (Number(loadComponent.end_hr) <= Number(loadComponent.start_hr)) {
            row_errors.push(`${loadComponent.name}: Start hour must be less than end hour`);
          }
          if (Number(loadComponent.end_month) < Number(loadComponent.start_month)) {
            row_errors.push(`${loadComponent.name}: Start month must be less than or equal to end month`);
          }
          if (row_errors.length !== 0) errors.push(row_errors)
        })
        return errors;
      },
      updateCriticalBuild: function() {
        this.errors = this.checkForm(); //client side validations
        if(this.errors.length !== 0) {
          window.scrollTo({top: 0, behavior: 'smooth'});

          return false ;
        }

        // set nested attributes as expected by rails params
        this.criticalBuild.load_components_attributes = this.loadComponentsAttributes;

        var id = window.location.href.split("/").slice(-2)[0];
        var self = this; // need to access this.errors within ajax function

        var self = this;
        $.ajax({
          url: `${this.urlRoot}/critical_builds/${id}`,
          type: "PATCH",
          data: { critical_build: this.criticalBuild },
          dataType: 'json',
          success: function(response) {
            window.location = `${self.urlRoot}/critical_builds`;
          },
          error: function(response) {
            self.errors = response.responseJSON;
          }
        })
      },
      sortLoadComponents: function(e) {
        if (this.selectedSort === '') return false;
        var attribute = this.selectedSort;
        this.loadComponentsAttributes.sort(function(a, b) {
          // sorts asc when name selected and desc for all other attrs
          if (isNaN(a[attribute] / 2)) {
            var attrA = a[attribute].toUpperCase();
            var attrB = b[attribute].toUpperCase();
            return (attrA < attrB) ? -1 : (attrA > attrB) ? 1 : 0;
          } else if  (attribute === "start_month") {
            var attrA = a[attribute];
            var attrB = b[attribute];
            return (attrA < attrB) ? -1 : (attrA > attrB) ? 1 : 0;
          } else {
            var attrA = a[attribute]
            var attrB = b[attribute]
            return (attrA < attrB) ? 1 : (attrA > attrB) ? -1 : 0;
          }
        });
      }
    }
  }

</script>


<style scoped>
  .save-build { margin-top: 25px; }
</style>
