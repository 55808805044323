<template>
  <div class="form-horizontal">
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='pv'
      attribute='installed_cost_per_kw' />
    <div class="row section-actions">
      <div class="col-sm-6">&nbsp;</div>
      <div class="col-sm-4">
        <input
          v-model="run.site.pv.existing_pv"
          type="checkbox"
          id="existing_pv_chk" />
        <label style="font-weight: normal" for="existing_pv_chk">
          Existing PV system?
        </label>
      </div>
    </div>
    <div v-if="run.site.pv.existing_pv">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='pv'
        attribute='existing_kw' />
      <div class="form-group radio_buttons optional run_site_pv_loads_kw_is_net">
        <div class="col-sm-6 control-label">
          <label class="radio_buttons optional">Type of load profile</label>
          <tooltip
            :association_1="'site'"
            :association_2="'pv'"
            :attribute="'loads_kw_is_net'" />
        </div>
        <div class="col-sm-4">
          <input type="hidden" name="run[site_attributes][pv_attributes][loads_kw_is_net]" value="" />
          <span class="radio">
            <label for="run_site_attributes_pv_attributes_loads_kw_is_net_true">
              <input class="radio_buttons optional" v-model="run.site.pv.loads_kw_is_net" type="radio" value="true" name="run[site_attributes][pv_attributes][loads_kw_is_net]" id="run_site_attributes_pv_attributes_loads_kw_is_net_true" />Net (gross load minus existing PV generation)
            </label>
          </span>
          <span class="radio">
            <label for="run_site_attributes_pv_attributes_loads_kw_is_net_false">
              <input class="radio_buttons optional" v-model="run.site.pv.loads_kw_is_net" type="radio" value="false" name="run[site_attributes][pv_attributes][loads_kw_is_net]" id="run_site_attributes_pv_attributes_loads_kw_is_net_false" />Gross load (also known as native load)
            </label>
          </span>
        </div>
      </div>
    </div>
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='pv'
      attribute='min_kw' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='pv'
      attribute='max_kw' />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>PV Costs</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='om_cost_per_kw' />
      </div>

      <h4>PV System Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='module_type' />
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='array_type' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='azimuth' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='tilt' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='dc_ac_ratio' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='losses' />
        <div class="form-group file optional run_site_pv_prod_factor_file">
          <div class="col-sm-6 control-label">
            <label class="file optional" for="run_site_attributes_pv_attributes_prod_factor_file">PV generation profile</label>
            <tooltip :association_1="'site'" :association_2="'pv'" :attribute="'prod_factor_file'"></tooltip>
          </div>
          <div class="col-sm-4">
            <input
              class="form-control hidden"
              type="hidden"
              name="run[site_attributes][pv_attributes][prod_factor_file]"
              id="run_site_attributes_pv_attributes_prod_factor_file" />
            <input
              @change="parseCSV($event)"
              class="form-control-file file optional load-profile-file-upload"
              type="file"
              name="run[site_attributes][pv_attributes][prod_factor_file]" />
            <!-- <p v-if="run.copied && this.run.site.pv.prod_factor_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.pv.prod_factor_file_data).metadata.filename }}</p> -->
            <p v-if="run.copied && this.run.site.pv.prod_factor_file_name" class="help-block">Uploaded file: {{ this.run.site.pv.prod_factor_file_name }}</p>
          </div>
        </div>
        <input
          v-model="run.site.pv.prod_factor_series_kw"
          class="form-control hidden"
          type="hidden"
          name="run[site_attributes][pv_attributes][prod_factor_series_kw]" />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='radius' />
        <horizontal-string-input-nested-2
          v-if="!run.grid"
          association_1='site'
          association_2='pv'
          attribute='operating_reserve_required_fraction' />
      </div>

      <h4>PV Incentives and Tax Treatment</h4>
      <incentives-table
        association_1='site'
        association_2='pv' />

      <h5>Tax Treatment</h5>
      <div class="form-horizontal">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='macrs_option_years' />
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='macrs_bonus_fraction' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import IncentivesTable from './inputs/IncentivesTable';
  import Tooltip from './Tooltip';

  export default {
    components: {
      // nested 2 b/c run.site.financial - measured from run
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      IncentivesTable,
      Tooltip,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'selectOptions'
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      parseCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The PV generation profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.pv.prod_factor_series_kw = [];
                    event.target.value = "";
                    bootbox.alert('The PV generation profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.pv.prod_factor_series_kw = [];
                  event.target.value = "";
                  bootbox.alert('The PV generation profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                var payload = {
                  association_1: 'site',
                  association_2: 'pv',
                  attribute: 'prod_factor_series_kw',
                  value: JSON.stringify(values),
                }
                self.$store.commit('updateValueNested2', payload);
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'pv', attribute: 'prod_factor_file_name', value: file.name});
              } finally {}
            }
          });
        }
      },
      resetInputs: function(event) {
        if(confirm("Are you sure you want to reset the inputs in the PV section to their default values?")) {
          if(this.run.site.pv.installed_cost_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'installed_cost_per_kw', value: '' });
          if(this.run.site.pv.min_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'min_kw', value: '' });
          if(this.run.site.pv.max_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'max_kw', value: '' });
          if(this.run.site.pv.om_cost_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'om_cost_per_kw', value: '' });
          if(this.run.site.pv.existing_pv) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'existing_pv', value: false });
          if(this.run.site.pv.module_type != 'Standard') this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'module_type', value: 'Standard' });
          if(this.run.site.pv.array_type != 'Ground Mount, Fixed') this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'array_type', value: 'Ground Mount, Fixed' });
          if(this.run.site.pv.azimuth) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'azimuth', value: '' });
          if(this.run.site.pv.tilt) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'tilt', value: '' });
          if(this.run.site.pv.dc_ac_ratio) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'dc_ac_ratio', value: '' });
          if(this.run.site.pv.losses) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'losses', value: '' });
          if(this.run.site.pv.prod_factor_file_data) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'prod_factor_file_data', value: '' });
          if(this.run.site.pv.prod_factor_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'prod_factor_file_name', value: '' });
          if(this.run.site.pv.radius) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'radius', value: '' });
          if(this.run.site.pv.operating_reserve_required_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'operating_reserve_required_fraction', value: '' });
          if(this.run.site.pv.federal_itc_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'federal_itc_fraction', value: '' });
          if(this.run.site.pv.federal_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'federal_rebate_per_kw', value: '' });
          if(this.run.site.pv.state_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'state_ibi_fraction', value: '' });
          if(this.run.site.pv.state_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'state_ibi_max', value: '' });
          if(this.run.site.pv.state_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'state_rebate_per_kw', value: '' });
          if(this.run.site.pv.state_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'state_rebate_max', value: '' });
          if(this.run.site.pv.utility_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'utility_ibi_fraction', value: '' });
          if(this.run.site.pv.utility_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'utility_ibi_max', value: '' });
          if(this.run.site.pv.utility_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'utility_rebate_per_kw', value: '' });
          if(this.run.site.pv.utility_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'utility_rebate_max', value: '' });
          if(this.run.site.pv.production_incentive_per_kwh) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'production_incentive_per_kwh', value: '' });
          if(this.run.site.pv.pbi_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'pbi_years', value: '' });
          if(this.run.site.pv.production_incentive_max_benefit) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'production_incentive_max_benefit', value: '' });
          if(this.run.site.pv.pbi_system_max_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'pbi_system_max_kw', value: '' });
          if(this.run.site.pv.macrs_option_years != 5) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'macrs_option_years', value: 5 });
          if(this.run.site.pv.macrs_bonus_fraction != 0.8) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'pv', attribute: 'macrs_bonus_fraction', value: 0.6 });

          window.scrollTo(0, document.getElementById('pv_heading').offsetTop);
        }
      },
      updateTilt: function() {
        if(this.run.site.pv.array_type == "Ground Mount, Fixed" || this.run.site.pv.array_type == "Rooftop, Fixed") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'tilt', value: "20" });
        } else if(this.run.site.pv.array_type == "Ground Mount, 1-Axis Tracking") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'tilt', value: "0" });
        }
      },
      updateAzimuth: function() {
        if(this.run.site.latitude < 0) {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'azimuth', value: 0 });
        } else {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'azimuth', value: 180 });
        }
      }
    },

    watch: {
      'run.site.address': function() {
        this.updateTilt();
        this.updateAzimuth();
      },
      'run.site.pv.array_type': function() {
        this.updateTilt();
      },
      'run.site.pv.existing_pv': function() {
        if(!this.run.site.pv.existing_pv) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'pv', attribute: 'loads_kw_is_net', value: '' });
        } else {
          if(!this.run.site.pv.loads_kw_is_net) {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'pv', attribute: 'loads_kw_is_net', value: 'true' });
          }
        }
      }
    },

    mounted: function() {
      // for copied runs
      if(this.run.site.pv.prod_factor_file_data) {
        document.getElementById("run_site_attributes_pv_attributes_prod_factor_file").value = this.run.site.pv.prod_factor_file_data;
      }

      this.updateTilt();
      this.updateAzimuth();
    }
  }
</script>
