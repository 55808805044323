<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="string optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip
        :association_1="this.association_1"
        :attribute="this.attribute" />
    </div>
    <div class="col-sm-4">
      <input
        v-model="run[association_1][attribute]"
        @change="$emit('input-change');"
        @blur="validate()"
        class="form-control string optional"
        :placeholder="placeholder"
        type="text"
        :name="inputName"
        :id="inputID" />
        <span v-if="validateError" class="help-block">
          {{ validateErrorMessage }}
        </span>
    </div>
    <div v-if="run.single_run && attribute == 'address'" class="col-sm-2">
      <div class="form-control-static">
        <a @click="useSampleSite()"
          class="sample-site hint-link no-visit-color">
          <i class="fas fa-globe-americas" aria-hidden="true"></i>Use sample site
        </a>
      </div>
    </div>
    <div v-if="displayInputDefault" class="col-sm-2 default-value-block">
      <div
        class="default-value"
        :data-default-value="placeholder"
        :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'association_1',
      'attribute',
      'required',
    ],

    data: function() {
      return {
        displayDefault: false,
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'labels',
        'placeholders',
        'sampleSiteParams',
        'run',
      ]),
      property :function() {
        return this.run[this.association_1][this.attribute];
      },
      label: function() {
        return this.labels[this.association_1][this.attribute];
      },
      placeholder: function() {
        return this.placeholders[this.association_1][this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.association_1}_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.attribute}]`;
      },
      displayInputDefault: function() {
        return this.placeholder && this.displayDefault && this.attribute != 'description' && this.attribute != 'address';
      },
      validateErrorMessage: function() {
        return this.clientSideValidations.run[this.association_1][this.attribute];
      }
    },

    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.attribute]) {
          this.validateError = false;
        }
      },
      setSampleRate: function () {
        this.$store.commit('updateObject', { object: 'utilityRatesLoading', value: true });

        var self = this;
        $.ajax({
          url: '/tool/utility-rates',
          data: {
            latitude: self.run.site.latitude,
            longitude: self.run.site.longitude,
            location: self.run.site.address
          },
          success: function handleResults(data) {
            // console.log('data', data)
            self.$store.commit('updateObject', { object: 'utilityRates', value: data });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });
            self.checkedForRates = true;
            self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'urdb_label', value: '662aae965d742ded630a7e55' });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'urdb_label', value: '662aae965d742ded630a7e55' });
          },
          error: function () {
            self.$store.commit('updateObject', { object: 'utilityRates', value: [] });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });

            alert('An unexpected error occurred while fetching the utility rates. Please try again or contact us for assistance.');
          },
        })
      },
      useSampleSite: function() {
        var confirmText = 'Are you sure you want to replace all the inputs with a sample site?\n\n*PV, Battery, and CHP (if selected) will be the only technologies selected for the sample site. Other technology inputs will be lost.'
        if(!confirm(confirmText)) {
          return false;
        }
        if (this.run.focus == 'resilience') {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: 'outage_duration', value: '48' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile', attribute: 'outage_duration', value: '48' });
          // don't need to set the outage_start_time and outage_start_hour for single outage as multi-outage is the default, so those have been removed.
          // when sample site was selected it was sending those values in the post and would therefore show up on the results page inputs accordion.
        }
        if (this.run.analyze_chp == true) {
          this.$store.commit('updateValueNested3', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_boiler_fuel',  attribute: 'doe_reference_name', value: 'Hospital' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_boiler_fuel',  attribute: 'doe_reference_name', value: 'Hospital' });
          this.$store.commit('updateValueNested3', { association_1: 'site', association_2: 'chp', association_3: 'boiler',  attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '9.0' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'boiler',  attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '9.0' });
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'chp',  attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '9.0' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp',  attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '9.0' });
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'chp',  attribute: 'size_class', value: '2' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp',  attribute: 'size_class', value: '2' });
          
        }
        if (this.run.analyze_ghp == true) {
          this.$store.commit('updateValueNested3', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_boiler_fuel',  attribute: 'doe_reference_name', value: 'Hospital' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_boiler_fuel',  attribute: 'doe_reference_name', value: 'Hospital' });
          this.$store.commit('updateValueNested3', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_chiller_thermal',  attribute: 'doe_reference_name', value: 'Hospital' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_chiller_thermal',  attribute: 'doe_reference_name', value: 'Hospital' });
          if (this.run.analyze_chp == false) {
            this.$store.commit('updateValueNested3', { association_1: 'site', association_2: 'ghp', association_3: 'boiler',  attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '9.0' });
            this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', association_3: 'boiler',  attribute: 'fuel_blended_annual_rates_per_mmbtu', value: '9.0' });
          }
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'ghp',  attribute: 'building_sqft', value: '300000.0' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp',  attribute: 'building_sqft', value: '300000.0' });
        }
        this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'address', value: 'San Francisco, CA, USA' });
        this.$store.commit('updatePlaceholder', { association_1: 'site', attribute: 'address', value: 'San Francisco, CA, USA' });
        this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'latitude', value: '37.7749295' });
        this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'longitude', value: '-122.4194155' });
        this.setSampleRate() // Need to fetch and populate rates so that placeholder displays correctly
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: 'doe_reference_name', value: 'Hospital' });
        this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile', attribute: 'doe_reference_name', value: 'Hospital' });
        this.$emit('update-emissions-defaults');
      }
    },

    watch: {
      'property': function() {
        if(this.property) {
          this.displayDefault = true;
        } else {
          this.displayDefault = false;
        }
      },
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    },

    mounted: function() {
      // needed for when user enters value, then section disappears, then reappears
      // should display value in field & `default = XYZ` in side column
      if(this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    }
  }
</script>
