<template>
  <div class="form-horizontal">
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='storage'
      attribute='installed_cost_per_kwh' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='storage'
      attribute='installed_cost_per_kw' />
    <horizontal-select-input-nested-2
      v-if="run.grid"
      association_1='site'
      association_2='storage'
      attribute='can_grid_charge' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='storage'
      attribute='min_kwh' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='storage'
      attribute='max_kwh' />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs($event)">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>Battery Costs</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='replace_cost_per_kwh' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='battery_replacement_year' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='replace_cost_per_kw' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='inverter_replacement_year' />
      </div>

      <h4>Battery Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='min_kw' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='max_kw' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='rectifier_efficiency_fraction'
          v-on:input-change="updateRoundTripEfficiency()" />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='internal_efficiency_fraction'
          v-on:input-change="updateRoundTripEfficiency()" />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='inverter_efficiency_fraction'
          v-on:input-change="updateRoundTripEfficiency()" />
        <div class="form-group">
          <label class="col-sm-6 control-label">
            Total AC-AC round trip efficiency
            <tooltip :association_1="'site'" :association_2="'storage'" :attribute="'ac_ac_efficiency'"></tooltip>
          </label>
          <div class="col-sm-4">
            <p class="form-control-static">{{ roundTripEfficiency.toFixed(1) }}%</p>
          </div>
        </div>
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='soc_min_fraction' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='soc_init_fraction' />
      </div>

      <h4>Battery Incentives and Tax Treatment</h4>
      <h5>Capital Cost Based Incentives</h5>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='total_itc_fraction' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='total_rebate_per_kw' />

        <h5>Tax Treatment</h5>
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='macrs_option_years' />
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='storage'
          attribute='macrs_bonus_fraction' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs($event)">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import Tooltip from './Tooltip'

  export default {
    components: {
      // nested 2 b/c run.site.financial - measured from run
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      Tooltip
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
        roundTripEfficiency: 89.9
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'selectOptions',
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updateRoundTripEfficiency: function() {
        var rectifier = (this.run.site.storage.rectifier_efficiency_fraction / 100.0) || 0.96
        var internal = (this.run.site.storage.internal_efficiency_fraction / 100.0) || 0.975
        var inverter = (this.run.site.storage.inverter_efficiency_fraction / 100.0) || 0.96
        this.roundTripEfficiency = rectifier * internal * inverter * 100;
      },
      resetInputs: function(event) {
        if(confirm("Are you sure you want to reset the inputs in the Battery section to their default values?")) {
          if(this.run.site.storage.installed_cost_per_kwh) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'installed_cost_per_kwh', value: '' });
          if(this.run.site.storage.installed_cost_per_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'installed_cost_per_kw', value: '' });
          if(!this.run.site.storage.can_grid_charge) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'can_grid_charge', value: true });
          if(this.run.site.storage.min_kwh) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'min_kwh', value: '' });
          if(this.run.site.storage.max_kwh) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'max_kwh', value: '' });
          if(this.run.site.storage.battery_replacement_year) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'battery_replacement_year', value: '' });

          if(this.run.site.storage.replace_cost_per_kwh) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'replace_cost_per_kwh', value: '' });
          if(this.run.site.storage.replace_cost_per_kwh) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'replace_cost_per_kwh', value: '' });
          if(this.run.site.storage.replace_cost_per_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'replace_cost_per_kw', value: '' });
          if(this.run.site.storage.inverter_replacement_year) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'inverter_replacement_year', value: '' });

          if(this.run.site.storage.min_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'min_kw', value: '' });
          if(this.run.site.storage.max_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'max_kw', value: '' });
          if(this.run.site.storage.rectifier_efficiency_fraction) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'rectifier_efficiency_fraction', value: '' });
          if(this.run.site.storage.internal_efficiency_fraction) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'internal_efficiency_fraction', value: '' });
          if(this.run.site.storage.inverter_efficiency_fraction) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'inverter_efficiency_fraction', value: '' });
          if(this.run.site.storage.soc_min_fraction) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'soc_min_fraction', value: '' });
          if(this.run.site.storage.soc_init_fraction) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'soc_init_fraction', value: '' });
          if(this.run.site.storage.total_itc_fraction) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'total_itc_fraction', value: '' });
          if(this.run.site.storage.total_rebate_per_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'total_rebate_per_kw', value: '' });
          if(this.run.site.storage.macrs_option_years != 7) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'macrs_option_years', value: 7 });
          if(this.run.site.storage.macrs_bonus_fraction != 0.8) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'storage', attribute: 'macrs_bonus_fraction', value: 0.6 });
          this.updateRoundTripEfficiency();

          window.scrollTo(0, document.getElementById('battery_heading').offsetTop);
        }
      }
    }
  }
</script>
