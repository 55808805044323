<template>
  <div class="form-horizontal">
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='financial'
      attribute='analysis_years'
      v-on:input-change="updateEmissionsYears()" />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='financial'
      attribute='offtaker_discount_rate_fraction' />
    <horizontal-string-input-nested-2
      v-if="run.grid"
      association_1='site'
      association_2='financial'
      attribute='elec_cost_escalation_rate_fraction' />
    <horizontal-string-input-nested-2
      v-if="run.analyze_generator || !run.grid"
      association_1='site'
      association_2='financial'
      attribute='generator_fuel_cost_escalation_rate_fraction' />
    <horizontal-string-input-nested-2
      v-if="!run.grid"
      association_1='site'
      association_2='financial'
      attribute='offgrid_other_capital_costs' />
    <horizontal-string-input-nested-2
      v-if="!run.grid"
      association_1='site'
      association_2='financial'
      attribute='offgrid_other_annual_costs' />
    <horizontal-string-input-nested-2
      v-if="run.grid && (run.analyze_chp || run.analyze_ghp)"
      association_1='site'
      association_2='financial'
      attribute='existing_boiler_fuel_cost_escalation_rate_fraction' />
    <horizontal-string-input-nested-2
      v-if="run.analyze_chp"
      association_1='site'
      association_2='financial'
      attribute='chp_fuel_cost_escalation_rate_fraction' />
    <horizontal-string-input-nested-2
      v-if="run.analyze_prime_generator"
      association_1='site'
      association_2='financial'
      attribute='prime_generator_fuel_cost_escalation_rate_fraction' />
    <div class="col-sm-offset-6 col-sm-4" id="custom-rate-checkbox" style="margin-bottom:10px;">
      <input
        v-model="run.site.financial.third_party_ownership"
        class="checkbox-inline"
        type="checkbox"
        value="1"
        name="run[site_attributes][financial_attributes][third_party_ownership]"
        id="run_site_attributes_financial_attributes_third_party_ownership" />
      <label class="custom-electricity-rate-label" for="run_site_attributes_financial_attributes_third_party_ownership">Use third-party ownership model</label>
      <tooltip
        :association_1="'site'"
        :association_2="'financial'"
        :attribute="'third_party_ownership'" />
    </div>
    <div v-if="run.site.financial.third_party_ownership">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='financial'
        attribute='owner_discount_rate_fraction' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='financial'
        attribute='owner_tax_rate_fraction' />
    </div>

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>

        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='financial'
        attribute='offtaker_tax_rate_fraction' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='financial'
        attribute='om_cost_escalation_rate_fraction' />
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import Tooltip from './Tooltip'

  export default {
    components: {
      // nested 2 b/c run.site.financial - measured from run
      HorizontalStringInputNested2,
      Tooltip
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'placeholders'
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updateEmissionsYears: function() {
        if (this.run.site.electric_tariff.cambium_greening_of_grid && this.run.site.financial.analysis_years) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: this.run.site.financial.analysis_years });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.run.site.financial.analysis_years) - 1) });
        } else if (this.run.site.electric_tariff.cambium_greening_of_grid && !this.run.site.financial.analysis_years) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: '' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.placeholders.site.financial.analysis_years) - 1) });
        } else {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: 25 });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024' });
        }
      },
      resetInputs: function() {
        var self = this;
        if(confirm("Are you sure you want to reset the inputs in the Financial section to their default values?")) {
          if(this.run.site.financial.analysis_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'analysis_years', value: ''});
          if(this.run.site.financial.offtaker_discount_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'offtaker_discount_rate_fraction', value: ''});
          if(this.run.site.financial.elec_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'elec_cost_escalation_rate_fraction', value: ''});
          if(this.run.site.financial.generator_fuel_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'generator_fuel_cost_escalation_rate_fraction', value: ''});
          if(this.run.site.financial.offgrid_other_capital_costs) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'offgrid_other_capital_costs', value: ''});
          if(this.run.site.financial.offgrid_other_annual_costs) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'offgrid_other_annual_costs', value: ''});
          if(this.run.site.financial.existing_boiler_fuel_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'existing_boiler_fuel_cost_escalation_rate_fraction', value: ''});
          if(this.run.site.financial.chp_fuel_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'chp_fuel_cost_escalation_rate_fraction', value: ''});
          if(this.run.site.financial.prime_generator_fuel_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'prime_generator_fuel_cost_escalation_rate_fraction', value: ''});
          if(this.run.site.financial.third_party_ownership) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'third_party_ownership', value: false});
          if(this.run.site.financial.owner_discount_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'owner_discount_rate_fraction', value: ''});
          if(this.run.site.financial.owner_tax_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'owner_tax_rate_fraction', value: ''});
          if(this.run.site.financial.offtaker_tax_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'offtaker_tax_rate_fraction', value: ''});
          if(this.run.site.financial.om_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'om_cost_escalation_rate_fraction', value: ''});

          window.scrollTo(0, document.getElementById('financial_heading').offsetTop);
        }
      }
    },
  }
</script>
