<template>
  <div class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="closeModal()" type="button" class="close" data-dismiss="modal" aria-hidden="true"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Upload a <strong>Critical</strong> Load Profile</h4>
          </div>

          <div class="modal-body">
            <div class="form-horizontal">
              <div class="alert alert-danger alert-form-errors" v-if="errors.length > 0">
                <ul v-for="error in errors">
                  <li>{{ error }}</li>
                </ul>
              </div>

              <div class="form-group file required critical_load_profile_file">
                <div class="col-sm-6 control-label">
                  <label class="file required" for="critical_load_profile_file">
                    <abbr title="Required" class="required">*</abbr> Custom typical load profile
                  </label>
                </div>
                <div class="col-sm-4">
                  <input @change="updateLoadKw()" class="form-control-file file required typical-load-profile-file-upload" required="required" aria-required="true" type="file" name="critical_load_profile[file]" id="critical_load_profile_file" />
                </div>
              </div>
              <input v-model="criticalLoadProfile.loadKw" class="form-control hidden" type="hidden" name="critical_load_profile[load_kw]" />
              <div class="form-group text optional critical_load_profile_description">
                <div class="col-sm-6 control-label">
                  <label class="text optional" for="critical_load_profile_description">Description</label>
                </div>
                <div class="col-sm-4">
                  <textarea v-model="criticalLoadProfile.description" class="form-control text optional" name="critical_load_profile[description]" id="critical_load_profile_description">
                  </textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <input @click="submitCriticalLoadProfile()" type="button" name="commit" value="Upload Critical Load Profile" class="btn btn-primary" data-disable-with="Upload Critical Load Profile" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    data: function() {
      return {
        errors: [],
        criticalLoadProfile: {}
      }
    },

    computed: {
      ...mapGetters([
        'urlRoot',
      ])
    },

    methods: {
      closeModal: function() {
        this.$store.commit('updateObject', { object: "showCriticalLoadProfileForm", value: false });
      },
      updateLoadKw: function() {
        this.errors = [];
        var input = document.getElementById('critical_load_profile_file');
        var file = input.files[0];

        var self = this;
        Papa.parse(file, {
          delimiter: ",",
          complete: function(results) {
            try {
              if(results.errors && results.errors.length > 0) {
                self.criticalLoadProfile.loadKw = undefined;
                self.errors.push(`The load profile could not be parsed. File must be a CSV.`);
                self.errors.push(`Error row ${results.errors[0].row}: ${results.errors[0].message}`);
                input.value = '';

                return false;
              }

              var rows = results.data;
              var values = [];
              var hasColumns = false;
              for(var i = 0, len = rows.length; i < len; i++) {
                var row = rows[i];

                // Detect header columns.
                if(i < 3) {
                  if(row[0] && typeof row[0] === 'string') {
                    var value = row[0].toLowerCase();

                    // Detect when the CSV has multiple columns. Otherwise assume
                    // the CSV is just straight list of values.
                    if(value.indexOf('hour') !== -1) {
                      hasColumns = true;
                    }

                    // Skip header columns.
                    if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                      continue;
                    }
                  }
                }

                // Skip empty rows.
                if(row.join('') === '') {
                  continue;
                }

                // Ensure the values are only numbers.
                var valueIndex = (hasColumns) ? 1 : 0;
                var value = parseFloat(row[valueIndex]);
                if(isNaN(value)) {
                  self.criticalLoadProfile.loadKw = undefined;
                  self.errors.push(`The load profile must contain only numeric values, with no blanks. Could not parse value at row ${(i + 1)}: '${row[valueIndex]}'`);
                  input.value = '';

                  return false;
                }
                values.push(value);
              }

              // Validate the file contains the expected number of value rows.
              var valuesCount = values.length;
              if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                self.criticalLoadProfile.loadKw = undefined;
                self.errors.push(`The load profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.`);
                input.value = '';

                return false;
              }

              self.criticalLoadProfile.loadKw = values;
            } finally {
              // $spinner.remove();
              // $fileInput.show();
            }
          },
        });
      },
      submitCriticalLoadProfile: function(event) {
        // without preventDefault and stop prop. the form is submitted twice AND
        // main form $("#new_run") client side validations are called
        // event.preventDefault();
        // event.stopImmediatePropagation();

        var payload  = new FormData();
        var file = document.getElementById('critical_load_profile_file').files[0];
        // var file = $("#critical_load_profile_file")[0].files[0];
        // var loadKw = $("#critical_load_profile_load_kw").val();
        // var year = $("#critical_load_profile_year").val();
        // var description = $("#critical_load_profile_description").val();

        payload.append('critical_load_profile[file]', file);
        payload.append('critical_load_profile[year]', this.criticalLoadProfile.year);
        payload.append('critical_load_profile[description]', this.criticalLoadProfile.description);
        payload.append('critical_load_profile[load_kw]', JSON.stringify(this.criticalLoadProfile.loadKw));

        var self = this;
        $.ajax({
          url: self.urlRoot + '/critical_load_profiles',
          type: 'POST',
          data: payload,
          contentType: false,
          processData: false,
          success: function(data) {
            data.load_kw = self.criticalLoadProfile.loadKw;
            self.$emit('update-selected-critical-load-profile', event, data)
            self.closeModal();
          },
          error: function(data) {
            if(data.status == 422) {
              var errors = data.responseJSON;

              errors.forEach(function(el, i) {
                self.errors.push(el);
              })
            } else {
              self.errors.push("Something went wrong with your file upload, please make sure the csv is properly formatted and try again.");
            }
          }
        })
      }
    }
  }
</script>

<style scoped>
  .modal-footer { text-align: right; }
</style>
