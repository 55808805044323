<template>
  <div class="form-group string optional" :class="formGroupClass" style="width: 150px; padding-left: 10px; padding-right: 10px;">
    <input
      v-model="run[association_1][association_2][attribute]"
      class="form-control string optional"
      :placeholder="placeholder"
      type="text"
      :name="inputName"
      :id="inputID"
      :disabled="disabled"/>
    <div v-if="placeholder && run[association_1][association_2][attribute]" class="default-value-block">
      <div class="default-value" :data-default-value="placeholder" :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [
      'association_1',
      'association_2',
      'association_3',
      'attribute',
    ],

    data: function() {
      return {
        displayDefault: false,
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'placeholders'
      ]),
      placeholder: function() {
        if(["NOx_onsite_fuelburn_cost_per_tonne", "NOx_grid_cost_per_tonne", "SO2_onsite_fuelburn_cost_per_tonne", "SO2_grid_cost_per_tonne", "PM25_onsite_fuelburn_cost_per_tonne", "PM25_grid_cost_per_tonne"].includes(this.attribute)) {
          var placeholder = this.placeholders[this.association_1][this.association_2][this.attribute];

          if(placeholder) {
            return this.$store.getters.formatAsIntegerWithCommas({ value: placeholder });
          } else {
            return "";
          }
        } else {
          return this.placeholders[this.association_1][this.association_2][this.attribute];
        }
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.attribute}]`;
      },
      disabled: function() {
        if(["NOx_grid_cost_per_tonne", "SO2_grid_cost_per_tonne", "PM25_grid_cost_per_tonne"].indexOf(this.attribute) >= 0) {
          if(!this.run.grid) {
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'NOx_grid_cost_per_tonne', value: ''});
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'SO2_grid_cost_per_tonne', value: ''});
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'PM25_grid_cost_per_tonne', value: ''});

            return true;
          } else {
            return false;
          }
        }
      }
    }
  }
</script>
  