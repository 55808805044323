<template>
  <div class="form-horizontal">
    <horizontal-select-input-nested-2
      association_1='site'
      association_2='wind'
      attribute='size_class'
      v-on:input-change="updatePlaceholders()" />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='wind'
      attribute='installed_cost_per_kw' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='wind'
      attribute='min_kw' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='wind'
      attribute='max_kw' />
    <horizontal-string-input-nested-2
      v-if="!run.grid"
      association_1='site'
      association_2='wind'
      attribute='operating_reserve_required_fraction' />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <div class="form-group file optional run_site_wind_prod_factor_file">
        <div class="col-sm-6 control-label">
          <label class="file optional" for="run_site_attributes_wind_attributes_prod_factor_file">Wind generation profile [kW-AC/kW-DC]</label>
          <tooltip :association_1="'site'" :association_2="'wind'" :attribute="'prod_factor_file'"></tooltip>
        </div>
        <div class="col-sm-4">
          <input
            class="form-control hidden"
            type="hidden"
            name="run[site_attributes][wind_attributes][prod_factor_file]"
            id="run_site_attributes_wind_attributes_prod_factor_file" />
          <input
            @change="parseCSV($event)"
            class="form-control-file file optional load-profile-file-upload"
            type="file"
            name="run[site_attributes][wind_attributes][prod_factor_file]" />
          <!-- <p v-if="run.copied && this.run.site.wind.prod_factor_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.wind.prod_factor_file_data).metadata.filename }}</p> -->
          <p v-if="run.copied && this.run.site.wind.prod_factor_file_name" class="help-block">Uploaded file: {{ this.run.site.wind.prod_factor_file_name }}</p>
        </div>
      </div>
      <input
        v-model="run.site.wind.prod_factor_series_kw"
        class="form-control hidden"
        type="hidden"
        name="run[site_attributes][wind_attributes][prod_factor_series_kw]" />
      <h4>Wind Costs</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='wind'
          attribute='om_cost_per_kw' />
      </div>

      <h4>Wind Incentives and Tax Treatment</h4>
      <incentives-table
        association_1='site'
        association_2='wind' />

      <h5>Tax Treatment</h5>
      <div class="form-horizontal">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='wind'
          attribute='macrs_option_years' />
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='wind'
          attribute='macrs_bonus_fraction' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import IncentivesTable from './inputs/IncentivesTable';
  import Tooltip from './Tooltip';

  export default {
    components: {
      // nested 2 b/c run.site.financial - measured from run
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      IncentivesTable,
      Tooltip,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'placeholders',
        'run',
        'selectOptions',
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updatePlaceholders: function() {
        if(this.run.site.wind.size_class == "large") {
          this.placeholders.site.wind.installed_cost_per_kw = "$2,386"
          this.placeholders.site.wind.federal_itc_fraction = "30%"
        } else if(this.run.site.wind.size_class == "medium") {
          this.placeholders.site.wind.installed_cost_per_kw = "$3,137"
          this.placeholders.site.wind.federal_itc_fraction = "30%"
        } else if(this.run.site.wind.size_class == "commercial") {
          this.placeholders.site.wind.installed_cost_per_kw = "$4,760"
          this.placeholders.site.wind.federal_itc_fraction = "30%"
        } else if(this.run.site.wind.size_class == "residential") {
          this.placeholders.site.wind.installed_cost_per_kw = "$6,339"
          this.placeholders.site.wind.federal_itc_fraction = "30%"
        }
      },
      parseCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The Wind generation profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;

                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.wind.prod_factor_series_kw = [];
                    event.target.value = "";
                    bootbox.alert('The Wind generation profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.wind.prod_factor_series_kw = [];
                  event.target.value = "";
                  bootbox.alert('The Wind generation profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                var payload = {
                  association_1: 'site',
                  association_2: 'wind',
                  attribute: 'prod_factor_series_kw',
                  value: JSON.stringify(values),
                }
                self.$store.commit('updateValueNested2', payload);
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'wind', attribute: 'prod_factor_file_name', value: file.name});
              } finally {};
            }
          });
        }
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'wind',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      resetInputs: function(event) {
        if(confirm("Are you sure you want to reset the inputs in the Wind section to their default values?")) {
          if(this.run.site.wind.size_class != 'commercial') this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'size_class', value: 'commercial' });
          if(this.run.site.wind.installed_cost_per_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'installed_cost_per_kw', value: '' });
          if(this.run.site.wind.min_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'min_kw', value: '' });
          if(this.run.site.wind.max_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'max_kw', value: '' });
          if(this.run.site.wind.prod_factor_file_data) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'prod_factor_file_data', value: '' });
          if(this.run.site.wind.prod_factor_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'prod_factor_file_name', value: '' });
          if(this.run.site.wind.prod_factor_series_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'prod_factor_series_kw', value: '' });
          if(this.run.site.wind.om_cost_per_kw) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'om_cost_per_kw', value: '' });

          if(this.run.site.wind.federal_itc_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'federal_itc_fraction', value: '' });
          if(this.run.site.wind.federal_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'federal_rebate_per_kw', value: '' });
          if(this.run.site.wind.state_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'state_ibi_fraction', value: '' });
          if(this.run.site.wind.state_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'state_ibi_max', value: '' });
          if(this.run.site.wind.state_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'state_rebate_per_kw', value: '' });
          if(this.run.site.wind.state_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'state_rebate_max', value: '' });
          if(this.run.site.wind.utility_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'utility_ibi_fraction', value: '' });
          if(this.run.site.wind.utility_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'utility_ibi_max', value: '' });
          if(this.run.site.wind.utility_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'utility_rebate_per_kw', value: '' });
          if(this.run.site.wind.utility_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'utility_rebate_max', value: '' });
          if(this.run.site.wind.production_incentive_per_kwh) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'production_incentive_per_kwh', value: '' });
          if(this.run.site.wind.pbi_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'pbi_years', value: '' });
          if(this.run.site.wind.production_incentive_max_benefit) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'production_incentive_max_benefit', value: '' });
          if(this.run.site.wind.pbi_system_max_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'pbi_system_max_kw', value: '' });
          if(this.run.site.wind.macrs_option_years != 5) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'macrs_option_years', value: 5 });
          if(this.run.site.wind.macrs_bonus_fraction != 0.8) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'wind', attribute: 'macrs_bonus_fraction', value: 0.6 });

          window.scrollTo(0, document.getElementById('wind_heading').offsetTop);
        }
      }
    },

    mounted: function() {
      this.updatePlaceholders();

      // for copied runs
      if(this.run.site.wind.prod_factor_file_data) {
        document.getElementById("run_site_attributes_wind_attributes_prod_factor_file").value = this.run.site.wind.prod_factor_file_data;
      }
    }
  }
</script>
