<template>
  <div class="form-horizontal">
    <div
      v-if="!run.grid && !customOffgridSize"
      class="form-group"
      style="margin-top:35px;margin-bottom:45px;">
      <div class="col-sm-6 control-label">
        <strong>Generator size adjustment</strong><tooltip :association_1="'site'" :association_2="'generator'" :attribute="'offgrid_size_slider'"></tooltip>
      </div>
      <div class="col-sm-4">
        <veeno
          v-model="run.site.generator.peak_load_multiplier"
          name="run[site_attributes][generator_attributes][peak_load_multiplier]"
          id="offgrid-slider"
          pipsy
          :step="1"
          :handles="parseInt(run.site.generator.peak_load_multiplier)"
          :range = "{ min: 0, max: 400 }" >
          {{ PercentOfLoadMessage }}
        </veeno>
        <input
          v-model="run.site.generator.peak_load_multiplier"
          class="form-control hidden peak-load-multiplier"
          type="hidden"
          name="run[site_attributes][generator_attributes][peak_load_multiplier]" />
      </div>
    </div>
    <div v-if="!run.grid" class="row">
      <div class="col-sm-6">&nbsp;</div>
      <div class="col-sm-4">
        <input
          v-model="customOffgridSize"
          type="checkbox"
          value="1"
          id="custom_gen_size"/>
        <label style="font-weight: normal" for="custom_gen_size">
          Custom generator size?
        </label>
      </div>
    </div>
    <horizontal-string-input-nested-2
      v-if="customOffgridSize"
      required=true
      association_1='site'
      association_2='generator'
      attribute='offgrid_size_kw' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='generator'
      attribute='installed_cost_per_kw' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='generator'
      attribute='fuel_cost_per_gallon' />
    <horizontal-string-input-nested-2
      v-if="!run.grid"
      association_1='site'
      association_2='generator'
      attribute='fuel_available_gal' />
    <horizontal-string-input-nested-2
      v-show="run.grid"
      association_1='site'
      association_2='generator'
      attribute='min_kw' />
    <horizontal-string-input-nested-2
      v-show="run.grid"
      association_1='site'
      association_2='generator'
      attribute='max_kw' />
    <div v-if="run.grid && !run.analyze_chp && !run.analyze_prime_generator" class="row section-actions">
      <div class="col-sm-6">&nbsp;</div>
      <div class="col-sm-6">
        <input
          v-model="run.site.generator.generator_only_runs_during_grid_outage"
          value="0"
          type="hidden"
          name="run[site_attributes][generator_attributes][generator_only_runs_during_grid_outage]" />
        <input
          v-model="run.site.generator.generator_only_runs_during_grid_outage"
          @click="updateInstalledCostDefault()"
          class="boolean optional"
          type="checkbox"
          value="1"
          name="run[site_attributes][generator_attributes][generator_only_runs_during_grid_outage]"
          id="run_site_attributes_generator_attributes_generator_only_runs_during_grid_outage"/>
        <label v-if="run.grid" style="font-weight: normal" for="run_site_attributes_generator_attributes_generator_only_runs_during_grid_outage">
          Emergency generator can run parallel with the grid? <tooltip :association_1="'site'" :association_2="'generator'" :attribute="'generator_only_runs_during_grid_outage'"></tooltip>
        </label>
      </div>
    </div>
    <div class="row section-actions">
      <div class="col-sm-6">&nbsp;</div>
      <div class="col-sm-4">
        <input
          v-model="run.existing_diesel_generator"
          value="0"
          type="hidden"
          name="run[existing_diesel_generator]" />
        <input
          v-model="run.existing_diesel_generator"
          class="boolean optional"
          type="checkbox"
          value="1"
          name="run[existing_diesel_generator]"
          id="run_existing_diesel_generator"/>
        <label v-if="run.grid" style="font-weight: normal" for="run_existing_diesel_generator">
          Existing generator?
        </label>
        <label v-else-if="!run.grid" style="font-weight: normal" for="run_existing_diesel_generator">
          Existing diesel generator?
        </label>
      </div>
    </div>
    <horizontal-string-input-nested-2
      v-if="run.existing_diesel_generator"
      association_1='site'
      association_2='generator'
      required='true'
      attribute='existing_kw' />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>Generator Costs</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='om_cost_per_kw' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='om_cost_per_kwh' />
      </div>

      <h4>Generator Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          v-if="run.focus == 'resilience'"
          association_1='site'
          association_2='generator'
          attribute='fuel_available_gal' />
        <!-- <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='generator_fuel_percent_RE' /> -->
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='elec_effic_full_load' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='elec_effic_half_load' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='fuel_higher_heating_value_kwh_per_gal' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='generator'
          attribute='min_turn_down_fraction' />
        <horizontal-string-input-nested-2
          v-if="!run.grid"
          association_1='site'
          association_2='generator'
          attribute='useful_life_years' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import Tooltip from './Tooltip';
  import veeno from 'veeno';
  import 'nouislider/distribute/nouislider.min.css';

  export default {
    components: {
      // nested 2 b/c run.site.financial - measured from run
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      Tooltip,
      veeno,
    },

    data: function() {
      return {
        customOffgridSize: false,
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',
        'run'
      ]),
      PercentOfLoadMessage: function() {
        var percent = Number(this.run.site.generator.peak_load_multiplier).toFixed(0);
        var elecLoadProfile = this[this.$store.getters.elecLoadProfile];
        var factor = this.run.site.load_profile.load_reduction / 100.0;

        if(elecLoadProfile.maxKw) {
          var genSize = (percent / 100.0) * elecLoadProfile.maxKw * factor;

          if(Number(genSize)) {
            this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'min_kw', value: genSize });
            this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'max_kw', value: genSize });
          }

          if(genSize < 50) {
            var genSize = this.$store.getters.formatAsIntegerWithCommas({ value: genSize.toFixed(2), decimals: 2 });
          } else {
            var genSize = this.$store.getters.formatAsIntegerWithCommas({ value: genSize.toFixed(0) });
          }

          return `${percent}% of peak electric load (${genSize} kW)`;
        } else {
          return `${percent}% of peak electric load`;
        }
      }
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      resetInputs: function(event) {
        var self = this;

        if(confirm("Are you sure you want to reset the inputs in the Generator section to their default values?")) {
          if(this.run.site.generator.peak_load_multiplier != 200) {
            self.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'peak_load_multiplier', value: 200});
            var slider = document.getElementById('offgrid-slider');
            slider.noUiSlider.set(200);
          }
          if(this.customOffgridSize) this.customOffgridSize = false;
          if(this.run.site.generator.offgrid_size_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'offgrid_size_kw', value: ''});
          if(this.run.site.generator.installed_cost_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'installed_cost_per_kw', value: ''});
          if(this.run.site.generator.fuel_cost_per_gallon) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'fuel_cost_per_gallon', value: ''});
          if(this.run.site.generator.fuel_available_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'fuel_available_gal', value: ''});
          if(this.run.site.generator.min_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'min_kw', value: ''});
          if(this.run.site.generator.max_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'max_kw', value: ''});
          if(this.run.existing_diesel_generator) this.$store.commit({type: 'updateValue', attribute: 'existing_diesel_generator', value: false });
          if(this.run.site.generator.existing_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'existing_kw', value: ''});

          if(this.run.site.generator.om_cost_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'om_cost_per_kw', value: ''});
          if(this.run.site.generator.om_cost_per_kwh) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'om_cost_per_kwh', value: ''});

          if(this.run.site.generator.fuel_slope_gal_per_kwh) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'fuel_slope_gal_per_kwh', value: ''});
          if(this.run.site.generator.fuel_intercept_gal_per_hr) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'fuel_intercept_gal_per_hr', value: ''});
          if(this.run.site.generator.elec_effic_full_load) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'elec_effic_full_load', value: ''});
          if(this.run.site.generator.elec_effic_half_load) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'elec_effic_half_load', value: ''});
          if(this.run.site.generator.fuel_higher_heating_value_kwh_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'fuel_higher_heating_value_kwh_per_gal', value: ''});
          if(this.run.site.generator.min_turn_down_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'min_turn_down_fraction', value: ''});
          if(this.run.site.generator.useful_life_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'useful_life_years', value: ''});

          window.scrollTo(0, document.getElementById('generator_heading').offsetTop);
        }
      },
      customizeSliderPips: function() {
        // definitely not pretty but not certain how to style pips without digging
        // into package's css file :/
        var pip100 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='100' style='left:25%;'>100</div>";
        var pip100Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:25%;'></div>";
        var pip200 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='200' style='left:50%;'>200</div>";
        var pip200Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:50%;'></div>";
        var pip300 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='300' style='left:75%;'>300</div>";
        var pip300Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:75%;'></div>";
        document.getElementById("offgrid-slider").getElementsByClassName("noUi-pips-horizontal")[0].innerHTML += ((pip100 + pip100Marker) + (pip200 + pip200Marker) + (pip300 + pip300Marker));
      },
      updateInstalledCostDefault: function() {
        // have to say "!this.run.site.generator.generator_only_runs_during_grid_outage" as when unchecked and clicked, that value is null.
        if (!this.run.site.generator.generator_only_runs_during_grid_outage) {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'generator', attribute: 'installed_cost_per_kw', value: "$800" });
        } else {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'generator', attribute: 'installed_cost_per_kw', value: "$650" });
        }
      }
    },

    watch: {
      "run.grid": function() {
        if(!this.run.grid) {
          var self = this;
          // nextTick means wait till all rendering has happened (slider is re-rendered)
          this.$nextTick(() => self.customizeSliderPips());
        }
      },
      "run.existing_diesel_generator": function() {
        if (!this.run.existing_diesel_generator && this.run.site.generator.existing_kw) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'generator', attribute: 'existing_kw', value: null });
        }
      },
      "customOffgridSize": function() {
        if(!this.customOffgridSize) {
          var self = this;
          this.run.site.generator.offgrid_size_kw = '';
          this.$nextTick(() => self.customizeSliderPips());
        } else if (this.customOffgridSize && this.run.site.generator.peak_load_multiplier !== 200) {
          this.run.site.generator.peak_load_multiplier = 200;
        }
      }
    },

    mounted: function() {
      if(this.run.site.generator.offgrid_size_kw) {
        this.customOffgridSize = true;
      } else {
        this.customOffgridSize = false;
      }

      if(!this.run.grid) {
        this.customizeSliderPips();
      }

      if (!this.run.existing_diesel_generator && this.run.site.generator.existing_kw) {
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'generator', attribute: 'existing_kw', value: null });
      }
    }
  }
</script>
