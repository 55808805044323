import App from '../app.vue'
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import RunForm from '../components/run/RunForm'
import PortfolioRunForm from '../components/run/PortfolioRunForm'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";

Stock(Highcharts);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Vuex)
Vue.use(HighchartsVue);

document.addEventListener('DOMContentLoaded', () => {
  const currentUser = $("#run-form").data('currentUser');
  const typicalLoadProfiles = $("#run-form").data('typicalLoadProfiles');
  const criticalLoadProfiles = $("#run-form").data('criticalLoadProfiles');
  const customRates = $("#run-form").data('customRates');
  const criticalBuilds = $("#run-form").data('criticalBuilds');
  const criticalBuild = $("#run-form").data('criticalBuild');
  const loadComponentsAttributes = $("#run-form").data('loadComponentsAttributes');
  const baseComponents = $("#run-form").data('baseComponents');
  const run = $("#run-form").data('run');
  const portfolioRun = $("#run-form").data('portfolioRun');
  const sectionHeaders = $("#run-form").data('sectionHeaders');
  const placeholders = $("#run-form").data('placeholders');
  const labels = $("#run-form").data('labels');
  const tooltips = $("#run-form").data('tooltips');
  const selectOptions = $("#run-form").data('selectOptions');
  const urlRoot = window.location.href.split("tool")[0] + "tool";
  const sampleSiteParams = $("#run-form").data('sampleSiteParams');
  const csrfToken = $("#run-form").data('csrfToken');
  const multiRunParams = $("#run-form").data('multiRunParams');
  var submitError = false;
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var portfolio = {
    name: '',
    runs: ''
  };
  var runsUtilityRatesLoading = [];
  var runsUtilityRates = [];
  var runsPlaceholders = [];
  var clientSideValidations = {
    run: {
      site: {
        address: "Site location can't be blank",
        electric_tariff: {
          urdb_label: "Electricity rate can't be blank",
          custom_urdb_label: "URDB label can't be blank",
          co2_annual_emissions_factor: "Annual CO₂e grid emissions factor (lbs CO₂e/kWh) can't be blank",
          nox_annual_emissions_factor: "Annual NOx grid emissions factor (lbs NOx/kWh) can't be blank",
          so2_annual_emissions_factor: "Annual SO2 grid emissions factor (lbs SO2/kWh) can't be blank",
          pm25_annual_emissions_factor: "Annual PM2.5 grid emissions factor (lbs PM2.5/kWh) can't be blank",
          emissions_file: "Hourly health emissions factors can't be blank",
          cambium_emissions_file: "Hourly climate emissions factors can't be blank"
        },
        load_profile: {
          load_profile_boiler_fuel: {
            annual_mmbtu: "Annual boiler fuel consump. can't be blank",
            doe_reference_name: "Type of building can't be blank"
          },
          load_profile_chiller_thermal: {
            annual_tonhour: "Annual thermal energy consump. can't be blank",
            doe_reference_name: "Type of building can't be blank"
          },
          doe_reference_name: "Type of building can't be blank",
          annual_kwh: "Annual energy consump. can't be blank",
          number_of_outages: "Number of outages can't be blank",
          outage_duration: "Outage duration can't be blank",
          outage_start_date: "Outage start date can't be blank",
          outage_start_hour: "Outage start time can't be blank"
        },
        chp: {
          boiler_fuel_blended_annual_rates_per_mmbtu: "Heating system fuel cost must be greater than or equal to 0",
          fuel_blended_annual_rates_per_mmbtu: "CHP fuel cost must be greater than or equal to 0"
        },
        prime_generator: {
          fuel_blended_annual_rates_per_mmbtu: "Prime Generator fuel cost must be greater than or equal to 0"
        }
      }
    }
  }
  var runsClientSideValidations = {
    run: {
      site: {
        address: "Site location can't be blank",
        electric_tariff: {
          urdb_label: "Electricity rate can't be blank",
          co2_annual_emissions_factor: "Annual CO₂ grid emissions factor (lbs CO₂/kWh) can't be blank",
          nox_annual_emissions_factor: "Annual NOx grid emissions factor (lbs NOx/kWh) can't be blank",
          so2_annual_emissions_factor: "Annual SO2 grid emissions factor (lbs SO2/kWh) can't be blank",
          pm25_annual_emissions_factor: "Annual PM2.5 grid emissions factor (lbs PM2.5/kWh) can't be blank",
          emissions_file: "Hourly emissions factor can't be blank"
        },
        load_profile: {
          load_profile_boiler_fuel: {
            annual_mmbtu: "Annual boiler fuel consump. can't be blank",
            doe_reference_name: "Type of building can't be blank"
          },
          load_profile_chiller_thermal: {
            annual_tonhour: "Annual thermal energy consump. can't be blank",
            doe_reference_name: "Type of building can't be blank"
          },
          doe_reference_name: "Type of building can't be blank",
          annual_kwh: "Annual energy consump. can't be blank",
          outage_duration: "Outage duration can't be blank",
          outage_start_date: "Outage start date can't be blank",
          outage_start_hour: "Outage start time can't be blank"
        },
        chp: {
          boiler_fuel_blended_annual_rates_us_dollars_per_mmbtu: "Heating system fuel cost must be greater than or equal to 0",
          chp_fuel_blended_annual_rates_us_dollars_per_mmbtu: "CHP fuel cost must be greater than or equal to 0"
        },
        prime_generator: {
          prime_generator_fuel_blended_annual_rates_us_dollars_per_mmbtu: "Prime Generator fuel cost must be greater than or equal to 0"
        }
      }
    }
  }

  if(!run.site.load_profile.campus_buildings) {
    run.site.load_profile.campus_buildings = [
      { doe_reference_name: '', percent_of_total: '' },
      { doe_reference_name: '', percent_of_total: '' }
    ];
  }
  if(!run.site.load_profile.load_profile_boiler_fuel.campus_buildings) {
    run.site.load_profile.load_profile_boiler_fuel.campus_buildings = [
      { doe_reference_name: '', percent_of_total: '' },
      { doe_reference_name: '', percent_of_total: '' }
    ];
  }
  if(!run.site.load_profile.load_profile_chiller_thermal.campus_buildings) {
    run.site.load_profile.load_profile_chiller_thermal.campus_buildings = [
      { doe_reference_name: '', percent_of_total: '' },
      { doe_reference_name: '', percent_of_total: '' }
    ];
  }

  // for multiple outages 
  if(!run.site.load_profile.multiple_outages) {
    run.site.load_profile.multiple_outages = [
      { outage_duration: '', outage_start_hour: '', outage_start_date: '', outage_start_time_step: '' },
      { outage_duration: '', outage_start_hour: '', outage_start_date: '', outage_start_time_step: '' },
      { outage_duration: '', outage_start_hour: '', outage_start_date: '', outage_start_time_step: '' },
      { outage_duration: '', outage_start_hour: '', outage_start_date: '', outage_start_time_step: '' }
    ];
  }
  
  // for resilience sample site
  if(run.site.load_profile.outage_start_date) {
    var year = Number(run.site.load_profile.outage_start_date.split("-")[0]);

    if(run.site.load_profile.outage_start_hour > 24) {
      run.site.load_profile.outage_start_hour = run.site.load_profile.outage_start_hour % 24;
    }
  } else {
    var year = placeholders.site.load_profile.year;
  }

  if(run.site.pv && run.site.pv.existing_kw) {
    // this is for copied runs b/c pv.existing_pv is not a field in the UI dB
    // but we use it in Vue
    run.site.pv.existing_pv = true;
  }

  let store = new Vuex.Store({
    state: {
      currentUser: currentUser,
      typicalLoadProfiles: typicalLoadProfiles,
      criticalLoadProfiles: criticalLoadProfiles,
      customRates: customRates,
      criticalBuilds: criticalBuilds,
      criticalBuild: criticalBuild,
      loadComponentsAttributes: loadComponentsAttributes,
      baseComponents: baseComponents,
      csrfToken: csrfToken,
      labels: labels,
      placeholders: placeholders,
      userEnteredAnnualKwhValue: '',
      userEnteredMonthlyKwhValues: [],
      run: run,
      sectionHeaders: sectionHeaders,
      selectOptions: selectOptions,
      tooltips: tooltips,
      urlRoot: urlRoot,
      sampleSiteParams: sampleSiteParams,
      multiRunParams: multiRunParams,
      portfolioRun: portfolioRun,
      portfolio: portfolio,
      months: months,
      showStartOutageOnPeakButton: false,
      year: year,
      clientSideValidations: clientSideValidations,
      runsClientSideValidations: runsClientSideValidations,
      utilityRates: [],
      utilityRatesLoading: false,
      runsUtilityRates: runsUtilityRates,
      runsUtilityRatesLoading: runsUtilityRatesLoading,
      runsPlaceholders: runsPlaceholders,
      displayAnnualEmissionsFactors: true,
      displayGridClimateEmissionsWarning: false,
      displayGridHealthEmissionsWarning: false,
      displayEmissionsHealthCostsWarning: false,
      submitError: submitError,

      chpMaintenanceDefaultSchedule: {},
      chpMaintenanceUploadedSchedule: {},
      chpMaintenanceScheduleLoading: false,
      showCHPDefaultSummarySchedule: false,
      showCHPDefaultDetailedSchedule: false,
      showCHPUploadedSummarySchedule: false,
      showCHPUploadedDetailedSchedule: false,
      showCHPMaxSizeButton: false,
      chpHeuristicSize: '',
      chpHeuristicSizeClass: '',

      emissionsHourlyProfile: {},
      emissionsHourlyLoading: false,
      showEmissionsHourlyProfile: false,
      emissionsUploadedProfile: {},
      cambiumEmissionsUploadedProfile: {},
      emissionsUploadedLoading: false,
      showEmissionsUploadedProfile: false,

      showElecSimBuildAnnualLoadProfile: false,
      showElecSimBuildMonthlyLoadProfile: false,
      showElecSimCampusLoadProfile: false,
      showElecUploadedLoadProfile: false,

      showThermalSimBuildAnnualLoadProfile: false,
      showThermalSimBuildMonthlyLoadProfile: false,
      showThermalSimCampusLoadProfile: false,
      showThermalUploadedLoadProfile: false,

      showColdSimBuildLoadProfile: false,
      showColdSimBuildAnnualLoadProfile: false,
      showColdSimBuildMonthlyLoadProfile: false,
      showColdSimCampusFromElecLoadProfile: false,
      showColdSimCampusLoadProfile: false,
      showColdUploadedLoadProfile: false,
      showColdCustomAnnualPctLoadProfile: false,
      showColdCustomMonthlyPctLoadProfile: false,
      ratioHeatingToCooling: '',
      annualMmbtuForRatio: '',
      annualTonHourForRatio: '',
      dhwAnnualMMbtuForRatio: '',

      showGHPCOPCustomTable: false,
      showGHPCOPWwhpCustomTable: false,
      showGHPCOPDefaultTable: false,
      ghpCOPCustomData: {},
      ghpCOPColdCustomData: {},
      ghpCOPHotCustomData: {},

      elecSimBuildAnnualLoadProfile: {},
      elecSimBuildMonthlyLoadProfile: {},
      elecSimCampusLoadProfile: {},
      elecUploadedLoadProfile: {},
      elecLoadProfileLoading: false,

      thermalSimBuildAnnualLoadProfile: {},
      thermalSimBuildMonthlyLoadProfile: {},
      thermalUploadedLoadProfile: {},
      thermalLoadProfileLoading: false,
      thermalSimCampusLoadProfile: {},

      coldSimBuildLoadProfile: {},
      coldSimBuildAnnualLoadProfile: {},
      coldSimBuildMonthlyLoadProfile: {},
      coldSimCampusFromElecLoadProfile: {},
      coldSimCampusLoadProfile: {},
      coldUploadedLoadProfile: {},
      coldCustomAnnualPctLoadProfile: {},
      coldCustomMonthlyPctLoadProfile: {},
      coldLoadProfileLoading: false,

      showCriticalPercentLoadProfile: false,
      showCriticalUploadedLoadProfile: false,
      showCriticalBuildLoadProfile: false,

      criticalLoadProfileLoading: false,
      criticalPercentLoadProfile: {},
      criticalUploadedLoadProfile: {},
      criticalBuildLoadProfile: {},

      showTypicalLoadProfileForm: false,
      showCriticalLoadProfileForm: false,
      showCriticalBuildForm: false,
      flatPickrConfig: {
        monthSelectorType: "static",
        enableTime: false,
        // Show a more human readable output on the screen (but submit a more
        // machine readable format).
        altInput: true,
        altFormat: 'F j',
        // Pick a non-leap year in the future (so the calendar pops up starting
        // in January).
        minDate: year + '-01-01',
        maxDate: year + '-12-31',
        // Since we've customized this input quite a bit to be non-year specific,
        // also keep using it on mobile devices (rather than reverting to native
        // date pickers on mobile).
        disableMobile: true,
        // Enable support of clicking on the calendar icon addon (outside the
        // input) and have the calendar pop up.
        wrap: true,
        // display client side validation error
        // onClose: function() {
        //   toggleClientSideValidation({
        //     selector: '#run_site_attributes_load_profile_attributes_outage_start_date',
        //     message: "Outage start date can't be blank",
        //     centerMessage: true
        //   }
        // }
      }
    },

    getters: {
      currentUser: (state) => {
        return state.currentUser;
      },
      clientSideValidations: (state) => {
        return state.clientSideValidations;
      },
      runsClientSideValidations: (state) => {
        return state.runsClientSideValidations
      },
      months: (state) => {
        return state.months;
      },
      portfolioRun: (state) => {
        return state.portfolioRun;
      },
      portfolio: (state) => {
        return state.portfolio;
      },
      typicalLoadProfiles: (state) => {
        return state.typicalLoadProfiles;
      },
      criticalLoadProfiles: (state) => {
        return state.criticalLoadProfiles;
      },
      customRates: (state) => {
        return state.customRates;
      },
      criticalBuilds: (state) => {
        return state.criticalBuilds;
      },
      criticalBuild: (state) => {
        return state.criticalBuild;
      },
      loadComponentsAttributes: (state) => {
        return state.loadComponentsAttributes;
      },
      baseComponents: (state) => {
        return state.baseComponents;
      },
      run: (state) => {
        return state.run;
      },
      sectionHeaders: (state) => {
        return state.sectionHeaders;
      },
      placeholders: (state) => {
        return state.placeholders;
      },
      userEnteredAnnualKwhValue: (state) => {
        return state.userEnteredAnnualKwhValue;
      },
      userEnteredMonthlyKwhValues: (state) => {
        return state.userEnteredMonthlyKwhValues;
      },
      runsPlaceholders: (state) => {
        return state.runsPlaceholders
      },
      labels: (state) => {
        return state.labels;
      },
      tooltips: (state) => {
        return state.tooltips;
      },
      selectOptions: (state) => {
        return state.selectOptions;
      },
      urlRoot: (state) => {
        return state.urlRoot;
      },
      csrfToken: (state) => {
        return state.csrfToken;
      },
      chpHeuristicSize: (state) => {
        return state.chpHeuristicSize;
      },
      chpHeuristicSizeClass: (state) => {
        return state.chpHeuristicSizeClass;
      },
      ratioHeatingToCooling: (state) => {
        return state.ratioHeatingToCooling;
      },
      annualMmbtuForRatio: (state) => {
        return state.annualMmbtuForRatio;
      },
      annualTonHourForRatio: (state) => {
        return state.annualTonHourForRatio;
      },
      dhwAnnualMMbtuForRatio: (state) => {
        return state.dhwAnnualMMbtuForRatio;
      },
      showCHPMaxSizeButton: (state) => {
        return state.showCHPMaxSizeButton;
      },
      ghpCOPCustomData: (state) => {
        return state.ghpCOPCustomData;
      },
      ghpCOPColdCustomData: (state) => {
        return state.ghpCOPColdCustomData;
      },
      ghpCOPHotCustomData: (state) => {
        return state.ghpCOPHotCustomData;
      },
      showGHPCOPCustomTable: (state) => {
        return state.showGHPCOPCustomTable;
      },
      showGHPCOPWwhpCustomTable: (state) => {
        return state.showGHPCOPWwhpCustomTable;
      },
      showGHPCOPDefaultTable: (state) => {
        return state.showGHPCOPDefaultTable;
      },
      showElecSimBuildAnnualLoadProfile: (state) => {
        return state.showElecSimBuildAnnualLoadProfile;
      },
      showElecSimBuildMonthlyLoadProfile: (state) => {
        return state.showElecSimBuildMonthlyLoadProfile;
      },
      showElecSimCampusLoadProfile: (state) => {
        return state.showElecSimCampusLoadProfile;
      },
      showElecUploadedLoadProfile: (state) => {
        return state.showElecUploadedLoadProfile;
      },
      showThermalSimBuildAnnualLoadProfile: (state) => {
        return state.showThermalSimBuildAnnualLoadProfile;
      },
      showThermalSimBuildMonthlyLoadProfile: (state) => {
        return state.showThermalSimBuildMonthlyLoadProfile;
      },
      showThermalSimCampusLoadProfile: (state) => {
        return state.showThermalSimCampusLoadProfile;
      },
      showThermalUploadedLoadProfile: (state) => {
        return state.showThermalUploadedLoadProfile;
      },
      showColdSimBuildLoadProfile: (state) => {
        return state.showColdSimBuildLoadProfile;
      },
      showColdSimBuildAnnualLoadProfile: (state) => {
        return state.showColdSimBuildAnnualLoadProfile;
      },
      showColdSimBuildMonthlyLoadProfile: (state) => {
        return state.showColdSimBuildMonthlyLoadProfile;
      },
      showColdSimCampusFromElecLoadProfile: (state) => {
        return state.showColdSimCampusFromElecLoadProfile;
      },
      showColdSimCampusLoadProfile: (state) => {
        return state.showColdSimCampusLoadProfile;
      },
      showColdUploadedLoadProfile: (state) => {
        return state.showColdUploadedLoadProfile;
      },
      showColdCustomAnnualPctLoadProfile: (state) => {
        return state.showColdCustomAnnualPctLoadProfile;
      },
      showColdCustomMonthlyPctLoadProfile: (state) => {
        return state.showColdCustomMonthlyPctLoadProfile;
      },
      elecSimBuildAnnualLoadProfile: (state) => {
        return state.elecSimBuildAnnualLoadProfile;
      },
      elecSimBuildMonthlyLoadProfile: (state) => {
        return state.elecSimBuildMonthlyLoadProfile;
      },
      elecSimCampusLoadProfile: (state) => {
        return state.elecSimCampusLoadProfile;
      },
      elecUploadedLoadProfile: (state) => {
        return state.elecUploadedLoadProfile;
      },
      elecLoadProfileLoading: (state) => {
        return state.elecLoadProfileLoading;
      },
      thermalSimBuildAnnualLoadProfile: (state) => {
        return state.thermalSimBuildAnnualLoadProfile;
      },
      thermalSimBuildMonthlyLoadProfile: (state) => {
        return state.thermalSimBuildMonthlyLoadProfile;
      },
      thermalUploadedLoadProfile: (state) => {
        return state.thermalUploadedLoadProfile;
      },
      thermalLoadProfileLoading: (state) => {
        return state.thermalLoadProfileLoading;
      },
      thermalSimCampusLoadProfile: (state) => {
        return state.thermalSimCampusLoadProfile;
      },
      coldSimBuildLoadProfile: (state) => {
        return state.coldSimBuildLoadProfile;
      },
      coldSimBuildAnnualLoadProfile: (state) => {
        return state.coldSimBuildAnnualLoadProfile;
      },
      coldSimBuildMonthlyLoadProfile: (state) => {
        return state.coldSimBuildMonthlyLoadProfile;
      },
      coldSimCampusFromElecLoadProfile: (state) => {
        return state.coldSimCampusFromElecLoadProfile;
      },
      coldSimCampusLoadProfile: (state) => {
        return state.coldSimCampusLoadProfile;
      },
      coldUploadedLoadProfile: (state) => {
        return state.coldUploadedLoadProfile;
      },
      coldCustomAnnualPctLoadProfile: (state) => {
        return state.coldCustomAnnualPctLoadProfile;
      },
      coldCustomMonthlyPctLoadProfile: (state) => {
        return state.coldCustomMonthlyPctLoadProfile;
      },
      coldLoadProfileLoading: (state) => {
        return state.coldLoadProfileLoading;
      },
      sampleSiteParams: (state) => {
        return state.sampleSiteParams;
      },
      multiRunParams: (state) => {
        return state.multiRunParams;
      },
      showCriticalPercentLoadProfile: (state) => {
        return state.showCriticalPercentLoadProfile;
      },
      showCriticalUploadedLoadProfile: (state) => {
        return state.showCriticalUploadedLoadProfile;
      },
      showCriticalBuildLoadProfile: (state) => {
        return state.showCriticalBuildLoadProfile;
      },
      showCriticalBuildForm: (state) => {
        return state.showCriticalBuildForm;
      },
      criticalLoadProfileLoading: (state) => {
        return state.criticalLoadProfileLoading;
      },
      criticalPercentLoadProfile: (state) => {
        return state.criticalPercentLoadProfile;
      },
      criticalUploadedLoadProfile: (state) => {
        return state.criticalUploadedLoadProfile;
      },
      criticalBuildLoadProfile: (state) => {
        return state.criticalBuildLoadProfile;
      },
      showTypicalLoadProfileForm: (state) => {
        return state.showTypicalLoadProfileForm;
      },
      showCriticalLoadProfileForm: (state) => {
        return state.showCriticalLoadProfileForm;
      },
      showStartOutageOnPeakButton: (state) => {
        return state.showStartOutageOnPeakButton;
      },
      flatPickrConfig: (state) => {
        return state.flatPickrConfig;
      },
      emissionsHourlyProfile: (state) => {
        return state.emissionsHourlyProfile;
      },
      emissionsHourlyLoading: (state) => {
        return state.emissionsHourlyLoading;
      },
      showEmissionsHourlyProfile: (state) => {
        return state.showEmissionsHourlyProfile;
      },
      CO2EmissionsAnnualProfile: (state) => {
        return state.CO2EmissionsAnnualProfile;
      },
      emissionsUploadedProfile: (state) => {
        return state.emissionsUploadedProfile;
      },
      cambiumEmissionsUploadedProfile: (state) => {
        return state.cambiumEmissionsUploadedProfile;
      },
      emissionsUploadedLoading: (state) => {
        return state.emissionsUploadedLoading;
      },
      showEmissionsUploadedProfile: (state) => {
        return state.showEmissionsUploadedProfile;
      },
      year: (state) => {
        return state.year;
      },
      displayAnnualEmissionsFactors: (state) => {
        return state.displayAnnualEmissionsFactors;
      },
      chpMaintenanceDefaultSchedule: (state) => {
        return state.chpMaintenanceDefaultSchedule;
      },
      chpMaintenanceUploadedSchedule: (state) => {
        return state.chpMaintenanceUploadedSchedule;
      },
      chpMaintenanceScheduleLoading: (state) => {
        return state.chpMaintenanceScheduleLoading;
      },
      showCHPDefaultSummarySchedule: (state) => {
        return state.showCHPDefaultSummarySchedule;
      },
      showCHPDefaultDetailedSchedule: (state) => {
        return state.showCHPDefaultDetailedSchedule;
      },
      showCHPUploadedSummarySchedule: (state) => {
        return state.showCHPUploadedSummarySchedule;
      },
      showCHPUploadedDetailedSchedule: (state) => {
        return state.showCHPUploadedDetailedSchedule;
      },
      utilityRates: (state) => {
        return state.utilityRates;
      },
      utilityRatesLoading: (state) => {
        return state.utilityRatesLoading;
      },
      runsUtilityRates: (state) => {
        return state.runsUtilityRates;
      },
      runsUtilityRatesLoading: (state) => {
        return state.runsUtilityRatesLoading;
      },
      displayGridClimateEmissionsWarning: (state) => {
        return state.displayGridClimateEmissionsWarning;
      },
      displayGridHealthEmissionsWarning: (state) => {
        return state.displayGridHealthEmissionsWarning;
      },
      displayEmissionsHealthCostsWarning: (state) => {
        return state.displayEmissionsHealthCostsWarning;
      },
      submitError: (state) => {
        return state.submitError
      },
      formatAsIntegerWithCommas: (state) => (payload) => {
        if(!payload.decimals) {
          var decimals = 0;
        } else {
          var decimals = payload.decimals;
        }

        return Number(payload.value).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      average: (state) => (array) => {
        var total = 0;
        for(var i = 0; i < array.length; i++) {
            total += array[i];
        }

        return total / array.length;
      },
      elecLoadProfile: (state) => {
        if(state.run.site.load_profile.typical_load_type == 'simulated_building') {
          if(state.run.site.load_profile.simulated_load_type == 'annual') {
            return 'elecSimBuildAnnualLoadProfile';
          } else if(state.run.site.load_profile.simulated_load_type == 'monthly') {
            return 'elecSimBuildMonthlyLoadProfile';
          }
        } else if(state.run.site.load_profile.typical_load_type == 'simulated_campus') {
          return 'elecSimCampusLoadProfile';
        } else if(state.run.site.load_profile.typical_load_type == 'uploaded') {
          return 'elecUploadedLoadProfile';
        }
      },
      thermalLoadProfile: (state) => {
        if(state.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated') {
          if(state.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'annual') {
            return 'thermalSimBuildAnnualLoadProfile';
          } else if(state.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'monthly') {
            return 'thermalSimBuildMonthlyLoadProfile';
          }
        } else if(state.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated_campus') {
          return 'thermalSimCampusLoadProfile';
        } else if(state.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'uploaded') {
          return 'thermalUploadedLoadProfile';
        }
      },
      coldLoadProfile: (state) => {
        if(state.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "simulated") {
          if(!state.run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump) {
            return 'coldSimBuildLoadProfile';
          } else if(state.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == "annual") {
            return 'coldSimBuildAnnualLoadProfile';
          } else if(state.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == "monthly") {
            return 'coldSimBuildMonthlyLoadProfile';
          }
        } else if(state.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "simulated_campus") {
          if(!state.run.site.load_profile.load_profile_chiller_thermal.custom_annual_simulated_campus) {
            return 'coldSimCampusFromElecLoadProfile';
          } else {
            return 'coldSimCampusLoadProfile';
          }
        } else if(state.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "uploaded") {
          return 'coldUploadedLoadProfile';
        } else if(state.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "custom") {
          if(state.run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'annual') {
            return 'coldCustomAnnualPctLoadProfile';
          } else if(state.run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'monthly') {
            return 'coldCustomMonthlyPctLoadProfile';
          }
        }

        return loadProfile;
      }
    },

    mutations: {
      updateObject: function(state, payload) {
        state[payload.object] = payload.value;
      },
      updateValue: function(state, payload) {
        state["run"][payload.attribute] = payload.value;
      },
      updateSubmitError: function(state, payload) {
        state['submitError'] = payload
      },
      updatePortfolioValue: function(state, payload) {
        state["portfolioRun"][payload.attribute] = payload.value;
      },
      updateValueNested1: function(state, payload) {
        state["run"][payload.association_1][payload.attribute] = payload.value;
      },
      updateValueNested2: function(state, payload) {
        state["run"][payload.association_1][payload.association_2][payload.attribute] = payload.value;
      },
      updatePortfolioValueNested2: function(state, payload) {
        state["portfolioRun"][payload.association_1][payload.association_2][payload.attribute] = payload.value;
      },
      updateValueNested3: function(state, payload) {
        state["run"][payload.association_1][payload.association_2][payload.association_3][payload.attribute] = payload.value;
      },
      updateRuns: function(state, payload) {
        if (!state["portfolio"]["runs"]) {
          state["portfolio"]["runs"] = payload;
        } else {
          state["portfolio"]["runs"].push(payload);
        }
      },
      updateRunsValues: function(state, payload) {
        if(payload.association_3) {
          state["portfolio"]["runs"][payload.index][payload.association_1][payload.association_2][payload.association_3][payload.attribute] = payload.value;
        } else if(payload.association_2) {
          state["portfolio"]["runs"][payload.index][payload.association_1][payload.association_2][payload.attribute] = payload.value;
        } else if(payload.association_1) {
          state["portfolio"]["runs"][payload.index][payload.association_1][payload.attribute] = payload.value;
        } else if(payload.attribute) {
          state["portfolio"]["runs"][payload.index][payload.attribute] = payload.value;
        }
      },
      updateRunsUtilityRates: function(state, payload) {
        state[payload.object][payload.index] = payload.value;
      },
      removeCampusBuilding: function(state) {
        var index = state.run.site.load_profile.campus_buildings.length - 1;
        state.run.site.load_profile.campus_buildings.splice(index, 1);
      },
      removeThermalCampusBuilding: function(state) {
        var index = state.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.length - 1;
        state.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.splice(index, 1);
      },
      removeColdCampusBuilding: function(state) {
        var index = state.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.length - 1;
        state.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.splice(index, 1);
      },
      updateLabel: function(state, payload) {
        if(payload.association_1) {
          state.labels[payload.association_1][payload.attribute] = payload.value;
        }
      },
      updatePlaceholder: function(state, payload) {
        if(payload.association_3) {
          state.placeholders[payload.association_1][payload.association_2][payload.association_3][payload.attribute] = payload.value;
        } else if(payload.association_2) {
          state.placeholders[payload.association_1][payload.association_2][payload.attribute] = payload.value;
        } else if(payload.association_1) {
          state.placeholders[payload.association_1][payload.attribute] = payload.value;
        } else if(payload.attribute) {
          state.placeholders[payload.attribute] = payload.value;
        }
      },
      updateRunsPlaceholder: function(state, payload) {
        if (payload.association_2) {
          state["runsPlaceholders"][payload.index][payload.association_1][payload.association_2][payload.attribute] = payload.value;
        } else if (payload.association_1) {
          state["runsPlaceholders"][payload.index][payload.association_1][payload.attribute] = payload.value;
        }
      },
      updateOutageDateHour: function(state, payload) {
        state.run.site.load_profile.multiple_outages[payload.index].outage_duration = payload.outage_duration;
        state.run.site.load_profile.multiple_outages[payload.index].outage_start_date = payload.outage_start_date;
        state.run.site.load_profile.multiple_outages[payload.index].outage_start_hour = payload.outage_start_hour;
        state.run.site.load_profile.multiple_outages[payload.index].outage_start_time_step = payload.outage_start_time_step;
      },
      updateSelectOptionsForInput: function(state, payload) {
        state.selectOptions.site[payload.association_2][payload.input] = payload.value;
      },
      updateFlatPickrYear: function(state, year) {
        if(!state.run.site.load_profile.outage_start_date) {
          state.run.site.load_profile.outage_start_date = undefined;
        }
        state.flatPickrConfig.minDate = `${year}-01-01`;
        state.flatPickrConfig.maxDate = `${year}-12-31`;
      },
      updateTooltip: function(state, payload) {
        if(payload.association_1) {
          state.tooltips[payload.association_1][payload.attribute] = payload.value;
        }
      }
    }
  })

  var runForm = new Vue({
    el: '#run-form',
    store,
    components: {
      RunForm,
      PortfolioRunForm
    }
  })
})
