<template>
  <div class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="closeLoadProfile()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Typical Grid Emissions Profile Based on Your Inputs</h4>
          </div>
          <div class="modal-body">
            <highcharts :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [ 'type' ],

    // when download option available again, might need to update this somehow to not include co2 column?
    // also might need to add a different modal for the climate download as it will only have co2 column?
    data: function() {
      return {
        chartOptions: {
          credits: {
            enabled: false
          },
          yAxis: {
            title: {
              text: 'Grid Emissions Factor (lbs/kWh)',
            }
          },
          series: [
            {
              name: 'CO2 Emissions Factor',
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' lbs CO2/kWh'
              }
            },
            {
              name: 'NOx Emissions Factor',
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' lbs NOx/kWh'
              }
            },
            {
              name: 'SO2 Emissions Factor',
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' lbs SO2/kWh'
              }
            },
            {
              name: 'PM2.5 Emissions Factor',
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' lbs PM2.5/kWh'
              }
            }
          ]
        }
      }
    },

    computed: {
      ...mapGetters([
        'emissionsHourlyProfile',
        'emissionsUploadedProfile',
        'run',
        'months',
        'year'
      ]),
      valueInterval: function() {
        // Based on how big the series is, determine how much time each
        // value represents.
        var interval = 60 * 60 * 1000; // 1 hour
        if(this[this.type].co2.series.length == 17520) {
          interval = 30 * 60 * 1000; // 30 minutes
        } else if(this[this.type].co2.series.length == 35040) {
          interval = 15 * 60 * 1000; // 15 minutes
        }

        return interval
      },
      minFactor: function() {
        var combined = this[this.type].co2.series.concat(this[this.type].nox.series).concat(this[this.type].so2.series).concat(this[this.type].pm25.series);

        return Math.min(...combined);
      },
      maxFactor: function() {
        var combined = this[this.type].co2.series.concat(this[this.type].nox.series).concat(this[this.type].so2.series).concat(this[this.type].pm25.series);

        return Math.max(...combined);
      }
    },

    methods: {
      closeLoadProfile: function() {
        if(this.type == "emissionsHourlyProfile") {
          var object = "showEmissionsHourlyProfile";
        } else if(this.type == "emissionsUploadedProfile") {
          var object = "showEmissionsUploadedProfile";
        }

        this.$store.commit('updateObject', { object: object, value: false });
      }
    },

    mounted: function() {
      this.chartOptions.yAxis.min = this.minFactor;
      this.chartOptions.yAxis.max = this.maxFactor;
      this.chartOptions.series[0].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[0].data = this[this.type].co2.series;
      this.chartOptions.series[1].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[1].data = this[this.type].nox.series;
      this.chartOptions.series[2].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[2].data = this[this.type].so2.series;
      this.chartOptions.series[3].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[3].data = this[this.type].pm25.series;
    }
  }
</script>
