<template>
  <div>
    <horizontal-string-input-nested-2 association_1='site' association_2='load_profile'
      attribute='campus_annual_kwh'
      v-on:input-change="getElecSimCampusLoad()"
      required='true'>
    </horizontal-string-input-nested-2>
    <div class="row">
      <div class="col-sm-offset-3 col-sm-7">
        <table class="simulated-campus">
          <thead>
            <tr>
              <th>Select Building Type <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'campus_select_building'"></tooltip></th>
              <th>% of Total Energy Consumption <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'percent_of_energy_consump'"></tooltip></th>
            </tr>
          </thead>
          <tbody class="fields">
            <tr v-for="(building, i) in run.site.load_profile.campus_buildings" >
              <td class="campus-building-type">
                <select v-model="building.doe_reference_name" @change="getElecSimCampusLoad()" label="false" class="select optional" :name="`run[site_attributes][load_profile_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" >
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
              </td>
              <td>
                <input v-model="building.percent_of_total" @change="getElecSimCampusLoad()" label="false" class="string optional building-percent" type="text" :name="`run[site_attributes][load_profile_attributes][campus_buildings_attributes][${i}][percent_of_total]`" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-offset-3">
        <div class="campus-table-actions">
          <button type="button" @click="addBuilding()" class="btn btn-primary">Add Building</button>
          <button type="button" @click="removeBuilding()" class="remove-campus-building btn btn-primary">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import HorizontalStringInputNested2 from './HorizontalStringInputNested2';

  export default {
    components: {
      HorizontalStringInputNested2,
      Tooltip
    },

    computed: {
      ...mapGetters([
        'elecSimCampusLoadProfile',
        'run',
        'selectOptions',
        'urlRoot'
      ]),
      options: function() {
        return this.selectOptions.site.load_profile.doe_reference_name.options;
      },
      watchDependencies:  function() {
        return this.run.site.latitude, 
        this.run.site.longitude,
        new Date();
      },
    },

    methods: {
      addBuilding: function() {
        if(this.run.site.load_profile.campus_buildings.length == 5) {
          bootbox.alert("Can only have a maximum of 5 building types.");
          return false;
        }

        var campusBuildings = [
          ...this.run.site.load_profile.campus_buildings,
          {
            doe_reference_name: '',
            percent_of_total: '',
          }
        ]

        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: 'campus_buildings',
          value: campusBuildings,
        }
        this.$store.commit('updateValueNested2', payload);
        this.getElecSimCampusLoad();
      },
      removeBuilding: function() {
        if(this.run.site.load_profile.campus_buildings.length == 1) {
          bootbox.alert("Must have at least 1 building type.");
          return false;
        }

        this.$store.commit('removeCampusBuilding');
        this.getElecSimCampusLoad();
      },
      verifyAllBuildingsSelected: function() {
        var selected = this.run.site.load_profile.campus_buildings.map(function(el, i) {
          if(el.doe_reference_name) {
            return true;
          } else {
            return false;
          }
        })

        if(selected.includes(false)) {
          return false;
        } else {
          return true;
        }
      },
      verifyPercentsSumTo100: function() {
        var sum = this.run.site.load_profile.campus_buildings.map(function(el, i) {
          return Number(el.percent_of_total);
        }).reduce(function(total, num) {
          return total + num;
        })

        if(sum == 100) {
          return true;
        } else {
          return false;
        }
      },
      getElecSimCampusLoad: function() {
        if(this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.campus_annual_kwh && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });

          var doeReferenceNames = this.run.site.load_profile.campus_buildings.map(function(el, i) {
            return el.doe_reference_name
          })
          var percentShares = this.run.site.load_profile.campus_buildings.map(function(el, i) {
            return el.percent_of_total/100.0
          })

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            campus_annual_kwh: this.run.site.load_profile.campus_annual_kwh,
            doe_reference_names: doeReferenceNames,
            percent_shares: percentShares,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + '/simulated-campus-load',
            contentType: 'application/json',
            data: params,
            success: function handleResults(data) {
              var payload = {
                loadsKw: data.loads_kw,
                minKw: data.min_kw,
                meanKw: data.mean_kw,
                maxKw: data.max_kw,
              }
              self.$store.commit('updateObject', { object: 'elecSimCampusLoadProfile', value: payload });
              self.$emit('update-cold-load', event);
              self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
            },
            error: function() {
              bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
              self.$store.commit('updateObject', { object: 'elecSimCampusLoadProfile', value: {} });
              self.$emit('update-cold-load', event);
            },
          })
        } else {
          this.$store.commit('updateObject', { object: 'elecSimCampusLoadProfile', value: {} });
        }
      }
    },

    watch: {
      watchDependencies: function() {
        var type = this.run.site.load_profile.typical_load_type;
        if(type == "simulated_campus") {
          this.getElecSimCampusLoad();
        }
      }
    },

    mounted: function() {
      if(this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.campus_annual_kwh && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
        this.getElecSimCampusLoad();
      }
    }
  }
</script>
