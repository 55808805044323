<template>
  <div>
    <h5>
      Capital Cost or System Size Based Incentives
      <tooltip association_1="incentives" attribute="capital_costs"></tooltip>
    </h5>
    <a target="blank" style="font-size: 12px" href="http://www.dsireusa.org/"
      >Database of state incentives for renewables</a
    >
    <table class="table tax-incentives-inputs">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" style="width: 21%">
            Incentive based on percentage of cost (%)
            <tooltip
              association_1="incentives"
              attribute="federal_itc_fraction"
            ></tooltip>
          </th>
          <th scope="col" style="width: 21%">
            Maximum dollar amount for incentive based on percentage of cost ($)
            <tooltip
              association_1="incentives"
              attribute="federal_ibi_max_us_dollars"
            ></tooltip>
          </th>
          <th v-if="association_2 != 'ghp'" scope="col" style="width: 21%">
            Rebate based on system size ($/kW)
            <tooltip
              association_1="incentives"
              attribute="federal_rebate_per_kw"
            ></tooltip>
          </th>
          <th v-else scope="col" style="width: 21%">
            Rebate based on system size ($/ton)
            <tooltip
              association_1="incentives"
              attribute="federal_rebate_per_ton"
            ></tooltip>
          </th>          
          <th scope="col" style="width: 21%">
            Maximum dollar amount for rebate based on system size ($)
            <tooltip
              association_1="incentives"
              attribute="federal_rebate_max_us_dollars"
            ></tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label"> Federal </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="federal_itc_fraction"
            />
          </td>
          <td class="form-horizontal">
            <p class="control-label help-block">Unlimited</p>
          </td>
          <td>
            <incentives-table-string-input-nested-2 v-if="association_2 != 'ghp'"
              :association_1="association_1"
              :association_2="association_2"
              attribute="federal_rebate_per_kw"
            />
            <incentives-table-string-input-nested-2 v-else
              :association_1="association_1"
              :association_2="association_2"
              attribute="federal_rebate_per_ton"
            />
          </td>
          <td class="form-horizontal">
            <p class="control-label help-block">Unlimited</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label"> State </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="state_ibi_fraction"
            />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="state_ibi_max"
            />
          </td>
          <td>
            <incentives-table-string-input-nested-2 v-if="association_2 != 'ghp'"
              :association_1="association_1"
              :association_2="association_2"
              attribute="state_rebate_per_kw"
            />
            <incentives-table-string-input-nested-2 v-else
              :association_1="association_1"
              :association_2="association_2"
              attribute="state_rebate_per_ton"
            />            
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="state_rebate_max"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label"> Utility </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="utility_ibi_fraction"
            />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="utility_ibi_max"
            />
          </td>
          <td>
            <incentives-table-string-input-nested-2 v-if="association_2 != 'ghp'"
              :association_1="association_1"
              :association_2="association_2"
              attribute="utility_rebate_per_kw"
            />
            <incentives-table-string-input-nested-2 v-else
              :association_1="association_1"
              :association_2="association_2"
              attribute="utility_rebate_per_ton"
            />            
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="association_1"
              :association_2="association_2"
              attribute="utility_rebate_max"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="association_2 != 'ghp'">
      <h5>
        Production Based Incentives
        <a
          href="#"
          rel="tooltip"
          title="Federal, State, and Utility production incentives can be entered here. If there is more than one production based incentive offered, the combined value can be entered and should be discounted back to year one if the incentive duration differs. For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank' rel='noopener noreferrer'>help manual</a>."
          ><i class="fa fa-question-circle" aria-hidden="true"></i
        ></a>
      </h5>
      <table class="table tax-incentives-inputs">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" style="width: 21%">
              Production incentive ($/kWh)
              <a
                href="#"
                rel="tooltip"
                title="The dollar value of the incentive per kWh produced. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank' rel='noopener noreferrer'>help manual</a>."
                ><i class="fas fa-question-circle" aria-hidden="true"></i
              ></a>
            </th>
            <th scope="col" style="width: 21%">
              Incentive duration (yrs)
              <a
                href="#"
                rel="tooltip"
                title="The number of years the production incentive is awarded. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank' rel='noopener noreferrer'>help manual</a>."
                ><i class="fas fa-question-circle" aria-hidden="true"></i
              ></a>
            </th>
            <th scope="col" style="width: 21%">
              Maximum incentive ($)
              <a
                href="#"
                rel="tooltip"
                title="The maximum incentive amount awarded each year; if there is no limit, the value defaults to ‘Unlimited’. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank' rel='noopener noreferrer'>help manual</a>."
                ><i class="fas fa-question-circle" aria-hidden="true"></i
              ></a>
            </th>
            <th scope="col" style="width: 21%">
              System size limit (kW)
              <a
                href="#"
                rel="tooltip"
                title="The maximum system size eligible for the production based incentive; if there is no limit, the value defaults to 'Unlimited'. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank' rel='noopener noreferrer'>help manual</a>."
                ><i class="fas fa-question-circle" aria-hidden="true"></i
              ></a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" class="form-horizontal">
              <label class="control-label"> Total </label>
            </th>
            <td>
              <incentives-table-string-input-nested-2
                :association_1="association_1"
                :association_2="association_2"
                attribute="production_incentive_per_kwh"
              />
            </td>
            <td>
              <incentives-table-string-input-nested-2
                :association_1="association_1"
                :association_2="association_2"
                attribute="pbi_years"
              />
            </td>
            <td>
              <incentives-table-string-input-nested-2
                :association_1="association_1"
                :association_2="association_2"
                attribute="production_incentive_max_benefit"
              />
            </td>
            <td>
              <incentives-table-string-input-nested-2
                :association_1="association_1"
                :association_2="association_2"
                attribute="pbi_system_max_kw"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Tooltip from "../Tooltip";
import IncentivesTableStringInputNested2 from "./IncentivesTableStringInputNested2";

export default {
  components: {
    Tooltip,
    IncentivesTableStringInputNested2,
  },

  props: ["association_1", "association_2"],
};
</script>
