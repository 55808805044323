<template>
  <div>
    <table class="table clean-energy-table-inputs">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" style="width:33%;text-align:center;">
            From on-site fuel burn <tooltip association_1='site' association_2='financial' attribute='onsite_fuel_burn'></tooltip>
          </th>
          <th scope="col" style="width:33%;text-align:center;">
            From grid emissions <tooltip association_1='site' association_2='financial' attribute='grid_emissions'></tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label">
              NOx cost ($/t NOx)
            </label>
          </th>
          <td style="vertical-align:middle;" >
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'NOx_onsite_fuelburn_cost_per_tonne'" />
          </td>
          <td class="form-horizontal" style="vertical-align:middle;" >
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'NOx_grid_cost_per_tonne'" />
          </td>
        </tr>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label">
              SO₂ cost ($/t SO₂)
            </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'SO2_onsite_fuelburn_cost_per_tonne'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'SO2_grid_cost_per_tonne'" />
          </td>
        </tr>
        <tr>
          <th scope="row" class="form-horizontal">
            <label class="control-label">
              PM2.5 cost ($/t PM2.5)
            </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'PM25_onsite_fuelburn_cost_per_tonne'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'financial'"
              :attribute="'PM25_grid_cost_per_tonne'" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Tooltip from '../Tooltip';
  import IncentivesTableStringInputNested2 from './IncentivesTableStringInputNested2';

  export default {
    components: {
      Tooltip,
      IncentivesTableStringInputNested2,
    },

    props: [
      'association_1',
      'association_2',
    ]
  }
</script>
