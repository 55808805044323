<template>
  <div class="form-horizontal">
    <p class="required-note"><abbr class="required">*</abbr> Required field</p>

    <h4><abbr class="required">*</abbr> Typical electrical load <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'section_header'"></tooltip></h4>
    <p>How would you like to enter the typical energy load profile?</p>

    <input
      v-model="run.site.load_profile.typical_load_type"
      type="hidden"
      name="run[site_attributes][load_profile_attributes][typical_load_type]" />

    <ul class="nav nav-tabs">
      <li class="active"><a data-toggle="tab" href="#simulated_building" @click="updateTypicalLoadType('simulated_building');"><i class="fas fa-home" aria-hidden="true"></i> Simulate Building</a></li>
      <li><a data-toggle="tab" href="#simulated_campus" @click="updateTypicalLoadType('simulated_campus');"><i class="fas fa-university" aria-hidden="true"></i> Simulate Campus</a></li>
      <li><a data-toggle="tab" href="#uploaded" @click="updateTypicalLoadType('uploaded');"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
    </ul>
    <div class="tab-content">
      <div v-if="run.site.load_profile.typical_load_type == 'simulated_building'" class="tab-pane in active">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='load_profile'
          attribute='doe_reference_name'
          required=true
          v-on:input-change="updateAnnualKwh();getElecSimBuildingMonthlyLoad();updateYear();" />
        <div class="col-sm-offset-6 col-sm-6 simulated-load-options">
          <input
            v-model="run.site.load_profile.simulated_load_type"
            style="width:15px;"
            type="radio"
            value="annual"
            checked="checked"
            name="run[site_attributes][load_profile_attributes][simulated_load_type]"
            id="run_site_attributes_load_profile_attributes_simulated_load_type_annual"
            @click="updateLoadSlider()" />
          <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_simulated_load_type_annual">Annual</label>
          <input
            v-model="run.site.load_profile.simulated_load_type"
            class="simulated-monthly"
            style="width:15px;"
            type="radio"
            value="monthly"
            name="run[site_attributes][load_profile_attributes][simulated_load_type]"
            id="run_site_attributes_load_profile_attributes_simulated_load_type_monthly"
            @click="updateLoadSlider()" />
          <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_simulated_load_type_monthly">Monthly</label>
        </div>
        <horizontal-string-input-nested-2
          v-if="run.site.load_profile.simulated_load_type == 'annual'"
          association_1='site'
          association_2='load_profile'
          attribute='annual_kwh'
          ref='annualKwh'
          v-on:input-change="updateAnnualKwh()" />
        <monthly-inputs
          v-else="run.site.load_profile.simulated_load_type == 'monthly'"
          type="electric"
          v-on:input-change="getElecSimBuildingMonthlyLoad()"
          v-on:paste-inputs="pasteInputs('electric')" />
        <div class="form-group string optional run_site_load_profile_annual_kwh" style="margin-top: -15px; margin-bottom: 0;">
          <div class="col-sm-2 default-value-block" style="float: right;">
            <div v-if="run.site.load_profile.load_reduction !== '100.00' && userEnteredAnnualKwhValue" class="default-value" :data-default-value="userEnteredAnnualKwhValue" :data-value-display="userEnteredAnnualKwhValue">
              User value = {{ this.$store.getters.formatAsIntegerWithCommas({value: this.userEnteredAnnualKwhValue, decimals: 0}) }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.typical_load_type == 'simulated_campus'" class="tab-pane" id="simulated_campus">
        <simulated-campus-table
          v-on:update-cold-load="getColdSimulatedLoadProfile()" />
      </div>
      <div v-else-if="!currentUser && run.site.load_profile.typical_load_type == 'uploaded'" class="tab-pane" id="uploaded">
        <input
          v-model="run.site.load_profile.loads_kw"
          type="hidden"
          name="run[site_attributes][load_profile_attributes][typical_uploaded_loads_kw]"/>

        <div class="form-group file required run_site_load_profile_typical_load_file">
          <div class="col-sm-6 control-label">
            <label class="file required" for="typical_load_file"><abbr title="Required" class="required">*</abbr> Custom load profile</label><a href="#" rel="tooltip" title="If 'Upload' is selected, users may upload a custom electric load profile for their site. Hourly, 30-minute, or 15-minute load data are accepted. The units must be in kW. See sample load profile for recommended format. For more information, please see the <a href='/tool/reopt-user-manual.pdf' target='_blank' rel='noopener noreferrer'>help manual</a>."><i class="fas fa-question-circle" aria-hidden="true"></i></a>
          </div>
          <div class="col-sm-4">
            <input
              class="form-control hidden"
              type="hidden"
              name="run[site_attributes][load_profile_attributes][typical_load_file]"
              id="run_site_attributes_load_profile_attributes_typical_load_file" />
            <input
              @change="parseCSV($event, 'electric')"
              class="form-control-file file required load-profile-file-upload"
              required="required"
              aria-required="true"
              type="file"
              name="run[site_attributes][load_profile_attributes][typical_load_file]"
              id="typical_load_file" />
            <!-- <p v-if="run.copied && this.run.site.load_profile.typical_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile.typical_load_file_data).metadata.filename }}</p> -->
            <p v-if="run.copied && this.run.site.load_profile.typical_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile.typical_load_file_name }}</p>
            <p class="help-block">
              <a style="font-size:12px;" href="/tool/load_profile_template.csv">Sample custom load profile</a>
            </p>
          </div>
        </div>
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='load_profile'
          v-on:input-change="updateYear();getDefaultOutageDateHour();"
          attribute='year' />
      </div>
      <div v-else-if="currentUser && run.site.load_profile.typical_load_type == 'uploaded'" class="tab-pane" id="uploaded">
        <div class="form-group select required">
          <div class="col-sm-6 control-label">
            <label class="select required" for="run_site_attributes_load_profile_attributes_typical_load_profile_id">
              <abbr title="Required" class="required">*</abbr> Custom load profile
            </label>
            <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'typical_load_file_data'"></tooltip>
          </div>
          <div class="col-sm-4">
            <select
              v-model="run.site.load_profile.typical_load_profile_id"
              @change="updateTypicalLoadProfile()"
              class="form-control select required"
              required="required"
              aria-required="true"
              name="run[site_attributes][load_profile_attributes][typical_load_profile_id]">
              <option v-for="loadProfile in typicalLoadProfiles" :value="loadProfile.id">
                {{ getTypicalLoadProfileName(loadProfile) }}
              </option>
            </select>
            <p class="help-block">
              <a style="font-size:12px;" href="/tool/load_profile_template.csv">Sample custom load profile</a>
            </p>
          </div>
        </div>
        <input v-model="run.site.load_profile.loads_kw" class="form-control hidden" type="hidden" name="run[site_attributes][load_profile_attributes][typical_uploaded_loads_kw]" />
        <div class="row">
          <div class="col-sm-offset-6 col-sm-6">
            <a @click="displayTypicalLoadProfileForm()" class="btn btn-primary">Upload typical load profile</a>
          </div>
        </div>
        <div class="row col-sm-offset-6" style="padding-left:15px;margin-top:15px;">
          <p>Upload and manage <a href="/tool/typical_load_profiles">your typical load profiles</a></p>
        </div>
      </div>
    </div>

    <div v-if="run.site.load_profile.typical_load_type == 'simulated_building' && run.site.load_profile.simulated_load_type == 'annual'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!elecLoadProfileLoading" @click="downloadLoadProfile('elecSimBuildAnnualLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download electric load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecSimBuildingAnnualLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart electric load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.typical_load_type == 'simulated_building' && run.site.load_profile.simulated_load_type == 'monthly'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!elecLoadProfileLoading" @click="downloadLoadProfile('elecSimBuildMonthlyLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download electric load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecSimBuildingMonthlyLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart electric load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.typical_load_type == 'simulated_campus'" >
      <div class="col-sm-6">
        <div>
          <a v-if="!elecLoadProfileLoading" @click="downloadLoadProfile('elecSimCampusLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download campus electric load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecSimCampusingLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart campus electric load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.typical_load_type == 'uploaded'" class="row">
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecUploadedLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart uploaded load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>

    <transition name="fade">
      <load-profile-modal v-if="showElecSimBuildAnnualLoadProfile"
        :loadType="'elecSimBuildAnnualLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <load-profile-modal v-if="showElecSimBuildMonthlyLoadProfile"
        :loadType="'elecSimBuildMonthlyLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <load-profile-modal v-if="showElecUploadedLoadProfile"
        :loadType="'elecUploadedLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <load-profile-modal v-if="showElecSimCampusLoadProfile"
        :loadType="'elecSimCampusLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <typical-load-profile-form v-if="showTypicalLoadProfileForm"
        v-on:update-selected-typ-load-profile="updateSelectedTypLoadProfile">
      </typical-load-profile-form>
    </transition>

    <br>
    <div v-if="!run.grid">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='load_profile'
        attribute='min_load_met_annual_fraction' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='load_profile'
        attribute='operating_reserve_required_fraction' />
    </div>

    <br>
    <h4>Electrical load adjustment</h4>
    <div class="form-group">
      <div class="col-sm-6 control-label" style="margin-top:26px;">
        <strong>Adjust electricity consumption</strong><tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'adjust_elec_consump'"></tooltip>
      </div>
      <div class="col-sm-4" style="margin-top:36px;">
        <veeno
          v-model="run.site.load_profile.load_reduction"
          id="load-reduction-slider"
          pipsy
          :step="1"
          :handles="parseInt(run.site.load_profile.load_reduction)"
          :range = "{ min: 0, max: 200 }" >
          {{ Number(run.site.load_profile.load_reduction).toFixed(1) }}% of original consumption
        </veeno>
        <input
          v-model="run.site.load_profile.load_reduction"
          class="form-control hidden load-reduction"
          type="hidden"
          name="run[site_attributes][load_profile_attributes][load_reduction]" />
      </div>
    </div>
    <br><br><br>

    <div v-if="run.analyze_chp || run.analyze_ghp">
      <h4><abbr class="required">*</abbr> Typical heating system fuel load <tooltip :association_1="'site'" :association_2="'load_profile'" :association_3="'load_profile_boiler_fuel'" :attribute="'section_header'"></tooltip></h4>
      <p>How would you like to enter the typical thermal load profile?</p>

      <input v-model="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type" type="hidden" name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][thermal_load_type]" />

      <ul class="nav nav-tabs">
        <li class="active"><a @click="updateThermalLoadType('simulated');" data-toggle="tab" href="#thermal_load_profile_simulated_inputs"><i class="fas fa-home" aria-hidden="true"></i> Simulate Building</a></li>
        <li><a @click="updateThermalLoadType('simulated_campus');" data-toggle="tab" href="#thermal_simulated_campus"><i class="fas fa-university" aria-hidden="true"></i> Simulate Campus</a></li>
        <li><a @click="updateThermalLoadType('uploaded');" data-toggle="tab" href="#thermal_load_profile_custom_inputs"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
      </ul>
      <div class="tab-content">
        <div v-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated'" class="tab-pane fade in active">
          <horizontal-select-input-nested-3
            association_1='site'
            association_2='load_profile'
            association_3='load_profile_boiler_fuel'
            attribute='doe_reference_name'
            style="margin-bottom:-25px"
            required=true
            v-on:input-change="updateAnnualMMBtu();getThermalSimBuildingMonthlyLoad();updateGHPDefaults();" />
          <div class="col-sm-offset-6 col-sm-6 simulated-load-options" style="margin-bottom:10px">
            <input
              v-model="run.site.load_profile.load_profile_boiler_fuel.simulated_load_type"
              style="width:15px;"
              type="radio"
              value="annual"
              checked="checked"
              name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_simulated_load_type_annual" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_simulated_load_type_annual">Annual</label>
            <input
              v-model="run.site.load_profile.load_profile_boiler_fuel.simulated_load_type"
              class="simulated-monthly"
              style="width:15px;"
              type="radio"
              value="monthly"
              name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_simulated_load_type_monthly" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_simulated_load_type_monthly">Monthly</label>
          </div>
          <horizontal-string-input-nested-3
            v-if="run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'annual'"
            association_1='site'
            association_2='load_profile'
            association_3='load_profile_boiler_fuel'
            ref='annualMMBtu'
            attribute='annual_mmbtu'
            v-on:input-change="updateAnnualMMBtu();" />
          <input
            v-model="run.site.load_profile.load_profile_boiler_fuel.space_annual_mmbtu"
            type="hidden"
            name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][space_annual_mmbtu]"
            id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_space_annual_mmbtu" />
          <input
            v-model="run.site.load_profile.load_profile_boiler_fuel.dhw_annual_mmbtu"
            type="hidden"
            name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][dhw_annual_mmbtu]"
            id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_dhw_annual_mmbtu" />
          <horizontal-string-input-nested-3
            v-if="run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'annual'"
            association_1='site'
            association_2='load_profile'
            association_3='load_profile_boiler_fuel'
            attribute='addressable_load_fraction'
            v-on:input-change="getThermalSimBuildingAnnualLoad()" />
          <thermal-monthly-inputs
            v-else="run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'monthly'"
            type='thermal'
            v-on:input-change="getThermalSimBuildingMonthlyLoad()"
            v-on:paste-inputs="pasteInputs('thermal')"
            v-on:paste-percent-inputs="pasteInputs('thermal-percent')" />
        </div>
        <div v-else-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated_campus'" class="tab-pane" id="thermal_simulated_campus">
          <thermal-simulated-campus-table />
        </div>
        <div v-else-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'uploaded'" class="tab-pane" id="thermal_load_profile_custom_inputs">
          <input
            v-model="run.site.load_profile.load_profile_boiler_fuel.loads_mmbtu_per_hour"
            type="hidden"
            name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][loads_mmbtu_per_hour]" />

          <div class="form-group file required">
            <div class="col-sm-6 control-label">
              <label class="file required" for="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_thermal_load_file"><abbr title="Required" class="required">*</abbr> Custom heating system fuel load profile</label><tooltip :association_1="'site'" :association_2="'load_profile'" :association_3="'load_profile_boiler_fuel'" :attribute="'thermal_load_file'"></tooltip>
            </div>
            <div class="col-sm-4">
              <input
                type="hidden"
                name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][thermal_load_file]"
                id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_thermal_load_file"/>
              <input
                @change="parseCSV($event, 'thermal')"
                class="form-control-file file required"
                required="required"
                aria-required="true"
                type="file"
                name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][thermal_load_file]" />
              <!-- <p v-if="run.copied && this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_file_name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated' && run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'annual'" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!thermalLoadProfileLoading" @click="downloadLoadProfile('thermalSimBuildAnnualLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download heating system fuel load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!thermalLoadProfileLoading" @click="displayThermalSimBuildingAnnualLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated' && run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == 'monthly'" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!thermalLoadProfileLoading" @click="downloadLoadProfile('thermalSimBuildMonthlyLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download heating system fuel load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!thermalLoadProfileLoading" @click="displayThermalSimBuildingMonthlyLP();" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'simulated_campus'" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!thermalLoadProfileLoading" @click="downloadLoadProfile('thermalSimCampusLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download heating system fuel load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!thermalLoadProfileLoading" @click="displayThermalSimCampusLP();" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == 'uploaded'" class="row">
        <div class="col-sm-6 col-sm-offset-6 text-right">
          <a v-if="!thermalLoadProfileLoading" @click="displayThermalUploadedLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>

      <transition name="fade">
        <thermal-load-profile-modal
          v-if="showThermalSimBuildAnnualLoadProfile"
          :loadType="'thermalSimBuildAnnualLoadProfile'">
        </thermal-load-profile-modal>
      </transition>
      <transition name="fade">
        <thermal-load-profile-modal
          v-if="showThermalSimBuildMonthlyLoadProfile"
          :loadType="'thermalSimBuildMonthlyLoadProfile'">
        </thermal-load-profile-modal>
      </transition>
      <transition name="fade">
        <thermal-load-profile-modal
          v-if="showThermalSimCampusLoadProfile"
          :loadType="'thermalSimCampusLoadProfile'">
        </thermal-load-profile-modal>
      </transition>
      <transition name="fade">
        <thermal-load-profile-modal
          v-if="showThermalUploadedLoadProfile"
          :loadType="'thermalUploadedLoadProfile'">
        </thermal-load-profile-modal>
      </transition>
    </div>

    <div v-if="displayColdLP">
      <br />
      <h4><abbr class="required">*</abbr> Typical cooling plant load <tooltip :association_1="'site'" :association_2="'load_profile'" :association_3="'load_profile_chiller_thermal'" :attribute="'section_header'"></tooltip></h4>
      <p>How would you like to enter the typical chiller thermal load profile?</p>

      <input
        v-model="run.site.load_profile.load_profile_chiller_thermal.cold_load_type"
        type="hidden"
        name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][cold_load_type]" />

      <ul class="nav nav-tabs">
        <li class="active"><a @click="updateColdLoadType('simulated');" data-toggle="tab" href="#cold_load_profile_simulated_inputs"><i class="fas fa-home" aria-hidden="true"></i> Simulate</a></li>
        <li><a @click="updateColdLoadType('simulated_campus');" data-toggle="tab" href="#cold_simulated_campus"><i class="fas fa-university" aria-hidden="true"></i> Simulate Campus</a></li>
        <li><a @click="updateColdLoadType('uploaded');" data-toggle="tab" href="#cold_load_profile_uploaded" class="technology-btns"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
        <li><a @click="updateColdLoadType('custom');" data-toggle="tab" href="#cold_load_profile_custom" class="technology-btns"><i class="fas fa-wrench" aria-hidden="true"></i> Custom</a></li>
      </ul>
      <div class="tab-content">
        <div v-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated'" class="tab-pane fade in active">
          <horizontal-select-input-nested-3
            association_1='site'
            association_2='load_profile'
            association_3='load_profile_chiller_thermal'
            attribute='doe_reference_name'
            required=true
            v-on:input-change="getColdSimulatedLoadProfile();" />
          <div class="row">
            <div class="col-sm-offset-6" style="padding-left:15px;">
              <input v-model="run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump" type="checkbox" value="1" id="custom_energy_consumption" name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][custom_energy_consump]" />
              <label style="font-weight:normal" for="custom_energy_consumption">
                &nbsp;Enter custom annual or monthly thermal energy amounts <tooltip :association_1="'site'" :association_2="'load_profile'" :association_3="'load_profile_chiller_thermal'" :attribute="'custom_energy_consump'"></tooltip>
              </label>
            </div>
          </div>
          <div v-if="run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump" >
            <div class="col-sm-offset-6 col-sm-6 simulated-load-options">
              <input
                v-model="run.site.load_profile.load_profile_chiller_thermal.simulated_load_type"
                style="width:15px;"
                type="radio"
                value="annual"
                checked="checked"
                name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][simulated_load_type]"
                id="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_simulated_load_type_annual" />
              <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_simulated_load_type_annual">Annual</label>
              <input
                v-model="run.site.load_profile.load_profile_chiller_thermal.simulated_load_type"
                class="simulated-monthly"
                style="width:15px;"
                type="radio"
                value="monthly"
                name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][simulated_load_type]"
                id="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_simulated_load_type_monthly" />
              <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_simulated_load_type_monthly">Monthly</label>
            </div>
            <horizontal-string-input-nested-3
              v-if="run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == 'annual'" association_1='site' association_2='load_profile' association_3='load_profile_chiller_thermal'
              ref='annualTonHour'
              attribute='annual_tonhour'
              v-on:input-change="getColdSimBuildingAnnualLoad()" />
            <monthly-inputs
              v-else="run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == 'monthly'"
              type='cold'
              v-on:input-change="getColdSimBuildingMonthlyLoad()"
              v-on:paste-inputs="pasteInputs('cold')" />
          </div>
        </div>

        <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated_campus'" class="tab-pane" id="cold_simulated_campus">
          <cold-simulated-campus-table
            v-on:update-cold-load="getColdSimulatedLoadProfile()" />
        </div>

        <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'uploaded'" class="tab-pane" id="cold_load_profile_uploaded">
          <input v-model="run.site.load_profile.load_profile_chiller_thermal.cold_uploaded_loads_ton" type="hidden" name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][cold_uploaded_loads_ton]"/>

          <div class="form-group file required">
            <div class="col-sm-6 control-label">
              <label class="file required" for="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_cold_load_file"><abbr title="Required" class="required">*</abbr> Thermal energy consumption load profile</label><tooltip :association_1="'site'" :association_2="'load_profile'" :association_3="'load_profile_chiller_thermal'" :attribute="'cold_load_file'"></tooltip>
            </div>
            <div class="col-sm-4">
              <input
                type="hidden"
                name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][cold_load_file]" />
              <input
                @change="parseCSV($event, 'cold')"
                class="form-control-file file required"
                required="required"
                aria-required="true"
                type="file"
                name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][cold_load_file]" />
              <!-- <p v-if="run.copied && this.run.site.load_profile.load_profile_chiller_thermal.cold_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.load_profile.load_profile_chiller_thermal.cold_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile.load_profile_chiller_thermal.cold_load_file_name }}</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'custom'"
          class="tab-pane"
          id="cold_load_profile_custom">
          <div class="col-sm-offset-6 col-sm-6 simulated-load-options" style="margin-bottom:10px;">
            <input
              v-model="run.site.load_profile.load_profile_chiller_thermal.custom_load_type"
              style="width:15px;"
              type="radio"
              value="annual"
              checked="checked"
              name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][custom_load_type]"
              id="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_custom_load_type_annual" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_custom_load_type_annual">Annual</label>
            <input
              v-model="run.site.load_profile.load_profile_chiller_thermal.custom_load_type"
              class="simulated-monthly"
              style="width:15px;"
              type="radio"
              value="monthly"
              name="run[site_attributes][load_profile_attributes][load_profile_chiller_thermal_attributes][custom_load_type]"
              id="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_custom_load_type_monthly" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_custom_load_type_monthly">Monthly</label>
          </div>
          <horizontal-string-input-nested-3
            v-if="run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'annual'"
            association_1='site'
            association_2='load_profile'
            association_3='load_profile_chiller_thermal'
            attribute='annual_fraction_of_electric_load'
            required='true'
            v-on:input-change="getColdCustomAnnualPctLoad()" />
          <monthly-inputs
            v-else="run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'monthly'"
            type='cold-percent'
            v-on:input-change="getColdCustomMonthlyPctLoad()"
            v-on:paste-inputs="pasteInputs('cold-percent')" />
        </div>
      </div>
      <div v-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated' && (!run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump || (run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump && run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == 'annual' && !run.site.load_profile.load_profile_chiller_thermal.annual_tonhour))" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimBuildLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdSimBuildingLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated' && run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump && run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == 'annual' && run.site.load_profile.load_profile_chiller_thermal.annual_tonhour" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimBuildAnnualLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdSimBuildingAnnualLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated' && run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump && run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == 'monthly'" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimBuildMonthlyLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdSimBuildingMonthlyLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated_campus' && !run.site.load_profile.load_profile_chiller_thermal.custom_annual_simulated_campus" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimCampusFromElecLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdSimCampusFromElecLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'simulated_campus' && run.site.load_profile.load_profile_chiller_thermal.custom_annual_simulated_campus" class="row">
        <div class="col-sm-6">
          <div>
            <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimCampusLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
            </a>
            <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdSimCampusLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'uploaded'" class="row">
        <div class="col-sm-6 col-sm-offset-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdUploadedLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'custom' && run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'annual'" class="row">
        <div class="col-sm-6 col-sm-offset-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdCustomAnnualPctLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.load_profile_chiller_thermal.cold_load_type == 'custom' && run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'monthly'" class="row">
        <div class="col-sm-6 col-sm-offset-6 text-right">
          <a v-if="!coldLoadProfileLoading" @click="displayColdCustomMonthlyPctLP()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>

      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdSimBuildLoadProfile"
          :loadType="'coldSimBuildLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdSimBuildAnnualLoadProfile"
          :loadType="'coldSimBuildAnnualLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdSimBuildMonthlyLoadProfile"
          :loadType="'coldSimBuildMonthlyLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdSimCampusFromElecLoadProfile"
          :loadType="'coldSimCampusFromElecLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdSimCampusLoadProfile"
          :loadType="'coldSimCampusLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdUploadedLoadProfile"
          :loadType="'coldUploadedLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdCustomAnnualPctLoadProfile"
          :loadType="'coldCustomAnnualPctLoadProfile'">
        </cold-load-profile-modal>
      </transition>
      <transition name="fade">
        <cold-load-profile-modal
          v-if="showColdCustomMonthlyPctLoadProfile"
          :loadType="'coldCustomMonthlyPctLoadProfile'">
        </cold-load-profile-modal>
      </transition>

      <horizontal-string-input-nested-2
        association_1='site'
        association_2='electric_chiller'
        attribute='cop'
        v-on:input-change="getColdCustomAnnualPctLoad()" />
      <horizontal-string-input-nested-2
        v-if="!run.analyze_ghp"
        association_1='site'
        association_2='electric_chiller'
        attribute='max_thermal_factor_on_peak_load' />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import HorizontalSelectInputNested3 from './inputs/HorizontalSelectInputNested3';
  import LoadProfileModal from './modals/LoadProfileModal';
  import ThermalLoadProfileModal from './modals/ThermalLoadProfileModal';
  import ColdLoadProfileModal from './modals/ColdLoadProfileModal';
  import SimulatedCampusTable from './inputs/SimulatedCampusTable';
  import ThermalSimulatedCampusTable from './inputs/ThermalSimulatedCampusTable';
  import ColdSimulatedCampusTable from './inputs/ColdSimulatedCampusTable';
  import Tooltip from './Tooltip';
  import MonthlyInputs from './inputs/MonthlyInputs';
  import ThermalAnnualInputs from './inputs/ThermalAnnualInputs';
  import ThermalMonthlyInputs from './inputs/ThermalMonthlyInputs';
  import TypicalLoadProfileForm from './../typical_load_profile/TypicalLoadProfileForm';
  import veeno from 'veeno';
  import 'nouislider/distribute/nouislider.min.css';

  export default {
    components: {
      HorizontalStringInputNested2,
      HorizontalStringInputNested3,
      HorizontalSelectInputNested2,
      HorizontalSelectInputNested3,
      LoadProfileModal,
      ThermalLoadProfileModal,
      ColdLoadProfileModal,
      SimulatedCampusTable,
      ThermalSimulatedCampusTable,
      ColdSimulatedCampusTable,
      Tooltip,
      MonthlyInputs,
      ThermalAnnualInputs,
      ThermalMonthlyInputs,
      TypicalLoadProfileForm,
      veeno
    },

    data: function() {
      return {
        unadjustedUploadedLP: [],
        tonhourToKwht: 3.51685,
      }
    },

    computed: {
      ...mapGetters([
        'currentUser',
        'typicalLoadProfiles',
        'placeholders',
        'userEnteredAnnualKwhValue',
        'userEnteredMonthlyKwhValues',
        'run',
        'urlRoot',
        'year',

        'elecLoadProfile',
        'elecLoadProfileLoading',
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',

        'showElecSimBuildAnnualLoadProfile',
        'showElecSimBuildMonthlyLoadProfile',
        'showElecSimCampusLoadProfile',
        'showElecUploadedLoadProfile',

        'thermalLoadProfileLoading',
        'thermalSimBuildAnnualLoadProfile',
        'thermalSimBuildMonthlyLoadProfile',
        'thermalSimCampusLoadProfile',
        'thermalUploadedLoadProfile',

        'showThermalSimBuildAnnualLoadProfile',
        'showThermalSimBuildMonthlyLoadProfile',
        'showThermalSimCampusLoadProfile',
        'showThermalUploadedLoadProfile',

        'coldLoadProfile',
        'coldLoadProfileLoading',
        // the coldSimBuildLoadProfile refers to when !run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump
        'coldSimBuildLoadProfile',
        'coldSimBuildAnnualLoadProfile',
        'coldSimBuildMonthlyLoadProfile',
        'coldSimCampusFromElecLoadProfile',
        'coldSimCampusLoadProfile',
        'coldUploadedLoadProfile',
        'coldCustomAnnualPctLoadProfile',
        'coldCustomMonthlyPctLoadProfile',
        'ratioHeatingToCooling',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'dhwAnnualMMbtuForRatio',

        'showColdSimBuildLoadProfile',
        'showColdSimBuildAnnualLoadProfile',
        'showColdSimBuildMonthlyLoadProfile',
        'showColdSimCampusFromElecLoadProfile',
        'showColdSimCampusLoadProfile',
        'showColdUploadedLoadProfile',
        'showColdCustomAnnualPctLoadProfile',
        'showColdCustomMonthlyPctLoadProfile',

        'showTypicalLoadProfileForm',
      ]),
      displayColdLP: function() {
        return this.run.analyze_cws || (this.run.site.chp && this.run.site.chp.analyze_abs_chiller) || this.run.analyze_ghp;
      },
      watchDependencies:  function() {
        return this.run.analyze_chp,
        this.run.analyze_prime_generator,
        this.run.analyze_ghp,
        this.run.analyze_cws,
        this.run.site.latitude, 
        this.run.site.longitude,
        this.run.site.load_profile.simulated_load_type, 
        this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type, 
        this.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type, 
        this.run.site.load_profile.typical_load_type,
        this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type, 
        this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type, 
        this.run.site.load_profile.load_profile_chiller_thermal.custom_load_type,
        new Date();
      },
    },

    methods: {
      updateAnnualKwh: function() {
        if(this.run.site.load_profile.simulated_load_type != 'annual') {
          return false;
        }

        if(this.run.site.address && this.run.site.load_profile.doe_reference_name) {
          var self = this;
          $.ajax({
            url: self.urlRoot + '/annual-kwh',
            contentType: 'application/json',
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              doe_reference_name: this.run.site.load_profile.doe_reference_name,
            },
            success: function handleResults(data) {
              if(!self.run.site.load_profile.annual_kwh) {
                self.$refs.annualKwh.toggleDisplayDefault();
              }

              self.$store.commit('updatePlaceholder', {
                association_1: 'site',
                association_2: 'load_profile',
                attribute: 'annual_kwh',
                value: self.$store.getters.formatAsIntegerWithCommas({ value: data.toFixed(0) })
              });

              // this logic is for copied runs to display the correct user adjusted annual_kwh value or the default adjusted annual_kwh value.
              // It does produce some weird results however if the load slider is changed from 100% and then the user tries to input a value.
              // I think the only way to work around that bug would be to create a rails db variable for the user input value, then the code below
              // might not even be needed at all. Can instead just check for a user input value and update that wherever you see userEnteredAnnualKwhValue?
              if (parseInt(self.placeholders.site.load_profile.annual_kwh.split(',').join('')) !== parseInt((self.run.site.load_profile.annual_kwh / (self.run.site.load_profile.load_reduction / 100.0)).toFixed(0))) {
                var userValue = (self.run.site.load_profile.annual_kwh / (self.run.site.load_profile.load_reduction / 100.0)).toString();
                if (userValue === '0') {
                  userValue = ''
                }
                self.$store.commit('updateObject', { object: 'userEnteredAnnualKwhValue', value: userValue });
                // self.$store.commit('updateObject', { object: 'userEnteredAnnualKwhValue', value: self.run.site.load_profile.annual_kwh.toString() });
              }

              //Add conditional so simulated_load endpoint is not called twice unnecessarily
              self.getElecSimBuildingAnnualLoad();

              if (self.run.site.load_profile.load_reduction != 100) {
                self.updateAnnualKwhValueFromSlider();
              }
            },
            error: function() {
              return false;
            },
          })
        }
      },
      updateAnnualMMBtu: function() {
        if(this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type != 'annual') {
          return false;
        }

        if(this.run.site.address && this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name) {
          var self = this;
          $.ajax({
            url: self.urlRoot + '/annual-mmbtu',
            contentType: 'application/json',
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              doe_reference_name: this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name,
            },
            success: function handleResults(data) {
              if(!self.run.site.load_profile.load_profile_boiler_fuel.annual_mmbtu) {
                self.$refs.annualMMBtu.toggleDisplayDefault();
                self.updateStoreNested3({
                  association_3: 'load_profile_boiler_fuel',
                  attribute: 'space_annual_mmbtu',
                  value: Math.round(data.space_annual_mmbtu)
                });
                self.updateStoreNested3({
                  association_3: 'load_profile_boiler_fuel',
                  attribute: 'dhw_annual_mmbtu',
                  value: Math.round(data.dhw_annual_mmbtu)
                });
              }

              self.$store.commit('updatePlaceholder', {
                association_1: 'site',
                association_2: 'load_profile',
                association_3: 'load_profile_boiler_fuel',
                attribute: 'annual_mmbtu',
                value: Math.round(data.annual_mmbtu)
              });

              //Add conditional so simulated_load endpoint is not called twice unnecessarily          
              self.getThermalSimBuildingAnnualLoad();
            },
            error: function() {
              return false;
            },
          })
        }
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateStoreNested3: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          association_3: payload.association_3,
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested3', payload);
      },
      displayElecSimBuildingAnnualLP: function() {
        // display electric simulated building load profile
        if(!this.run.site.load_profile.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('A load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecSimBuildAnnualLoadProfile', value: true });
      },
      displayThermalSimBuildingAnnualLP: function() {
        // display thermal simulated building annual load profile
        if(!this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('A heating system fuel load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showThermalSimBuildAnnualLoadProfile', value: true });
      },
      displayThermalSimCampusLP: function() {
        // display thermal simulated campus load profile
        if(Object.keys(this.thermalSimCampusLoadProfile).length == 0) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showThermalSimCampusLoadProfile', value: true });
      },
      displayColdSimCampusFromElecLP: function() {
        // display cold simulated campus load profile
        if(Object.keys(this.coldSimCampusFromElecLoadProfile).length == 0) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimCampusFromElecLoadProfile', value: true });
      },
      displayColdSimCampusLP: function() {
        // display cold simulated campus load profile
        if(Object.keys(this.coldSimCampusLoadProfile).length == 0) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimCampusLoadProfile', value: true });
      },
      displayColdSimBuildingAnnualLP: function() {
        // display cold simulated building annual load profile
        if(!this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimBuildAnnualLoadProfile', value: true });
      },
      displayColdSimBuildingLP: function() {
        // display cold building annual load profile (no custom energy consumption)
        var elecLoadProfile = this.$store.getters.elecLoadProfile;
        if(!this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude || Object.keys(this[elecLoadProfile]).length == 0) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first. If no custom thermal energy amounts are entered, please verify a typical electrical load is present.');
          return false;
        }
        if (this.run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump && this.run.site.load_profile.load_profile_chiller_thermal.custom_load_type == 'annual' && !this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour) {
          this.$store.commit('updateObject', { object: 'showColdSimBuildAnnualLoadProfile', value: true });
        } else {
          this.$store.commit('updateObject', { object: 'showColdSimBuildLoadProfile', value: true });
        }
      },
      allMonthlyInputsPresent: function(type) {
        var values = [];
        for(var i = 1; i <= 12; i++) {
          if(type == "thermal") {
            values.push(this.run.site.load_profile.load_profile_boiler_fuel[`thermal_mmbtu_monthly_${i}`]);
          } else if(type == "cold") {
            values.push(this.run.site.load_profile.load_profile_chiller_thermal[`monthly_tonhour_${i}`]);
          } else if(type == "electric") {
            values.push(this.run.site.load_profile[`energy_use_monthly_${i}`]);
          } else if(type == "cold-percent") {
            values.push(this.run.site.load_profile.load_profile_chiller_thermal[`lp_fraction_monthly_${i}`]);
          }
        }

        if(values.includes("") || values.includes(null) || values.includes(undefined)) {
          return false;
        } else {
          return true;
        }
      },
      displayElecSimBuildingMonthlyLP: function() {
        // display electric simulated building monthly load profile
        if(!this.allMonthlyInputsPresent('electric') || !this.run.site.load_profile.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('Please verify that all monthly values for energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecSimBuildMonthlyLoadProfile', value: true });
      },
      displayThermalSimBuildingMonthlyLP: function() {
        // display thermal simulated building monthly load profile
        if(!this.allMonthlyInputsPresent('thermal') || !this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('Please verify that all monthly values for thermal energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showThermalSimBuildMonthlyLoadProfile', value: true });
      },
      displayColdSimBuildingMonthlyLP: function() {
        // display cold simulated building monthly load profile
        if(!this.allMonthlyInputsPresent('cold') || !this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('Please verify that all monthly values for cooling plant thermal energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimBuildMonthlyLoadProfile', value: true });
      },
      displayElecSimCampusingLP: function() {
        // display electric simulated campus load profile
        if(!this.elecSimCampusLoadProfile.loadsKw) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecSimCampusLoadProfile', value: true });
      },
      displayElecUploadedLP: function() {
        // display electric uploaded load profile
        if(!this.elecUploadedLoadProfile.loadsKw) {
          bootbox.alert('A load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecUploadedLoadProfile', value: true });
      },
      displayThermalUploadedLP: function() {
        // display electric uploaded load profile
        if(!this.thermalUploadedLoadProfile.loadsMMBtu) {
          bootbox.alert('A thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showThermalUploadedLoadProfile', value: true });
      },
      displayColdUploadedLP: function() {
        // display electric uploaded load profile
        if(!this.coldUploadedLoadProfile.loadsTonHour) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdUploadedLoadProfile', value: true });
      },
      displayColdCustomAnnualPctLP: function() {
        // display cold custom annual percent load profile
        if(!this.coldCustomAnnualPctLoadProfile.loadsTonHour) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdCustomAnnualPctLoadProfile', value: true });
      },
      displayColdCustomMonthlyPctLP: function() {
        // display cold custom annual percent load profile
        if(!this.coldCustomMonthlyPctLoadProfile.loadsTonHour) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdCustomMonthlyPctLoadProfile', value: true });
      },
      getElecSimBuildingAnnualLoad: function() {
        this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        var self = this;
        var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile.doe_reference_name,
        }

        // due to the new functionality of the load_reduction slider autopopulating the adjusted annual_kwh value,
        // only send annual_kwh params if load_reduction slider is 100 and there is a user entered value. Otherwise,
        // on copy, if the load_reduction is something other than 100, the min/max/mean/loads_kw values are different
        // than the initial input page values.
        if(this.run.site.load_profile.load_reduction == 100 && this.run.site.load_profile.annual_kwh) {
          params['annual_kwh'] = this.run.site.load_profile.annual_kwh;
        }

        $.ajax({
          url: self.urlRoot + "/simulated-annual-elec-load",
          data: params,
          success: function(data) {
            var payload = {
              loadsKw: data.loads_kw,
              minKw: data.min_kw,
              meanKw: data.mean_kw,
              maxKw: data.max_kw,
            }
            self.$store.commit('updateObject', { object: 'elecSimBuildAnnualLoadProfile', value: payload });
            self.getColdSimulatedLoadProfile();
            self.getColdCustomAnnualPctLoad();
            self.getColdCustomMonthlyPctLoad();
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'elecSimBuildAnnualLoadProfile', value: {} });
            self.getColdSimulatedLoadProfile();
            self.getColdCustomAnnualPctLoad();
            self.getColdCustomMonthlyPctLoad();
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }
        })
      },
      getThermalSimBuildingAnnualLoad: function() {
        this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
        var self = this;
        var thermalPercent = this.run.site.load_profile.load_profile_boiler_fuel.addressable_load_fraction;
        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          doe_reference_name: this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name,
        }
        if(this.run.site.load_profile.load_profile_boiler_fuel.annual_mmbtu) {
          params['annual_mmbtu'] = this.run.site.load_profile.load_profile_boiler_fuel.annual_mmbtu;
        }
        if(thermalPercent) {
          params['addressable_load_fraction'] = (thermalPercent / 100.0);
        }

        $.ajax({
          url: self.urlRoot + "/simulated-annual-thermal-load",
          data: params,
          success: function(data) {
            var payload = {
              loadsMMBtu: data.loads_mmbtu_per_hour,
              minMMBtu: data.min_mmbtu_per_hour,
              meanMMBtu: data.mean_mmbtu_per_hour,
              maxMMBtu: data.max_mmbtu_per_hour,
              dhwLoadsMMBtu: data.dhw_loads_mmbtu_per_hour,
              dhwMinMMBtu: data.dhw_min_mmbtu_per_hour,
              dhwMaxMMBtu: data.dhw_max_mmbtu_per_hour,
              dhwMeanMMBtu: data.dhw_mean_mmbtu_per_hour,
              spaceLoadsMMBtu: data.space_loads_mmbtu_per_hour,
              spaceMinMMBtu: data.space_min_mmbtu_per_hour,
              spaceMaxMMBtu: data.space_max_mmbtu_per_hour,
              spaceMeanMMBtu: data.space_mean_mmbtu_per_hour,
            }
            self.$store.commit('updateObject', { object: 'thermalSimBuildAnnualLoadProfile', value: payload });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });

            self.updateStoreNested3({
              association_3: 'load_profile_boiler_fuel',
              attribute: 'space_annual_mmbtu',
              value: Math.round(data.space_annual_mmbtu)
            });
            self.updateStoreNested3({
              association_3: 'load_profile_boiler_fuel',
              attribute: 'dhw_annual_mmbtu',
              value: Math.round(data.dhw_annual_mmbtu)
            });
            if (self.run.analyze_ghp) {
              var dhwAnnualMMbtuForRatio = data.dhw_annual_mmbtu;
              var annualMmbtuForRatio = data.annual_mmbtu;

              self.$store.commit('updateObject', { object: 'dhwAnnualMMbtuForRatio', value: dhwAnnualMMbtuForRatio });
              self.$store.commit('updateObject', { object: 'annualMmbtuForRatio', value: annualMmbtuForRatio });
            }

            if (self.run.analyze_ghp && self.annualTonHourForRatio) {
              self.updateRatioHeatingToCooling();
            }
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'thermalSimBuildAnnualLoadProfile', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getColdSimBuildingAnnualLoad: function() {
        if (this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });
          var self = this;
          // Adding these conditionals to send additional parameters to the simulated_annual_cold_load method in the runs_controller.
          // This allows the default annual_tonhour value to update if there is a user entered annual_tonhour value.
          if (this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.annual_kwh) {
            var annualKwh = this.run.site.load_profile.annual_kwh;
            var doeReferenceName = this.run.site.load_profile.doe_reference_name;
          } else if (this.run.site.load_profile.typical_load_type == "simulated_building" && !this.run.site.load_profile.annual_kwh) {
            var annualKwh = parseInt(this.$store.state.placeholders.site.load_profile.annual_kwh.split(',').join(''));
            var doeReferenceName = this.run.site.load_profile.doe_reference_name;
          } else if (this.run.site.load_profile.typical_load_type  == "simulated_campus") {
            var annualKwh = this.run.site.load_profile.campus_annual_kwh;
            var doeReferenceNames = this.run.site.load_profile.campus_buildings.map(function(el, i) {
              return el.doe_reference_name
            })
            var percentShares = this.run.site.load_profile.campus_buildings.map(function(el, i) {
              return el.percent_of_total/100.0
            })
          }

          $.ajax({
            url: `${self.urlRoot}/simulated-annual-cold-load`,
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              doe_reference_name: doeReferenceName,
              doe_reference_names: doeReferenceNames,
              percent_shares: percentShares,
              cooling_doe_ref_name: this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name,
              annual_tonhour: this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour,
              annual_kwh: annualKwh,
            },
            success: function(data) {
              var payload = {
                loadsTonHour: data.cold_load_data.loads_ton,
                minTonHour: data.cold_load_data.min_ton,
                meanTonHour: data.cold_load_data.mean_ton,
                maxTonHour: data.cold_load_data.max_ton,
              }
              self.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.cold_load_data.chiller_cop });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_chiller_thermal', attribute: 'annual_tonhour', value: Math.round(data.elec_load_data_for_defaults.cooling_defaults.annual_tonhour) });

              self.updateAbsChillerDefaults();
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: {} });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            }
          })
        } else {
          this.getColdSimulatedLoadProfile();
        }
      },
      getElecSimBuildingMonthlyLoad: function() {
        if(this.run.site.load_profile.simulated_load_type != 'monthly') {
          return false;
        }

        if(this.allMonthlyInputsPresent('electric') && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.doe_reference_name) {
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });

          var monthly_totals_kwh = [];
          for(var i = 1; i <= 12; i++) {
            monthly_totals_kwh.push(this.run.site.load_profile[`energy_use_monthly_${i}`]);
          }
          this.$store.commit('updateObject', { object: 'userEnteredMonthlyKwhValues', value: monthly_totals_kwh });

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile.doe_reference_name,
            monthly_totals_kwh: monthly_totals_kwh,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + "/simulated-monthly-elec-load",
            data: params,
            success: function(data) {
              var payload = {
                loadsKw: data.loads_kw,
                minKw: data.min_kw,
                meanKw: data.mean_kw,
                maxKw: data.max_kw,
              }
              self.$store.commit('updateObject', { object: 'elecSimBuildMonthlyLoadProfile', value: payload });
              self.getColdSimulatedLoadProfile();
              self.getColdCustomAnnualPctLoad();
              self.getColdCustomMonthlyPctLoad();
              self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });

              // for copied runs
              if (self.run.site.load_profile.load_reduction != 100) {
                var monthly_values = [];
                self.userEnteredMonthlyKwhValues.forEach(function(value, i) {
                  monthly_values.push((self.run.site.load_profile[`energy_use_monthly_${i + 1}`] / (self.run.site.load_profile.load_reduction / 100.0)).toFixed(0));
                })

                self.$store.commit('updateObject', { object: 'userEnteredMonthlyKwhValues', value: monthly_values });
                self.updateMonthlyKwhValuesFromSlider();
              }
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'elecSimBuildMonthlyLoadProfile', value: {} });
              self.getColdSimulatedLoadProfile();
              self.getColdCustomAnnualPctLoad();
              self.getColdCustomMonthlyPctLoad();
              self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
            }
          })
        }
      },
      getThermalSimBuildingMonthlyLoad: function() {
        if(this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type != 'monthly') {
          return false;
        }

        if(this.allMonthlyInputsPresent('thermal') && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name) {
          this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });

          var monthly_mmbtu = [];
          var addressable_load_fraction = [];
          for(var i = 1; i <= 12; i++) {
            monthly_mmbtu.push(this.run.site.load_profile.load_profile_boiler_fuel[`thermal_mmbtu_monthly_${i}`]);

            var monthlyAddressableLoad = this.run.site.load_profile.load_profile_boiler_fuel[`addressable_load_fraction_monthly_${i}`];
            if(monthlyAddressableLoad) {
              addressable_load_fraction.push(monthlyAddressableLoad / 100.0);
            } else {
              addressable_load_fraction.push(1.0);
            }
          }

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile.load_profile_boiler_fuel.doe_reference_name,
            monthly_mmbtu: monthly_mmbtu,
            addressable_load_fraction: addressable_load_fraction,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + "/simulated-monthly-thermal-load",
            data: params,
            success: function(data) {
              var payload = {
                loadsMMBtu: data.loads_mmbtu_per_hour,
                minMMBtu: data.min_mmbtu_per_hour,
                meanMMBtu: data.mean_mmbtu_per_hour,
                maxMMBtu: data.max_mmbtu_per_hour,
                dhwLoadsMMBtu: data.dhw_loads_mmbtu_per_hour,
                dhwMinMMBtu: data.dhw_min_mmbtu_per_hour,
                dhwMaxMMBtu: data.dhw_max_mmbtu_per_hour,
                dhwMeanMMBtu: data.dhw_mean_mmbtu_per_hour,
                spaceLoadsMMBtu: data.space_loads_mmbtu_per_hour,
                spaceMinMMBtu: data.space_min_mmbtu_per_hour,
                spaceMaxMMBtu: data.space_max_mmbtu_per_hour,
                spaceMeanMMBtu: data.space_mean_mmbtu_per_hour,
              }
              self.$store.commit('updateObject', { object: 'thermalSimBuildMonthlyLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });

              data.dhw_monthly_mmbtu.forEach(function(mmbtu, index) {
                self.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_boiler_fuel', attribute: `dhw_mmbtu_monthly_${index + 1}`, value: mmbtu });
              });

              data.space_monthly_mmbtu.forEach(function(mmbtu, index) {
                self.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_boiler_fuel', attribute: `space_mmbtu_monthly_${index + 1}`, value: mmbtu });
              });

              if (self.run.analyze_ghp) {
                var dhwAnnualMMbtuForRatio = data.dhw_annual_mmbtu;
                var annualMMbtuForRatio = data.annual_mmbtu

                self.$store.commit('updateObject', { object: 'dhwAnnualMMbtuForRatio', value: dhwAnnualMMbtuForRatio });
                self.$store.commit('updateObject', { object: 'annualMmbtuForRatio', value: annualMMbtuForRatio });
              }

              if (self.run.analyze_ghp && self.annualTonHourForRatio) {
                self.updateRatioHeatingToCooling();
              }
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'thermalSimBuildMonthlyLoadProfile', value: {} });
              self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
            }
          })
        }
      },
      getUrlForSimColdLoadBasedOnElec: function(elecLoadProfile) {
        // this is used to create the url and params for simulating a cold load
        // based on the elec load (hence all the conditionals and permutations)
        // this happens when custom annual (or monthly) tonhours are not entered
        // by the user (we still simulate the cold load, this simulation is based
        // on the electric load and cold load building type(s)). This happens when:
        // !run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump
        // OR
        // !run.site.load_profile.load_profile_chiller_thermal.custom_annual_simulated_campus
        // this is so users don't specify cold loads that are greater than elec loads
        // which gives the API trouble during optimization
        var coldType = this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type;
        var coldBuilding = this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name;
        var elecBuilding = this.run.site.load_profile.doe_reference_name;
        if(this.run.site.load_profile.annual_kwh) {
          var annualKwh = this.run.site.load_profile.annual_kwh;
        } else if(this[`${elecLoadProfile}`].loadsKw) {
          var annualKwh = this[`${elecLoadProfile}`].loadsKw.reduce((a, b) => a + b);
        }
        var campusAnnualKwh = this.run.site.load_profile.campus_annual_kwh;

        if(coldType == "simulated" && elecLoadProfile == 'elecSimBuildAnnualLoadProfile') {
          return `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}&annual_kwh=${annualKwh}&cooling_doe_ref_name=${coldBuilding}`;
        } else if(coldType == "simulated" && elecLoadProfile == 'elecSimBuildMonthlyLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}&cooling_doe_ref_name=${coldBuilding}`;
          for(var i = 1; i <= 12; i++) {
            url += "&monthly_totals_kwh[]=" + this.run.site.load_profile[`energy_use_monthly_${i}`];
          }

          return url;
        } else if(coldType == "simulated" && elecLoadProfile == 'elecSimCampusLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&cooling_doe_ref_name=${coldBuilding}&annual_kwh=${campusAnnualKwh}`;
          this.run.site.load_profile.campus_buildings.forEach(function(el) {
            url += `&doe_reference_names[]=${el.doe_reference_name}`;
            url += `&percent_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        } else if(coldType == "simulated" && elecLoadProfile == 'elecUploadedLoadProfile') {
          return `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&cooling_doe_ref_name=${coldBuilding}`;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecSimBuildAnnualLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}&annual_kwh=${annualKwh}`;
          this.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecSimBuildMonthlyLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}`;
          this.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })
          for(var i = 1; i <= 12; i++) {
            url += "&monthly_totals_kwh[]=" + this.run.site.load_profile[`energy_use_monthly_${i}`];
          }

          return url;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecSimCampusLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&annual_kwh=${campusAnnualKwh}`
          this.run.site.load_profile.campus_buildings.forEach(function(el) {
            url += `&doe_reference_names[]=${el.doe_reference_name}`;
            url += `&percent_shares[]=${el.percent_of_total / 100.0}`;
          })
          this.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecUploadedLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}`;
          this.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        }
      },
      populateAnnualTonHourValueOrDefault: function(payload) {
        // if annual tonhour already present then we just update the default
        if(!this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour) {

          this.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: payload });
        }

        this.$store.commit('updatePlaceholder', {
          association_1: 'site',
          association_2: 'load_profile',
          association_3: 'load_profile_chiller_thermal',
          attribute: 'annual_tonhour',
          value: Math.round(payload.annualTonHour)
        });
      },
      getColdSimLoadProfileBasedOnElec: function(url, coldLoadProfile) {
        // see comment in getUrlForSimColdLoadBasedOnElec method above
        this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

        var self = this;
        $.ajax({
          url: url,
          success: function(data) {
            var payload = {
              loadsTonHour: data.cooling_defaults.loads_ton,
              minTonHour: data.cooling_defaults.min_ton,
              meanTonHour: data.cooling_defaults.mean_ton,
              maxTonHour: data.cooling_defaults.max_ton,
            }

            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.cooling_defaults.chiller_cop });
            self.$store.commit('updateObject', { object: coldLoadProfile, value: payload });

            if (self.run.site.electric_chiller.cop) {
              var cop = self.run.site.electric_chiller.cop
            } else {
              var cop = data.cooling_defaults.chiller_cop
            }

            if(self.run.site.load_profile.typical_load_type == "uploaded") {
              // when the electric typical load type == uploaded, the cold load
              // profile is based on the `fraction_of_total_electric_profile` returned
              // by the /simulated_load endpoint (if no tonhour specified)
              cop ? '' : cop = data.cooling_defaults.chiller_cop;

              var coldLoad = self[self.$store.getters.elecLoadProfile].loadsKw.map(function(el, i) {
                return el * data.cooling_defaults.fraction_of_total_electric_profile[i] * cop / 3.51685;
              })
              var payload = {
                loadsTonHour: coldLoad,
                minTonHour: Math.min.apply(Math, coldLoad),
                meanTonHour: self.$store.getters.average(coldLoad),
                maxTonHour: Math.max.apply(Math, coldLoad),
              }
              self.$store.commit('updateObject', { object: coldLoadProfile, value: payload });
            }

            payload.annualTonHour = data.cooling_defaults.annual_tonhour;
            self.populateAnnualTonHourValueOrDefault(payload);
            self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            self.updateAbsChillerDefaults();

            if (self.run.analyze_ghp) {
              var annualTonHourForRatio = payload.annualTonHour;
              self.$store.commit('updateObject', { object: 'annualTonHourForRatio', value: annualTonHourForRatio });
            }

            if (self.run.analyze_ghp && self.annualMmbtuForRatio) {
              self.updateRatioHeatingToCooling();
            }
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'coldSimBuildLoadProfile', value: {} });
            self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
          }
        })
      },
      updateRatioHeatingToCooling: function() {
        var annualMmbtu = this.annualMmbtuForRatio;
        var annualTonHour = this.annualTonHourForRatio;

        if (this.run.site.ghp.boiler.boiler_efficiency) {
          var boilerEfficiency = this.run.site.ghp.boiler.boiler_efficiency / 100;
        } else {
          var boilerEfficiency = this.placeholders.site.ghp.boiler.boiler_efficiency.split('%')[0] / 100;
        }

        if (this.run.site.ghp.space_heating_efficiency_thermal_factor) {
          var heatingThermalFactor = this.run.site.ghp.space_heating_efficiency_thermal_factor / 100;
        } else {
          var heatingThermalFactor = this.placeholders.site.ghp.space_heating_efficiency_thermal_factor.split('%')[0] / 100;
        }

        if (this.run.site.ghp.cooling_efficiency_thermal_factor) {
          var coolingThermalFactor = this.run.site.ghp.cooling_efficiency_thermal_factor / 100;
        } else {
          var coolingThermalFactor = this.placeholders.site.ghp.cooling_efficiency_thermal_factor.split('%')[0] / 100;
        }
        if (!this.run.site.ghp.can_serve_dhw) {
          var calculateRatio = ((annualMmbtu - this.dhwAnnualMMbtuForRatio) * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
        } else {
          var calculateRatio = (annualMmbtu * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
        }
        this.$store.commit('updateObject', { object: 'ratioHeatingToCooling', value: calculateRatio });
      },
      getColdSimulatedLoadProfile: function() {
        // see comment in getUrlForSimColdLoadBasedOnElec method above
        var coldType = this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type;
        var coldSimType = this.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type;
        var coldBuilding = this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name;
        var customEnergyConsumption = this.run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump;
        var elecLoadType = this.run.site.load_profile.typical_load_type;
        var elecSimType = this.run.site.load_profile.simulated_load_type;
        var elecLoadProfile = this.$store.getters.elecLoadProfile;
        var url = this.getUrlForSimColdLoadBasedOnElec(elecLoadProfile);

        if(coldType == 'simulated') {
          if(!coldBuilding) {
            this.$store.commit('updateObject', { object: 'coldSimBuildLoadProfile', value: {} });
            this.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: {} });
            this.$store.commit('updateObject', { object: 'coldSimBuildMonthlyLoadProfile', value: {} });

            return false;
          } else {

            if(!customEnergyConsumption || (customEnergyConsumption && coldSimType == "annual" && !this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour)) {
              if(Object.keys(this[elecLoadProfile]).length == 0) {
                // if no elec load then set cold load to null
                this.$store.commit('updateObject', { object: 'coldSimBuildLoadProfile', value: {} });

                return false;
              }
              // get cold load based on elec load inputs and cold building
              // when the annual_tonhour has not been specified by the user we still base the
              // cold load on the elec load
              this.getColdSimLoadProfileBasedOnElec(url, this.$store.getters.coldLoadProfile);
            } else if(customEnergyConsumption && coldSimType == "annual" && this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour) {
              this.getColdSimBuildingAnnualLoad();
            } else if(customEnergyConsumption && coldSimType == "monthly") {
              this.getColdSimBuildingMonthlyLoad();
            }
          }
        } else if(coldType == "simulated_campus") {
          var customAnnual = $('#custom_annual_simulated_campus')[0].checked;
          if(!customAnnual) {
            var verifyAllBuildings = !this.run.site.load_profile.load_profile_chiller_thermal.campus_buildings.map((el) => el.doe_reference_name).includes(undefined);
            var buildings = this.run.site.load_profile.load_profile_chiller_thermal.campus_buildings;
            if(buildings.length == 1) {
              var percentSum = Number(buildings[0].percent_of_total);
            } else {
              var percentSum = buildings.map(function(el, i) {
                return Number(el.percent_of_total);
              }).reduce(function(total, num) {
                return total + num;
              })
            }

            if(Object.keys(this[elecLoadProfile]).length == 0 || !verifyAllBuildings || percentSum != 100) {
              // if no elec load then set cold load to null
              this.$store.commit('updateObject', { object: 'coldSimCampusFromElecLoadProfile', value: {} });

              return false;
            } else {
              this.getColdSimLoadProfileBasedOnElec(url, this.$store.getters.coldLoadProfile);
            }
          }
        }
      },
      getColdSimBuildingMonthlyLoad: function() {
        if(this.allMonthlyInputsPresent('cold') && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

          var monthly_tonhour = [];
          for(var i = 1; i <= 12; i++) {
            monthly_tonhour.push(this.run.site.load_profile.load_profile_chiller_thermal[`monthly_tonhour_${i}`]);
          }

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile.load_profile_chiller_thermal.doe_reference_name,
            monthly_tonhour: monthly_tonhour,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + "/simulated-monthly-cold-load",
            data: params,
            success: function(data) {
              var payload = {
                loadsTonHour: data.loads_ton,
                minTonHour: data.min_ton,
                meanTonHour: data.mean_ton,
                maxTonHour: data.max_ton,
              }
              self.$store.commit('updateObject', { object: 'coldSimBuildMonthlyLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.chiller_cop });

              self.updateAbsChillerDefaults();
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'coldSimBuildMonthlyLoadProfile', value: {} });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            }
          })
        }
      },
      getColdCustomAnnualPctLoad: function() {
        var self = this;
        var fraction = this.run.site.load_profile.load_profile_chiller_thermal.annual_fraction_of_electric_load;
        var typicalLoadType = this.getTypicalLoadType();
        this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

        if(!fraction || !Object.keys(this[typicalLoadType]).length) {
          this.$store.commit('updateObject', { object: 'coldCustomAnnualPctLoadProfile', value: {} });
        } else {
          var factor = (fraction / 100.0);
          var maxKw = this[typicalLoadType].maxKw * factor;
          var chillerCOP = this.run.site.electric_chiller.cop;

          if(chillerCOP) {
            this.updateColdLoad(this[typicalLoadType].loadsKw, chillerCOP, 'annual');
          } else {
            var fraction = this.run.site.load_profile.load_profile_chiller_thermal.annual_fraction_of_electric_load / 100.0;
            var load = this[typicalLoadType].loadsKw.map(function(el, i) {
              return el * fraction;
            })

            // send fractional load to API to get chiller COP and THEN adjust load yet again
            this.getChillerCOPAndUpdateLoad(load, 'annual');
          }
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
        }, 800);
      },
      getColdCustomMonthlyPctLoad: function() {
        var self = this;
        var typicalLoadType = this.getTypicalLoadType();
        this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

        if(!this.allMonthlyInputsPresent('cold-percent') || !Object.keys(this[typicalLoadType]).length || !this.run.site.latitude || !this.run.site.longitude) {
          this.$store.commit('updateObject', { object: 'coldCustomMonthlyPctLoadProfile', value: {} });
        } else {
          var monthly_fraction = [];
          for(var i = 1; i <= 12; i++) {
            var load = self.run.site.load_profile.load_profile_chiller_thermal[`lp_fraction_monthly_${i}`];
            monthly_fraction.push(load / 100);
          }

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            monthly_fraction: monthly_fraction,
          }

          $.ajax({
            url: this.urlRoot + "/simulated-monthly-cold-load",
            data: params,
            success: function handleResults(data) {
              var typicalLoadType = self.getTypicalLoadType();
              var initialLoad = self[typicalLoadType].loadsKw.map(function(el, i) {
                return el * data.loads_fraction[i];
              })

              // send fractional load to API to get chiller COP and THEN adjust load yet again
              self.getChillerCOPAndUpdateLoad(initialLoad, 'monthly');
            },
          })
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
        }, 800);
      },
      getChillerCOPAndUpdateLoad: function(initialLoad, type) {
        var self = this;

        $.ajax({
          url: self.urlRoot + "/electric-chiller-cop",
          data: { max_kw: Math.max.apply(Math, initialLoad) },
          success: function handleResults(data) {
            self.$store.commit('updatePlaceholder', {
              association_1: 'site',
              association_2: 'electric_chiller',
              attribute: 'cop',
              value: data.chiller_cop
            });

            self.updateColdLoad(initialLoad, data.chiller_cop, type);
          },
        })
      },
      updateColdLoad: function(initialLoad, chillerCOP, type) {
        var coldLoad = {};
        var typicalLoadType = this.getTypicalLoadType();
        var factor = chillerCOP / this.tonhourToKwht;

        coldLoad.loadsTonHour = initialLoad.map(function(el, i) {
          return el * factor;
        })
        coldLoad.maxTonHour = Math.max.apply(Math, initialLoad) * factor;
        coldLoad.minTonHour = Math.min.apply(Math, initialLoad) * factor;
        coldLoad.meanTonHour = this.$store.getters.average(initialLoad) * factor;

        if(type == "annual") {
          this.$store.commit('updateObject', { object: 'coldCustomAnnualPctLoadProfile', value: coldLoad });
        } else if(type == "monthly") {
          this.$store.commit('updateObject', { object: 'coldCustomMonthlyPctLoadProfile', value: coldLoad });
        }
      },
      getTypicalLoadType: function() {
        var type = this.run.site.load_profile.typical_load_type;

        if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual") {
          return 'elecSimBuildAnnualLoadProfile';
        } else if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "monthly") {
          return 'elecSimCampusLoadProfile';
        } else if(type == "simulated_campus") {
          return 'elecSimCampusLoadProfile';
        } else if(type == "uploaded") {
          return 'elecUploadedLoadProfile';
        }
      },
      updateTypicalLoadType: function(type) {
        this.updateStore('typical_load_type', type);
        this.updateYear();
        this.getColdSimulatedLoadProfile();
        this.getColdCustomAnnualPctLoad();
        this.getColdCustomMonthlyPctLoad();
      },
      updateThermalLoadType: function(type) {
        this.updateStoreNested3({
          association_3: 'load_profile_boiler_fuel',
          attribute: 'thermal_load_type',
          value: type
        });
        this.$emit('update-ghp-defaults');
      },
      updateColdLoadType: function(type) {
        this.updateStoreNested3({
          association_3: 'load_profile_chiller_thermal',
          attribute: 'cold_load_type',
          value: type
        });
      },
      
      //new comment (2023): the updated load code block is commented out due to
      //buggy behavior wherein the loads don't get updated automatically after
      //uploading load csv and then adjusting the slider, but get updated if
      //csv is uploaded after slider is adjusted, the load gets updated to the
      //adjusted ratio. This is not what we want, so changed behaviour to only
      //update loads in POST to API. Otherwise, only the charts show the adjustement

      //updateUploadedLoadsKw: function() {
      //  var self = this;
      //  var newLoad = this.unadjustedUploadedLP.map(function(el, i) {
      //    return el * (self.run.site.load_profile.load_reduction / 100.0);
      //  })
      //
      //  this.updateStore('loads_kw', newLoad);
      //},
      parseCSV: function(event, type) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.load_profile.loads_kw = [];
                    event.target.value = "";
                    bootbox.alert('The load profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                if(type == 'electric') {
                  // old comment: kinda funky but we have to preserve the unadjusted load profile
                  // (without load reduction factored in) for scenario where user adjusts
                  // the load reduction twice - otherwise we are adjusting the already
                  // adjusted series (as opposed to the original)

                  //new comment (2023): the updated load code block is commented out due to
                  //buggy behavior wherein the loads don't get updated automatically after
                  //uploading load csv and then adjusting the slider, but get updated if
                  //csv is uploaded after slider is adjusted, the load gets updated to the
                  //adjusted ratio. This is not what we want, so changed behaviour to only
                  //update loads in POST to API. Otherwise, only the charts show the adjustement

                  //self.unadjustedUploadedLP = values;
                  //var adjustedValues = values.map(function(el, i) {
                  //  return el * (self.run.site.load_profile.load_reduction / 100.0);
                  //});
                  self.updateUploadedLoadProfile(values, 'electric');
                  self.updateStore('loads_kw', JSON.stringify(values));
                  self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'load_profile', attribute: 'typical_load_file_name', value: file.name});
                  self.displayElecLPLoading();
                } else if(type == 'thermal') {
                  self.updateUploadedLoadProfile(values, 'thermal');
                  self.updateStoreNested3({
                    association_3: 'load_profile_boiler_fuel',
                    attribute: 'loads_mmbtu_per_hour',
                    value: JSON.stringify(values)
                  });
                  self.updateStoreNested3({association_3: 'load_profile_boiler_fuel', attribute: 'thermal_load_file_name', value: file.name});
                  self.displayThermalLPLoading();
                } else if(type == 'cold') {
                  self.updateUploadedLoadProfile(values, 'cold');
                  self.updateStoreNested3({
                    association_3: 'load_profile_chiller_thermal',
                    attribute: 'cold_uploaded_loads_ton',
                    value: JSON.stringify(values)
                  });
                  self.updateStoreNested3({association_3: 'load_profile_chiller_thermal', attribute: 'cold_load_file_name', value: file.name});
                  self.displayColdLPLoading();
                }
              } finally {
              }
            }
          })
        }
      },
      updateUploadedLoadProfile: function(values, type) {
        var avgValue = 0;
        var minValue;
        var maxValue;
        for(var i = 0; i < values.length; i++) {
          var value = values[i];
          avgValue += value;

          if(minValue === undefined || value < minValue) {
            minValue = value;
          }

          if(maxValue === undefined || value > maxValue) {
            maxValue = value;
          }
        }
        avgValue = avgValue / values.length;

        if(type == 'electric') {
          var payload = {
            loadsKw: values,
            minKw: minValue,
            meanKw: avgValue,
            maxKw: maxValue,
          }

          this.$store.commit('updateObject', { object: 'elecUploadedLoadProfile', value: payload });
          this.getColdSimulatedLoadProfile();
          this.getColdCustomAnnualPctLoad();
          this.getColdCustomMonthlyPctLoad();
        } else if(type == 'thermal') {
          var payload = {
            loadsMMBtu: values,
            minMMBtu: minValue,
            meanMMBtu: avgValue,
            maxMMBtu: maxValue,
          }

          this.$store.commit('updateObject', { object: 'thermalUploadedLoadProfile', value: payload });
        } else if(type == 'cold') {
          var payload = {
            loadsTonHour: values,
            minTonHour: minValue,
            meanTonHour: avgValue,
            maxTonHour: maxValue,
          }

          this.$store.commit('updateObject', { object: 'coldUploadedLoadProfile', value: payload });
          this.updateAbsChillerDefaults();
          // call new endpoint to populate electric cop
        }
      },
      displayElecLPLoading: function() {
        var type = this.run.site.load_profile.typical_load_type;
        var simBuildType = this.run.site.load_profile.simulated_load_type;
        var elecSimBuildingAnnualLP = type == "simulated_building" && simBuildType == "annual" && this.elecSimBuildAnnualLoadProfile.loadsKw;
        var elecSimBuildingMonthlyLP = type == "simulated_building" && simBuildType == "monthly" && this.elecSimBuildMonthlyLoadProfile.loadsKw;
        var elecSimCampusLP = type == "simulated_campus" && this.elecSimCampusLoadProfile.loadsKw;
        var elecUploadedLP = type == "uploaded" && this.elecUploadedLoadProfile.loadsKw;

        if(elecSimBuildingAnnualLP || elecSimBuildingMonthlyLP || elecSimCampusLP || elecUploadedLP) {
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
          var self = this;
          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }, 800);
        }
      },
      displayThermalLPLoading: function() {
        var type = this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type;
        var simBuildType = this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type;
        var thermalSimBuildingAnnualLP = type == "simulated" && simBuildType == "annual" && this.thermalSimBuildAnnualLoadProfile.loadsMMBtu;
        var thermalSimBuildingMonthlyLP = type == "simulated" && simBuildType == "monthly" && this.thermalSimBuildMonthlyLoadProfile.loadsMMBtu;
        var thermalUploadedLP = type == "uploaded" && this.thermalUploadedLoadProfile.loadsMMBtu;

        if(thermalSimBuildingAnnualLP || thermalSimBuildingMonthlyLP || thermalUploadedLP) {
          this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
          var self = this;
          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }, 800);
        }
      },
      displayColdLPLoading: function() {
        var type = this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type;
        var simBuildType = this.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type;
        var coldSimBuildingAnnualLP = type == "simulated" && simBuildType == "annual" && this.coldSimBuildAnnualLoadProfile.loadsTonHour;
        var coldSimBuildingMonthlyLP = type == "simulated" && simBuildType == "monthly" && this.coldSimBuildMonthlyLoadProfile.loadsTonHour;
        var coldUploadedLP = type == "uploaded" && this.coldUploadedLoadProfile.loadsTonHour;

        if(coldSimBuildingAnnualLP || coldSimBuildingMonthlyLP || coldUploadedLP) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });
          var self = this;
          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
          }, 800);
        }
      },
      downloadLoadProfile: function(loadProfile) {
        if(loadProfile.includes("thermal")) {
          var values = this[loadProfile].loadsMMBtu;
          var rows = [ ['Hour', 'Heating Load (MMBtu/hr)'] ];
        } else if(loadProfile.includes("cold")) {
          var values = this[loadProfile].loadsTonHour;
          var rows = [ ['Hour', 'Cooling Thermal Load (tons)'] ];
        } else {
          var values = this[loadProfile].loadsKw;
          var rows = [ ['Hour', 'Electric Load (kW)'] ];
        }
        if(!values || values.length == 0) {
          bootbox.alert("A load profile must be selected or uploaded first.");
          return false;
        }

        for(var i = 0, len = values.length; i < len; i++) {
          rows.push([i + 1, values[i]]);
        }

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'REopt Load Profile.csv');
      },
      getTypicalLoadProfileName(loadProfile) {
        var name = JSON.parse(loadProfile.file_data).metadata.filename.split(".")[0];

        return `${name} (${loadProfile.year})`
      },
      updateTypicalLoadProfile() {
        this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        var id = this.run.site.load_profile.typical_load_profile_id;
        if(!id) {
          this.$store.commit('updateObject', { object: 'elecUploadedLoadProfile', value: {} });
          this.updateStore('loads_kw', []);
          this.unadjustedUploadedLP = [];
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });

          return false;
        }

        var loadProfile = this.typicalLoadProfiles.find(function(loadProfile) {
          return loadProfile.id == id;
        })
        var payload = {
          loadsKw: loadProfile.load_kw.map(function(el) {
            // for the chart to find the correct min/max/avg values,
            // need to round each value up to four decimal places due to the precision/scale 
            // restriction in the rails db for min_load, max_load, and avg_load
            var roundedEl = Math.round(el * 10000) / 10000
            return parseFloat(roundedEl);
          }),
          minKw: loadProfile.min_load,
          meanKw: loadProfile.avg_load,
          maxKw: loadProfile.max_load,
        }
        this.$store.commit('updateObject', { object: 'elecUploadedLoadProfile', value: payload });
        this.getColdSimulatedLoadProfile();
        this.updateStore('loads_kw', JSON.stringify(payload.loadsKw));
        this.unadjustedUploadedLP = payload.loadsKw;
        this.updateYear();

        var self = this;
        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
        }, 800);
      },
      displayTypicalLoadProfileForm: function() {
        this.$store.commit('updateObject', { object: 'showTypicalLoadProfileForm', value: true });
      },
      updateSelectedTypLoadProfile: function(event, payload) {
        var fileData = JSON.parse(payload.file_data);
        var fileName = fileData.metadata.filename.split(".")[0];
        var id = payload.id;

        var newLoadProfile = {
          id: id,
          file_data: payload.file_data,
          year: payload.year,
          load_kw: payload.load_kw,
          min_load: payload.min_load,
          avg_load: payload.avg_load,
          max_load: payload.max_load,
        }

        this.typicalLoadProfiles.push(newLoadProfile);
        this.updateStore('typical_load_profile_id', id);
        //this.updateStore('typical_load_profile_data', fileName);
        this.updateStore('loads_kw', JSON.stringify(payload.load_kw));
        this.updateTypicalLoadProfile();
      },
      updateYear: function() {
        var year;
        if(this.run.site.load_profile.typical_load_type == "simulated_building" || this.run.site.load_profile.typical_load_type == "simulated_campus") {
          year = 2017;
        } else {
          if(this.currentUser && this.run.site.load_profile.typical_load_profile_id) {
            var self = this;
            year = this.typicalLoadProfiles.find(function(el) {
              return el.id == self.run.site.load_profile.typical_load_profile_id;
            }).year
          } else {
            year = this.run.site.load_profile.year || 2022;
            this.placeholders.site.load_profile.year = "2022";
          }
        }

        this.$store.commit('updateObject', { object: 'year', value: year });
        this.$store.commit('updateFlatPickrYear', year);
      },
      getDefaultOutageDateHour: function() {
        if(!this.run.site.load_profile.outage_duration || this.run.site.load_profile.custom_multiple_outage_dates || this.run.site.load_profile.number_of_outages == 1) {
          return false;
        } else if ((this.elecSimBuildAnnualLoadProfile.loadsKw && this.elecSimBuildAnnualLoadProfile.loadsKw.length == 0) && (this.elecSimBuildMonthlyLoadProfile.loadsKw && this.elecSimBuildMonthlyLoadProfile.loadsKw.length == 0) && (this.elecSimCampusLoadProfile.loadsKw && this.elecSimCampusLoadProfile.loadsKw.length == 0) && (this.elecUploadedLoadProfile.loadsKw && this.elecUploadedLoadProfile.loadsKw.length == 0) && this.run.site.load_profile.critical_loads_kw.length == 0) { 
          return false;
        }
        var self = this;
        var params = {
          seasonal_peaks: 1,
          outage_duration: this.run.site.load_profile.outage_duration,
          start_not_center_on_peaks: 0,
        }
        var typicalLoadType = this.run.site.load_profile.typical_load_type;
        var criticalLoadType = this.run.site.load_profile.critical_load_type;
        if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "annual" && this.elecSimBuildAnnualLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimBuildAnnualLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "monthly" && this.elecSimBuildMonthlyLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimBuildMonthlyLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "simulated_campus" && criticalLoadType == "percent" && this.elecSimCampusLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimCampusLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "uploaded" && criticalLoadType == "percent" && this.elecUploadedLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecUploadedLoadProfile")['loadsKw'];
        } else if(criticalLoadType == "upload") {
          params['critical_load'] = this.run.site.load_profile.critical_loads_kw;
        }

        // this variable (time_steps_per_hour) doesn't seem to be used anywhere after it's declared... not sure what it's for.
        var time_steps_per_hour = params['critical_load'].length/8760;

        $.ajax({
          url: self.urlRoot + "/peak-load-outage-times",
          method: 'POST',
          data: JSON.stringify(params),
          contentType: "application/json",
          processData: false,
          success: function handleResults(data) {
            var year = (self.run.site.load_profile.year) ? Number(self.run.site.load_profile.year) : Number(self.year);
            var outagedate = new Date(Date.UTC(year, 0, 1));
            for(var i=0; i < 4; i++){
              var outagedate = new Date(Date.UTC(year, 0, 1));          
              outagedate.setUTCSeconds(Math.floor(data.outage_start_time_steps[i]/24) * 24 * 3600);
              var payload = {
                index: i,
                outage_duration: Number(self.run.site.load_profile.outage_duration),
                outage_start_date: `${year}-${outagedate.getUTCMonth()+1}-${outagedate.getUTCDate()}`,
                outage_start_hour: data.outage_start_time_steps[i]%24,
                outage_start_time_step: data.outage_start_time_steps[i],
              }
              self.$store.commit('updateOutageDateHour', payload);
            }
          },
          error: function(data) {
            return false;
          },
        })
      },
      calculateCriticalPercentLP: function(typicalLoadProfile) {
        var percent = (this.run.site.load_profile.critical_load_fraction / 100.0) || (this.placeholders.site.load_profile.critical_load_fraction / 100.0);
        var self = this;

        return {
          loadsKw: self[typicalLoadProfile].loadsKw.map(function(el, i) {
            return el * percent;
          }),
          minKw: self[typicalLoadProfile].minKw * percent,
          meanKw: self[typicalLoadProfile].meanKw * percent,
          maxKw: self[typicalLoadProfile].maxKw * percent,
        }
      },
      updateAbsChillerDefaults: function() {
        if(!this.run.site.chp.analyze_abs_chiller) {
          return false;
        }
        var self = this;
        $.ajax({
          url: self.urlRoot + '/abs-chiller-defaults',
          data: {
            hot_water_or_steam: this.run.site.chp.boiler.existing_boiler_production_type,
            max_cooling_load_tons: this[this.$store.getters.coldLoadProfile].maxTonHour,
          },
          success: function handleResults(data) {
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'cop_thermal', value: data.default_inputs.cop_thermal });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'installed_cost_per_ton', value: '$' + Math.round(data.default_inputs.installed_cost_per_ton) });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'om_cost_per_ton', value: '$' + Math.round(data.default_inputs.om_cost_per_ton) });
          }
        })
      },
      updateGHPDefaults: function() {
        this.$emit('update-ghp-defaults');
      },
      updateLoadSlider: function() {
        if (this.run.site.load_profile.load_reduction != 100) {
          this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'load_profile', attribute: 'load_reduction', value: '100.00' });
          $('#load-reduction-slider')[0].noUiSlider.set("100.00");

          if (this.run.site.load_profile.annual_kwh && !this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type === "annual") {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: 'annual_kwh', value: null });
          } else if (this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type === "annual") {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: 'annual_kwh', value: this.userEnteredAnnualKwhValue });
          }

          if (this.userEnteredMonthlyKwhValues.length !== 0 && this.run.site.load_profile.simulated_load_type === "monthly") {
            for(var i = 0; i < 12; i++) {
              this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: `energy_use_monthly_${i + 1}`, value: this.userEnteredMonthlyKwhValues[i] });
            }
          }
        }
      },
      updateAnnualKwhValueFromSlider: function() {
        if (this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.typical_load_type != "simulated_campus" && !this.userEnteredAnnualKwhValue && this.run.site.load_profile.load_reduction != 100) {
          var annualKwh = parseInt(this.placeholders.site.load_profile.annual_kwh.split(',').join(''));
          var loadReductionPct = this.run.site.load_profile.load_reduction / 100;
          var adjustedAnnualKwh = annualKwh * loadReductionPct;

          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            // Cannot say Math.round(adjustedAnnualKwh), need to leave at least one decimal place in this value 
            // or else on copy sometimes the default value and the calculated value do not match and it thinks
            // there was a user entered value when there wasn't.
            value: adjustedAnnualKwh.toFixed(1)
          });
        } else if (this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction != 100) {
          var annualKwh = parseInt(this.userEnteredAnnualKwhValue.split(',').join(''));
          var loadReductionPct = this.run.site.load_profile.load_reduction / 100;
          var adjustedAnnualKwh = annualKwh * loadReductionPct;

          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            value: adjustedAnnualKwh.toFixed(1)
          });
        } else if (this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction == 100) {
          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            value: this.userEnteredAnnualKwhValue
          });
        } else {
          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            value: ''
          });
        }
      },
      updateMonthlyKwhValuesFromSlider: function() {
        if (this.userEnteredMonthlyKwhValues.length !== 0 && this.run.site.load_profile.simulated_load_type == "monthly" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction != 100) {
          var self = this;
          this.userEnteredMonthlyKwhValues.forEach(function(value, i) {
            var monthlyKwh = parseInt(value.split(',').join(''));
            var loadReductionPct = self.run.site.load_profile.load_reduction / 100;
            var adjustedMonthlyKwh = monthlyKwh * loadReductionPct;

            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile',
              attribute: `energy_use_monthly_${i + 1}`,
              value: adjustedMonthlyKwh.toFixed(1)
            });
          })
        } else if (this.userEnteredMonthlyKwhValues.length !== 0 && this.run.site.load_profile.simulated_load_type == "monthly" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction == 100) {
          var self = this;
          this.userEnteredMonthlyKwhValues.forEach(function(value, i) {
            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile',
              attribute: `energy_use_monthly_${i + 1}`,
              value: value
            });
          })
        }
      },
      pasteInputs: function(type) {
        event.preventDefault();
        var self = this;
        var clip = event.clipboardData.getData('text');

        if (clip.includes('\r\n')) {
          var clipArray = clip.split('\r\n');
          if (clipArray.length > 12) {
            // remove any values at the end of the array if they exceed the 12 month count so the first 12 paste to the correct months
            clipArray.splice(12, clipArray.length - 12);
          }
          // remove any commas within the numbers if they exist
          clipArray.forEach(function(item, i) {
            clipArray[i] = item.split(',').join('');
          })

          // set the month number to be assigned dynamically below
          if (type === 'electric') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[3]);
          } else if (type === 'cold') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[4].split('_')[2]);
          } else if (type === 'thermal-percent') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[4].split('_')[4]);
          } else {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[4].split('_')[3]);
          }

          if (type === 'thermal' || type === 'cold' || type === 'cold-percent' || type === 'thermal-percent') {
            var attributeNameArray = event.target.name.replaceAll(']', '').split('[')[4].split('_');
            attributeNameArray.splice(-1);
            var attrBaseName = attributeNameArray.join('_');
          }
        } else {
          // remove any commas if they exist for single value paste
          var clipValue = clip.split(',').join('');
          if (type === 'electric') {
            var attributeName = event.target.name.replaceAll(']', '').split('[')[3];
          } else {
            var attributeName = event.target.name.replaceAll(']', '').split('[')[4];
          }
        }

        // remove "_attributes" from the end of the association_3 name for dynamic assignment below
        if (type === 'thermal' || type === 'cold' || type === 'cold-percent' || type === 'thermal-percent') {
          var association3Array = event.target.name.replaceAll(']', '').split('[')[3].split('_');
          association3Array.splice(-1);
          var association3Name = association3Array.join('_');
        }

        if (clipArray) {
          clipArray.forEach(function(item, i) {
            // Ensure the values are only numbers.
            if (isNaN(parseFloat(item))) {
              bootbox.alert('The monthly input must contain only numeric values. Could not parse value "' + item + '"');
              return false;
            } else {
              if (type === 'electric') {
                self.$store.commit('updateValueNested2', {
                  association_1: 'site',
                  association_2: 'load_profile',
                  attribute: `energy_use_monthly_${i + month}`,
                  value: item
                });
              } else {
                self.$store.commit('updateValueNested3', {
                  association_1: 'site',
                  association_2: 'load_profile',
                  association_3: association3Name,
                  attribute: attrBaseName + `_${i + month}`,
                  value: item
                });
              }
            }
          });
        } else {
          // for single input paste
          if (type === 'electric') {
            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile',
              attribute: attributeName,
              value: clipValue
            });
          } else {
            self.$store.commit('updateValueNested3', {
              association_1: 'site',
              association_2: 'load_profile',
              association_3: association3Name,
              attribute: attributeName,
              value: clipValue
            });
          }
        }

        if (type === 'electric') {
          this.getElecSimBuildingMonthlyLoad();
        } else if (type === 'thermal' || type === 'thermal-percent') {
          this.getThermalSimBuildingMonthlyLoad();
        } else if (type === 'cold') {
          this.getColdSimBuildingMonthlyLoad();
        } else if (type === 'cold-percent') {
          this.getColdCustomMonthlyPctLoad();
        }
      }
    },

    watch: {
      watchDependencies: function() {
        var type = this.run.site.load_profile.typical_load_type;
        if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual") {
          this.updateAnnualKwh();
        } else if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "monthly") {
          this.getElecSimBuildingMonthlyLoad();
        }
        // if no current_user, need to remove the user input load profile year when toggle from uploaded to simulate building or campus
        // or the outage dates will not update automatically 
        if ((type == "simulated_building" || type == "simulated_campus") && this.run.site.load_profile.year) {
          this.run.site.load_profile.year = null;
        }
        if (this.run.analyze_chp || this.run.analyze_ghp) {
          var heating_type = this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type;
          if(heating_type == "simulated" && this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == "annual") {
            this.updateAnnualMMBtu();
          } else if(heating_type == "simulated" && this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == "monthly") {
            this.getThermalSimBuildingMonthlyLoad();
          }
        }
        if (this.run.analyze_ghp || (this.run.site.chp && this.run.site.chp.analyze_abs_chiller) || this.run.analyze_cws) {
          this.getColdSimulatedLoadProfile();
        }
      },
      'run.site.load_profile.load_profile_chiller_thermal.custom_energy_consump': function() {
        if (!$('#custom_energy_consumption')[0].checked) {
          this.getColdSimulatedLoadProfile();
          if (this.run.site.load_profile.load_profile_chiller_thermal.annual_tonhour) {
            this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'load_profile', association_3: 'load_profile_chiller_thermal', attribute: 'annual_tonhour', value: '' })
          }
        } else if ($('#custom_energy_consumption')[0].checked && this.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == 'monthly') {
          this.getColdSimBuildingMonthlyLoad();
        }
      },
    },

    mounted: function() {
      var self = this;

      // definitely not pretty but not certain how to style pips without digging
      // into package's css file :/
      // this is also why the click events are needed to be added after html added
      var pip50 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='50' style='left:25%;'>50</div>";
      var pip50Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:25%;'></div>";
      var pip100 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='200' style='left:50%;'>100</div>";
      var pip100Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:50%;'></div>";
      var pip150 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='150' style='left:75%;'>150</div>";
      var pip150Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:75%;'></div>";
      document.getElementById("load-reduction-slider").getElementsByClassName("noUi-pips-horizontal")[0].innerHTML += ((pip50 + pip50Marker) + (pip100 + pip100Marker) + (pip150 + pip150Marker));

      // this is to make the pip markers clickable for setting 'load_reduction' value
      for(var el of document.getElementById("load-reduction-slider").getElementsByClassName('noUi-value')) {
        el.addEventListener('click', function(event) {
          self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
          var slider = document.getElementById("load-reduction-slider");
          slider.noUiSlider.set(event.target.innerText);
          self.updateStore('load_reduction', event.target.innerText);

          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }, 800);
        })
      }

      // not ideal although if we use a watch function on `load_reduction`
      // the slider causes the value to change WHILE sliding - leading to a weird
      // UI experience - on mouseup is much cleaner
      document.getElementById("load-reduction-slider").getElementsByClassName("noUi-handle")[0].addEventListener('mouseup', function() {
        self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        if (self.run.site.load_profile.simulated_load_type == "annual") {
          self.updateAnnualKwhValueFromSlider();
        } else if (self.run.site.load_profile.simulated_load_type == "monthly") {
          self.updateMonthlyKwhValuesFromSlider();
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
        }, 800);
      })

      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if(this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.annual_kwh) {
        document.querySelectorAll("[href='#simulated_building']")[0].click();
        this.getElecSimBuildingAnnualLoad();
      } else if(this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "monthly") {
        document.querySelectorAll("[href='#simulated_building']")[0].click();
        this.getElecSimBuildingMonthlyLoad();
      } else if(this.run.site.load_profile.typical_load_type == "simulated_campus") {
        document.querySelectorAll("[href='#simulated_campus']")[0].click();
      } else if(this.run.site.load_profile.typical_load_type == "uploaded") {
        this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile.loads_kw), 'electric');
        document.querySelectorAll("[href='#uploaded']")[0].click();
      }

      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if(this.run.analyze_chp || this.run.analyze_ghp) {
        if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == "simulated" && this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == "annual") {
          document.querySelectorAll("[href='#thermal_load_profile_simulated_inputs']")[0].click();
          this.getThermalSimBuildingAnnualLoad();
        } else if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == "simulated" && this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == "monthly") {
          document.querySelectorAll("[href='#thermal_load_profile_simulated_inputs']")[0].click();
          this.getThermalSimBuildingMonthlyLoad();
        } else if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == "simulated_campus") {
          document.querySelectorAll("[href='#thermal_simulated_campus']")[0].click();
        } else if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == "uploaded") {
          this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile.load_profile_boiler_fuel.loads_mmbtu_per_hour), 'thermal');
          document.querySelectorAll("[href='#thermal_load_profile_custom_inputs']")[0].click();
        }
      }

      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if(this.run.analyze_cws || this.run.analyze_ghp || (this.run.site.chp && this.run.site.chp.analyze_abs_chiller)) {
        if(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "simulated" && this.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == "annual") {
          document.querySelectorAll("[href='#cold_load_profile_simulated_inputs']")[0].click();
          this.getColdSimBuildingAnnualLoad();
        } else if(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "simulated" && this.run.site.load_profile.load_profile_chiller_thermal.simulated_load_type == "monthly") {
          document.querySelectorAll("[href='#cold_load_profile_simulated_inputs']")[0].click();
          this.getColdSimBuildingMonthlyLoad();
        } else if(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "simulated_campus") {
          document.querySelectorAll("[href='#cold_simulated_campus']")[0].click();
        } else if(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "uploaded") {
          this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile.load_profile_chiller_thermal.thermal_loads_ton), 'cold');
          document.querySelectorAll("[href='#cold_load_profile_uploaded']")[0].click();
        } else if(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_type == "custom") {
          this.getColdCustomAnnualPctLoad();
          document.querySelectorAll("[href='#cold_load_profile_custom']")[0].click();
        }
      }

      // for copied runs
      if(this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual") {
        this.updateAnnualKwh();
      }
      
      if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == "simulated" && this.run.site.load_profile.load_profile_boiler_fuel.simulated_load_type == "annual") {
        this.updateAnnualMMBtu();
      }
      
      if(this.run.site.load_profile.typical_load_file_data) {
        document.getElementById("run_site_attributes_load_profile_attributes_typical_load_file").value = this.run.site.load_profile.typical_load_file_data;

        var loadKw = JSON.parse(this.run.site.load_profile.loads_kw)
        var payload = {
          loadsKw: loadKw,
          minKw: Math.min.apply(Math, loadKw),
          meanKw: this.$store.getters.average(loadKw),
          maxKw: Math.max.apply(Math, loadKw),
        }

        this.$store.commit("updateObject", { object: "elecUploadedLoadProfile", value: payload });
      }

      if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_file_data) {
        document.getElementById("run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_thermal_load_file").value = this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_file_data;

        var loadMMBtu = JSON.parse(this.run.site.load_profile.load_profile_boiler_fuel.loads_mmbtu_per_hour)
        var payload = {
          loadsMMBtu: loadMMBtu,
          minMMBtu: Math.min.apply(Math, loadMMBtu),
          meanMMBtu: this.$store.getters.average(loadMMBtu),
          maxMMBtu: Math.max.apply(Math, loadMMBtu),
        }

        this.$store.commit("updateObject", { object: "thermalUploadedLoadProfile", value: payload });
      }

      if(this.run.site.load_profile.load_profile_chiller_thermal.cold_load_file_data) {
        document.getElementById("run_site_attributes_load_profile_attributes_load_profile_chiller_thermal_attributes_cold_load_file").value = this.run.site.load_profile.load_profile_chiller_thermal.cold_load_file_data;
        // this loadTonHour variable is pointing to the wrong values. I think it should be: this.run.site.load_profile.load_profile_chiller_thermal.cold_uploaded_loads_ton
        // but check when GHP is hooked up
        var loadTonHour = JSON.parse(this.run.site.load_profile.loads_kw)
        // var loadTonHour = JSON.parse(this.run.site.load_profile.load_profile_chiller_thermal.cold_uploaded_loads_ton)
        var payload = {
          loadsTonHour: loadTonHour,
          minTonHour: Math.min.apply(Math, loadTonHour),
          meanTonHour: this.$store.getters.average(loadTonHour),
          maxTonHour: Math.max.apply(Math, loadTonHour),
        }

        this.$store.commit("updateObject", { object: "coldUploadedLoadProfile", value: payload });
        this.updateAbsChillerDefaults();
      }
    }
  }
</script>

<style>
  .noUi-handle-lower {
    background-color: rgb(61,111,189);
    box-shadow: none;
  }
</style>
