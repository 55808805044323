<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_installed_cost_per_kwh">
              {{ labels.site.storage.installed_cost_per_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="installed_cost_per_kwh" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kwh'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kwh'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_installed_cost_per_kw">
              {{ labels.site.storage.installed_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="installed_cost_per_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_can_grid_charge">
              {{ labels.site.storage.can_grid_charge }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="can_grid_charge" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_can_grid_charge">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='can_grid_charge'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_storage_can_grid_charge">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='can_grid_charge'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_min_kwh">
              {{ labels.site.storage.min_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="min_kwh" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kwh'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kwh'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_max_kwh">
              {{ labels.site.storage.max_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="max_kwh" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kwh'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kwh'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Advanced inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Battery Costs</h4>
      </td>
    </tr>
    <br>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_replace_cost_per_kwh">
              {{ labels.site.storage.replace_cost_per_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="replace_cost_per_kwh" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kwh'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kwh'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_battery_replacement_year">
              {{ labels.site.storage.battery_replacement_year }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="battery_replacement_year" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='battery_replacement_year'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='battery_replacement_year'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_replace_cost_per_kw">
              {{ labels.site.storage.replace_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="replace_cost_per_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_inverter_replacement_year">
              {{ labels.site.storage.inverter_replacement_year }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="inverter_replacement_year" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_replacement_year'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_replacement_year'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Battery Characteristics</h4>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_min_kw">
              {{ labels.site.storage.min_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="min_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_max_kw">
              {{ labels.site.storage.max_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="max_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_rectifier_efficiency_fraction">
              {{ labels.site.storage.rectifier_efficiency_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="rectifier_efficiency_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='rectifier_efficiency_fraction'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="updateRoundTripEfficiency()" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='rectifier_efficiency_fraction'
          :run="portfolioRun"
          v-on:input-change="updateRoundTripEfficiency()" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_internal_efficiency_fraction">
              {{ labels.site.storage.internal_efficiency_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="internal_efficiency_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='internal_efficiency_fraction'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="updateRoundTripEfficiency()" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='internal_efficiency_fraction'
          :run="portfolioRun"
          v-on:input-change="updateRoundTripEfficiency()" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_inverter_efficiency_fraction">
              {{ labels.site.storage.inverter_efficiency_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="inverter_efficiency_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_efficiency_fraction'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="updateRoundTripEfficiency()" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_efficiency_fraction'
          :run="portfolioRun"
          v-on:input-change="updateRoundTripEfficiency()" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_ac_ac_efficiency">
            Total AC-AC round trip efficiency
            <tooltip :association_1="'site'" :association_2="'storage'" :attribute="'ac_ac_efficiency'"></tooltip>
          </label>
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <p class="form-control-static">{{ roundTripEfficiency.toFixed(1) }}%</p>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <p class="form-control-static">{{ roundTripEfficiency.toFixed(1) }}%</p>
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_soc_min_fraction">
              {{ labels.site.storage.soc_min_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="soc_min_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_min_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_min_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_soc_init_fraction">
              {{ labels.site.storage.soc_init_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="soc_init_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_init_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_init_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Battery Incentives and Tax Treatment</h4>
        <h5>Capital Cost Based Incentives</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_total_itc_fraction">
              {{ labels.site.storage.total_itc_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="total_itc_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_itc_fraction'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_itc_fraction'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_total_rebate_per_kw">
              {{ labels.site.storage.total_rebate_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="total_rebate_per_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_rebate_per_kw'
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_rebate_per_kw'
          :run="portfolioRun" />
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5>Tax Treatment</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_macrs_option_years">
              {{ labels.site.storage.macrs_option_years }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="macrs_option_years" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_macrs_option_years">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_option_years'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_storage_macrs_option_years">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_option_years'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_macrs_bonus_fraction">
              {{ labels.site.storage.macrs_bonus_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="macrs_bonus_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_macrs_bonus_fraction">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun"
            :index="index" />
        </div>
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div class="form-group select optional run_site_storage_macrs_bonus_fraction">
          <portfolio-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun" />
        </div>
      </td> -->
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';
  import PortfolioSelectInputNested2 from './inputs/PortfolioSelectInputNested2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
      PortfolioSelectInputNested2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
        roundTripEfficiency: 89.9,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'selectOptions',
        'year',
        'portfolioRun',
        'portfolio'
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },

      updateRoundTripEfficiency: function() {
        var rectifier = (this.portfolioRun.site.storage.rectifier_efficiency_fraction / 100.0) || 0.96
        var internal = (this.portfolioRun.site.storage.internal_efficiency_fraction / 100.0) || 0.975
        var inverter = (this.portfolioRun.site.storage.inverter_efficiency_fraction / 100.0) || 0.96
        this.roundTripEfficiency = rectifier * internal * inverter * 100;
      },

      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'storage',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },
    },

    watch: {
      'portfolioRun.site.storage.installed_cost_per_kwh': function() {
        this.updateInputValueForRuns('installed_cost_per_kwh', this.portfolioRun.site.storage.installed_cost_per_kwh);
      },
      'rportfolioRunun.site.storage.installed_cost_per_kw': function() {
        this.updateInputValueForRuns('installed_cost_per_kw', this.portfolioRun.site.storage.installed_cost_per_kw);
      },
      'portfolioRun.site.storage.can_grid_charge': function() {
        this.updateInputValueForRuns('can_grid_charge', this.portfolioRun.site.storage.can_grid_charge);
      },
      'portfolioRun.site.storage.min_kwh': function() {
        this.updateInputValueForRuns('min_kwh', this.portfolioRun.site.storage.min_kwh);
      },
      'portfolioRun.site.storage.max_kwh': function() {
        this.updateInputValueForRuns('max_kwh', this.portfolioRun.site.storage.max_kwh);
      },
      'portfolioRun.site.storage.replace_cost_per_kwh': function() {
        this.updateInputValueForRuns('replace_cost_per_kwh', this.portfolioRun.site.storage.replace_cost_per_kwh);
      },
      'portfolioRun.site.storage.battery_replacement_year': function() {
        this.updateInputValueForRuns('battery_replacement_year', this.portfolioRun.site.storage.battery_replacement_year);
      },
      'portfolioRun.site.storage.replace_cost_per_kw': function() {
        this.updateInputValueForRuns('replace_cost_per_kw', this.portfolioRun.site.storage.replace_cost_per_kw);
      },
      'portfolioRun.site.storage.inverter_replacement_year': function() {
        this.updateInputValueForRuns('inverter_replacement_year', this.portfolioRun.site.storage.inverter_replacement_year);
      },
      'portfolioRun.site.storage.min_kw': function() {
        this.updateInputValueForRuns('min_kw', this.portfolioRun.site.storage.min_kw);
      },
      'portfolioRun.site.storage.max_kw': function() {
        this.updateInputValueForRuns('max_kw', this.portfolioRun.site.storage.max_kw);
      },
      'portfolioRun.site.storage.rectifier_efficiency_fraction': function() {
        this.updateInputValueForRuns('rectifier_efficiency_fraction', this.portfolioRun.site.storage.rectifier_efficiency_fraction);
      },
      'portfolioRun.site.storage.internal_efficiency_fraction': function() {
        this.updateInputValueForRuns('internal_efficiency_fraction', this.portfolioRun.site.storage.internal_efficiency_fraction);
      },
      'portfolioRun.site.storage.inverter_efficiency_fraction': function() {
        this.updateInputValueForRuns('inverter_efficiency_fraction', this.portfolioRun.site.storage.inverter_efficiency_fraction);
      },
      'portfolioRun.site.storage.soc_min_fraction': function() {
        this.updateInputValueForRuns('soc_min_fraction', this.portfolioRun.site.storage.soc_min_fraction);
      },
      'portfolioRun.site.storage.soc_init_fraction': function() {
        this.updateInputValueForRuns('soc_init_fraction', this.portfolioRun.site.storage.soc_init_fraction);
      },
      'portfolioRun.site.storage.total_itc_fraction': function() {
        this.updateInputValueForRuns('total_itc_fraction', this.portfolioRun.site.storage.total_itc_fraction);
      },
      'portfolioRun.site.storage.total_rebate_per_kw': function() {
        this.updateInputValueForRuns('total_rebate_per_kw', this.portfolioRun.site.storage.total_rebate_per_kw);
      },
      'portfolioRun.site.storage.macrs_option_years': function() {
        this.updateInputValueForRuns('macrs_option_years', this.portfolioRun.site.storage.macrs_option_years);
      },
      'portfolioRun.site.storage.macrs_bonus_fraction': function() {
        this.updateInputValueForRuns('macrs_bonus_fraction', this.portfolioRun.site.storage.macrs_bonus_fraction);
      },
    },
  }
</script>