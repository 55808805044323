<template>
  <div class="form-horizontal">
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='chilled_water_storage'
      attribute='min_gal' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='chilled_water_storage'
      attribute='max_gal' />
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='chilled_water_storage'
      attribute='installed_cost_per_gal' />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>Chilled Water Storage System Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='cool_water_temp_degF' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='hot_water_temp_degF' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='om_cost_per_gal' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='thermal_decay_rate_fraction' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='soc_min_fraction' />
      </div>

      <h4>Chilled Water Storage Incentives and Tax Treatment</h4>
      <h5>Capital Cost Based Incentives</h5>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='total_itc_fraction' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='total_rebate_per_kwh' />
      </div>

      <h5>Tax Treatment</h5>
      <div class="form-horizontal">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='macrs_option_years' />
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='chilled_water_storage'
          attribute='macrs_bonus_fraction' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import Tooltip from './Tooltip'

  export default {
    components: {
      // nested 3 b/c run.site.chp.hot_water_storage - measured from run
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      Tooltip
    },

    data: function() {
      return {
        displayAdvancedInputs: false
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'selectOptions'
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      resetInputs: function(event) {
        if(confirm("Are you sure you want to reset the inputs in the Chilled Water Storage section to their default values?")) {
          if(this.run.site.chilled_water_storage.min_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'min_gal', value: '' });
          if(this.run.site.chilled_water_storage.max_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'max_gal', value: '' });
          if(this.run.site.chilled_water_storage.installed_cost_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'installed_cost_per_gal', value: '' });
          if(this.run.site.chilled_water_storage.cool_water_temp_degF) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'cool_water_temp_degF', value: '' });
          if(this.run.site.chilled_water_storage.hot_water_temp_degF) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'hot_water_temp_degF', value: '' });
          if(this.run.site.chilled_water_storage.om_cost_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'om_cost_per_gal', value: '' });
          if(this.run.site.chilled_water_storage.thermal_decay_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'thermal_decay_rate_fraction', value: '' });
          if(this.run.site.chilled_water_storage.soc_min_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'soc_min_fraction', value: '' });
          if(this.run.site.chilled_water_storage.total_itc_fraction) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'total_itc_fraction', value: '' });
          if(this.run.site.chilled_water_storage.total_rebate_per_kwh) this.$store.commit({type: 'updateValueNested3', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'total_rebate_per_kwh', value: '' });
          if(this.run.site.chilled_water_storage.macrs_option_years != 7) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'macrs_option_years', value: 7 });
          if(this.run.site.chilled_water_storage.macrs_bonus_fraction != 0.8) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chilled_water_storage', attribute: 'macrs_bonus_fraction', value: 0.6 });

          window.scrollTo(0, document.getElementById('cws_heading').offsetTop);
        }
      }
    }
  }
</script>
