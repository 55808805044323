<template>
  <div class="form-group select optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="select optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip :attribute="this.attribute" />
    </div>
    <div class="col-sm-4">
      <select
        v-model="run[attribute]"
        @change="$emit('input-change')"
        class="form-control select optional"
        :name="inputName"
        :id="inputID">
        <option v-for="option in options" :value="option['value']">
          {{ option['label'] }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'attribute',
      'required',
    ],

    data: function() {
      return {
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'labels',
        'run',
        'selectOptions',
        'tooltips'
      ]),
      label: function() {
        return this.labels[this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.attribute}]`;
      },
      options: function() {
        return this.selectOptions[this.attribute]["options"];
      }
    }
  }
</script>
