<template>
  <div class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="closeLoadProfile()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Heating Boiler Fuel Load Profile Based on Your Inputs</h4>
          </div>
          <div class="modal-body">
            <highcharts :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
            <div class="row row-flex text-center chart-stats">
              <div class="col form-control-static"><strong>Avg Heating Load:</strong> {{ meanMMBtu.toFixed(2) }} MMBtu/hr</div>
              <div class="col form-control-static"><strong>Min Heating Load:</strong> {{ minMMBtu.toFixed(2) }} MMBtu/hr ({{ minMMBtuDate }})</div>
              <div class="col form-control-static"><strong>Max Heating Load:</strong> {{ maxMMBtu.toFixed(2) }} MMBtu/hr ({{ maxMMBtuDate }})</div>
            </div>
            <div v-if="run.site.load_profile.load_profile_boiler_fuel.thermal_load_type != 'uploaded'" >
              <div class="row row-flex text-center chart-stats">
                <div class="col form-control-static"><strong>Avg Space Heating Load:</strong> {{ spaceMeanMMBtu.toFixed(2) }} MMBtu/hr</div>
                <div class="col form-control-static"><strong>Min Space Heating Load:</strong> {{ spaceMinMMBtu.toFixed(2) }} MMBtu/hr</div>
                <div class="col form-control-static"><strong>Max Space Heating Load:</strong> {{ spaceMaxMMBtu.toFixed(2) }} MMBtu/hr</div>
              </div>
              <div class="row row-flex text-center chart-stats">
                <div class="col form-control-static"><strong>Avg DHW Load:</strong> {{ dhwMeanMMBtu.toFixed(2) }} MMBtu/hr</div>
                <div class="col form-control-static"><strong>Min DHW Load:</strong> {{ dhwMinMMBtu.toFixed(2) }} MMBtu/hr</div>
                <div class="col form-control-static"><strong>Max DHW Load:</strong> {{ dhwMaxMMBtu.toFixed(2) }} MMBtu/hr</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [ 'loadType' ],

    data: function() {
      return {
        chartOptions: {
          credits: {
            enabled: false
          },
          yAxis: {
            title: {
              text: 'Heating Load (MMBtu/hr)',
            },
            plotLines: [{
              color: 'green',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Minimum Load',
              },
            }, {
              color: 'black',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Average Load',
              },
            }, {
              color: 'red',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Maximum Load',
              },
            }]
          },
          series: [
            {
              name: 'Heating Load',
              color: 'black',
              lineWidth: 1.8,
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' MMBtu/hr'
              }
            },
            {
              name: 'Space Heating Load',
              color: "rgb(83, 194, 235)",
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' MMBtu/hr'
              }
            },
            {
              name: 'DHW Load',
              color: "rgb(52, 152, 77)",
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' MMBtu/hr'
              }
            }
          ]
        }
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'thermalSimBuildAnnualLoadProfile',
        'thermalSimBuildMonthlyLoadProfile',
        'thermalSimCampusLoadProfile',
        'thermalUploadedLoadProfile',
        'months',
        'year'
      ]),
      valueInterval: function() {
        // Based on how big the series is, determine how much time each
        // value represents.
        var interval = 60 * 60 * 1000; // 1 hour
        if(this[this.loadType].loadsMMBtu.length == 17520) {
          interval = 30 * 60 * 1000; // 30 minutes
        } else if(this[this.loadType].loadsMMBtu.length == 35040) {
          interval = 15 * 60 * 1000; // 15 minutes
        }

        return interval
      },
      minMMBtu: function() {
        return this[this.loadType].minMMBtu;
      },
      maxMMBtu: function() {
        return this[this.loadType].maxMMBtu;
      },
      meanMMBtu: function() {
        return this[this.loadType].meanMMBtu;
      },
      spaceMinMMBtu: function() {
        return this[this.loadType].spaceMinMMBtu;
      },
      spaceMaxMMBtu: function() {
        return this[this.loadType].spaceMaxMMBtu;
      },
      spaceMeanMMBtu: function() {
        return this[this.loadType].spaceMeanMMBtu;
      },
      dhwMinMMBtu: function() {
        return this[this.loadType].dhwMinMMBtu;
      },
      dhwMaxMMBtu: function() {
        return this[this.loadType].dhwMaxMMBtu;
      },
      dhwMeanMMBtu: function() {
        return this[this.loadType].dhwMeanMMBtu;
      },
      minMMBtuDate: function() {
        var minIndex = this[this.loadType].loadsMMBtu.indexOf(this.minMMBtu);
        var startDate = new Date(this.year, 0, 1);
        var minDate = new Date(startDate.getTime() + minIndex * this.valueInterval);

        return `${this.months[minDate.getMonth()].substring(0,3)} ${minDate.getDate()}`;
      },
      maxMMBtuDate: function() {
        var maxIndex = this[this.loadType].loadsMMBtu.indexOf(this.maxMMBtu);
        var startDate = new Date(this.year, 0, 1);
        var maxDate = new Date(startDate.getTime() + maxIndex * this.valueInterval);

        return `${this.months[maxDate.getMonth()].substring(0,3)} ${maxDate.getDate()}`;
      },
      absoluteMin: function() {
        return Math.min.apply(Math, [this.minMMBtu, this.spaceMinMMBtu, this.dhwMinMMBtu]);
      },
      absoluteMax: function() {
        return Math.max.apply(Math, [this.maxMMBtu, this.spaceMaxMMBtu, this.dhwMaxMMBtu]);
      }
    },

    methods: {
      closeLoadProfile: function() {
        if(this.loadType == "thermalSimBuildAnnualLoadProfile") {
          var object = "showThermalSimBuildAnnualLoadProfile";
        } else if(this.loadType == "thermalSimBuildMonthlyLoadProfile") {
          var object = "showThermalSimBuildMonthlyLoadProfile";
        } else if(this.loadType == "thermalSimCampusLoadProfile") {
          var object = "showThermalSimCampusLoadProfile";
        } else if(this.loadType == "thermalUploadedLoadProfile") {
          var object = "showThermalUploadedLoadProfile";
        }

        this.$store.commit('updateObject', { object: object, value: false });
      }
    },

    mounted: function() {
      this.chartOptions.yAxis.plotLines[0].value = this[this.loadType].minMMBtu;
      this.chartOptions.yAxis.plotLines[1].value = this[this.loadType].meanMMBtu;
      this.chartOptions.yAxis.plotLines[2].value = this[this.loadType].maxMMBtu;

      this.chartOptions.series[0].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[0].data = this[this.loadType].loadsMMBtu;

      if(this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type == "uploaded") {
        // we don't show space and heating when user uploads a thermal load profile
        this.chartOptions.series[0].color = "rgb(83, 194, 235)";
        this.chartOptions.yAxis.min = this.minMMBtu;
        this.chartOptions.yAxis.max = this.maxMMBtu;

        this.chartOptions.series = [ this.chartOptions.series[0] ];
      } else {
        this.chartOptions.yAxis.min = this.absoluteMin;
        this.chartOptions.yAxis.max = this.absoluteMax;

        this.chartOptions.series[1].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
        this.chartOptions.series[1].data = this[this.loadType].spaceLoadsMMBtu;

        this.chartOptions.series[2].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
        this.chartOptions.series[2].data = this[this.loadType].dhwLoadsMMBtu;
      }
    }
  }
</script>
