<template>
  <tbody>
    <tr>
      <td class="col-sm-3">
        <p class="required-note" style="text-align: left;">
          <abbr class="required">*</abbr> Required field
        </p>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_description">
            {{ labels.site.description }}
          </label>
          <tooltip
            association_1="site"
            attribute="description" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='description'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <br><br>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_address">
            <abbr title="Required" class="required">*</abbr>
            {{ labels.site.address }}
          </label>
          <tooltip
            association_1="site"
            attribute="address" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='address'
          :index="index"
          :run="run"
          required=true
          @input-change="updateLatLong(index)"
          @focus="setupLocationAutocomplete()" />
        <input
          v-model="run.site.latitude"
          type="hidden"
          name="run[site_attributes][latitude]"
          :id="`run_site_attributes_latitude_${index + 1}`" />
        <input
          v-model="run.site.longitude"
          type="hidden"
          name="run[site_attributes][longitude]"
          :id="`run_site_attributes_longitude_${index + 1}`" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="grouped_select required" for="run_site_attributes_electric_tariff_attributes_urdb_label">
            <abbr title="Required" class="required">*</abbr>
            Choose Electricity Rate Type
          </label>
        </div>
      </th>
      <td colspan="2" class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed; text-align: left;">
        <div class="custom-elec-rate-options">
          <!-- if v-model is using portfolioRun, will ruby still associate that value with the correct this if run is being used? -->
          <input
            v-model="portfolioRun.site.electric_tariff.custom_rate_type"
            type="radio"
            value="urdb_label"
            name="run[site_attributes][electric_tariff_attributes][custom_rate_type]"
            id="run_site_attributes_electric_tariff_attributes_custom_rate_type_urdb_label" />
          <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_urdb_label">URDB Dropdown</label>
          <input
            v-model="portfolioRun.site.electric_tariff.custom_rate_type"
            type="radio"
            value="annual"
            name="run[site_attributes][electric_tariff_attributes][custom_rate_type]"
            id="run_site_attributes_electric_tariff_attributes_custom_rate_type_annual" />
          <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_custom_rate_type_annual">Annual</label>
        </div>
      </td>
    </tr>
    <br>
    <tr v-if="portfolioRun.site.electric_tariff.custom_rate_type == 'urdb_label'">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="grouped_select required" for="run_site_attributes_electric_tariff_attributes_urdb_label">
            <abbr title="Required" class="required">*</abbr>
            Electricity rate
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="urdb_label" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-select-elec-rate
          v-if="run.grid"
          ref="portfolio-elec-rate"
          :run="run"
          :index="index"/>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.custom_rate_type == 'annual'">
      <th class="col-sm-3"></th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="annual-custom-entry">
          <div class="annual-cost-column">
            <p>Energy Cost ($/kWh)</p>
            <input v-model="run.site.electric_tariff.blended_annual_energy_rate" label="false" class="numeric decimal optional form-control" type="number" step="any" name="run[site_attributes][electric_tariff_attributes][blended_annual_energy_rate]" />
          </div>
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.custom_rate_type == 'annual'">
      <th class="col-sm-3"></th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="annual-custom-entry">
          <div class="annual-cost-column">
            <p>Demand Cost ($/kW/month)</p>
            <input v-model="run.site.electric_tariff.blended_annual_demand_rate" label="false" class="numeric decimal optional form-control" type="number" step="any" name="run[site_attributes][electric_tariff_attributes][blended_annual_demand_rate]" />
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Optional inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Location</h4>
      </td>
    </tr>
    <br>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_land_acres">
              {{ labels.site.land_acres }}
          </label>
          <tooltip
            association_1="site"
            attribute="land_acres" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='land_acres'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_roof_squarefeet">
              {{ labels.site.roof_squarefeet }}
          </label>
          <tooltip
            association_1="site"
            attribute="roof_squarefeet" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='roof_squarefeet'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Electrical <tooltip :association_1="'site'" :association_2="'headers'" :attribute="'electrical'"></tooltip></h4>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_electric_tariff_net_metering_limit_kw">
              {{ labels.site.electric_tariff.net_metering_limit_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='electric_tariff'
            attribute="net_metering_limit_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='net_metering_limit_kw'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_optimality_tolerance_techs">
            Technologies that can net meter <tooltip :attribute="'can_net_meter'"></tooltip>
          </label>
        </div>
      </th>
      <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <div id="pv-net-meter">
          <input
            type="hidden"
            name="run[site_attributes][pv_attributes][can_net_meter]"
            value="0"
            :disabled="!portfolioRun.analyze_pv" />
          <input
            v-model="portfolioRun.site.pv.can_net_meter"
            type="checkbox"
            name="run[site_attributes][pv_attributes][can_net_meter]"
            value="1"
            id="run_site_attributes_pv_attributes_can_net_meter"
            :disabled="!portfolioRun.analyze_pv" />
          <label style="font-weight: normal" for="run_site_attributes_pv_attributes_can_net_meter">PV</label><br />
        </div>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_electric_tariff_wholesale_rate">
              {{ labels.site.electric_tariff.wholesale_rate }}
          </label>
          <tooltip
            association_1="site"
            association_2='electric_tariff'
            attribute="wholesale_rate" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='wholesale_rate'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Solver settings</h4>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_optimality_tolerance_techs">
            {{ labels.optimality_tolerance_techs }}
          </label>
          <tooltip
            attribute="optimality_tolerance_techs" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input
          attribute="optimality_tolerance_techs"
          :run="portfolioRun"
          :index="index" />
      </td>
      <!-- <td class="col-sm-3" style="position: sticky; left: 380px; z-index: 1; background: #fdf4ed;">
        <portfolio-horizontal-string-input
          attribute="optimality_tolerance_techs"
          :run="portfolioRun" />
      </td> -->
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioSelectElecRate from './inputs/PortfolioSelectElecRate';
  import PortfolioHorizontalStringInput from './inputs/PortfolioHorizontalStringInput.vue';
  import PortfolioHorizontalStringInputNested1 from './inputs/PortfolioHorizontalStringInputNested1.vue';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2.vue';

  export default {
    components: {
      Tooltip,
      PortfolioSelectElecRate,
      PortfolioHorizontalStringInput,
      PortfolioHorizontalStringInputNested1,
      PortfolioHorizontalStringInputNested2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'year',
        'portfolioRun',
        'portfolio',
        'runsUtilityRatesLoading'
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },

      setupLocationAutocomplete: function() {
        var self = this;
        if (self.portfolio.runs) {
          self.portfolio.runs.forEach(function(run, i) {
            var runId = `run_site_attributes_address_${i + 1}`
            var locationInput = document.getElementById(runId);
            if(locationInput) {
              var autocomplete = new google.maps.places.Autocomplete(locationInput, {
                types: ['geocode'],
              });
              autocomplete.addListener('place_changed', function selectAddress() {
                var place = autocomplete.getPlace();
                if(!place.geometry) {
                  return;
                }
                self.$store.commit('updateRunsValues', {
                  association_1: 'site',
                  attribute: 'address',
                  index: i,
                  value: place.formatted_address
                })
                self.$store.commit('updateRunsValues', {
                  association_1: 'site',
                  attribute: 'latitude',
                  index: i,
                  value: place.geometry.location.lat()
                })
                self.$store.commit('updateRunsValues', {
                  association_1: 'site',
                  attribute: 'longitude',
                  index: i,
                  value: place.geometry.location.lng()
                })

                if (run.site.load_profile.doe_reference_name) {
                  self.updateAnnualKwh(run, i);
                }
                // self.updateGHPGroundConductivity();

                // TODO: fix weird Vue reactivity issue
                // for w/e reason, without the value setting below, Vue will update the
                // data model, i.e. `run.site.latitude` but not the input UNTIL some JS has
                // been performed in this component (for instance clicking the advanced inputs).
                // This will THEN update the input to be consistent with the data model
                document.getElementById(`run_site_attributes_latitude_${i + 1}`).value = place.geometry.location.lat();
                document.getElementById(`run_site_attributes_longitude_${i + 1}`).value = place.geometry.location.lng();
                // self.updateEmissionsDefaults();
                // self.updateHealthEmissionsDefaults();
                // self.$emit('update-ghp-defaults');

                if(run.grid) {
                  self.$refs['portfolio-elec-rate'][i].fetchUtilityRates(run, i);
                }
              });

              // When "enter" is hit and the autocomplete results are up, only select the
              // item (don't submit the form).
              google.maps.event.addDomListener(locationInput, 'keydown', function(event) {
                if(event.keyCode === 13 && $('.pac-container:visible').length) {
                  event.preventDefault();
                }
              });
            }
          })
        }
      },

      updateLatLong: function(index) {
        if(this.portfolio.runs[index].site.address == "") {
          this.portfolio.runs[index].site.latitude = "";
          this.portfolio.runs[index].site.longitude = "";
        }
      },

      // hack for now as not sure how to put a watcher on a specific run in runs
      updateAnnualKwh: function(run, index) {
        if(run.site.load_profile.simulated_load_type != 'annual') {
          return false;
        }

        if(run.site.address) {
          var self = this;

          $.ajax({
            url: self.urlRoot + '/annual-kwh',
            contentType: 'application/json',
            data: {
              latitude: run.site.latitude,
              longitude: run.site.longitude,
              doe_reference_name: run.site.load_profile.doe_reference_name,
            },
            success: function handleResults(data) {
              // if(!run.site.load_profile.annual_kwh) {
              //   self.$refs.annualKwh[index].toggleDisplayDefault();
              // }

              self.$store.commit('updateRunsPlaceholder', {
                association_1: 'site',
                association_2: 'load_profile',
                attribute: 'annual_kwh',
                index: index,
                value: self.$store.getters.formatAsIntegerWithCommas({ value: data.toFixed(0) })
              });
              // self.getElecSimBuildingAnnualLoad(run, index);
            },
            error: function() {
              return false;
            },
          })
        }
      },

      updateOptimalityToleranceForRuns: function() {
        var self = this;

        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            attribute: "optimality_tolerance_techs",
            value: self.portfolioRun.optimality_tolerance_techs,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      updateInputValueForRuns: function(attr, association_2, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: association_2,
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      fetchUtilityRates: function(run = null, index = null) {
      // how on earth do I relate this update
        // this.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: true, index: this.index });
        this.$store.commit('updateObject', { object: 'utilityRatesLoading', value: true });
        var self = this;
        $.ajax({
          url: self.urlRoot + '/utility-rates',
          data: {
            latitude: run.site.latitude,
            longitude: run.site.longitude,
            location: run.site.address
          },
          // data: data,
          success: function handleResults(data) {
            self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRates', value: data, index: index });
            // self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: false, index: self.index });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });
            // self.runsUtilityRatesLoading = false
            self.checkedForRates = true;
          },
          error: function() {
            self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRates', value: [], index: self.index });
            self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: false, index: self.index });
          //   self.runsUtilityRatesLoading = false

            alert('An unexpected error occurred while fetching the utility rates. Please try again or contact us for assistance.');
          },
        })
      },
      // resetInputs: function() {
      //   if(confirm("Are you sure you want to reset the inputs in the entire form to their default values?")) {
      //     window.location = this.urlRoot;
      //   }
      // },
    },

    watch: {
      'portfolioRun.optimality_tolerance_techs': function() {
        this.updateOptimalityToleranceForRuns();
      },

      'portfolioRun.site.pv.can_net_meter': function() {
        this.updateInputValueForRuns('can_net_meter', 'pv', this.portfolioRun.site.pv.can_net_meter);
      },

      'portfolioRun.site.electric_tariff.custom_rate_type': function() {
        var self = this;
        this.updateInputValueForRuns('custom_rate_type', 'electric_tariff', this.portfolioRun.site.electric_tariff.custom_rate_type)

        if (this.portfolioRun.site.electric_tariff.custom_rate_type == 'urdb_label') {
          this.portfolio.runs.forEach(function(run, i) {
            if (run.grid && run.site.address) {
              // sel.$refs doesn't work here because when this is hit, the electricity rate dropdowns are not visible on the page yet.
              // need to find another place to call this, once the dropdowns are visible on the page.
              // self.$refs['portfolio-elec-rate'][i].fetchUtilityRates(run, i);
              self.fetchUtilityRates(run, i);
            }
            self.updateInputValueForRuns('custom_electricity_rate', 'electric_tariff', false);
          });
        } else if (this.portfolioRun.site.electric_tariff.custom_rate_type == 'annual') {
          this.portfolio.runs.forEach(function(run, i) {
            self.updateInputValueForRuns('custom_electricity_rate', 'electric_tariff', true);
          })
        }
      },
    },

    mounted: function() {
      this.setupLocationAutocomplete();
      
      if(this.portfolio.runs) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          if (run.grid && run.site.address) {
            self.$refs['portfolio-elec-rate'][i].fetchUtilityRates(run, i);
          }
        })
      }

      if(!this.portfolioRun.site.electric_tariff.custom_rate_type) {
        this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'custom_rate_type', value: 'urdb_label' });
      }
    }
  }
</script>