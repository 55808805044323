<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="string optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip
        :association_1="this.association_1"
        :association_2="this.association_2"
        :association_3="this.association_3"
        :attribute="this.attribute" />
    </div>
    <div class="col-sm-4">
      <input
        v-model="run[association_1][association_2][association_3][attribute]"
        @change="toggleDisplayDefault(); $emit('input-change');"
        @blur="validate()"
        class="form-control string optional"
        :placeholder="placeholder"
        type="text"
        :name="inputName"
        :id="inputID" />
      <span v-if="validateError" class="help-block">
        {{ validateErrorMessage }}
      </span>
    </div>
    <div v-if="placeholder && displayDefault" class="col-sm-2 default-value-block">
      <div
        class="default-value"
        :data-default-value="placeholder"
        :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    props: [
      'association_1',
      'association_2',
      'association_3',
      'attribute',
      'required'
    ],

    components: {
      Tooltip
    },

    data: function() {
      return {
        displayDefault: false,
        validateError: false
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'labels',
        'placeholders',
        'run',
        'selectOptions',
      ]),
      property :function() {
        return this.run[this.association_1][this.association_2][this.association_3][this.attribute];
      },
      label: function() {
        if(this.attribute == 'fuel_blended_annual_rates_per_mmbtu' && ((this.run.site.chp.existing_boiler_fuel_type || this.run.site.ghp.existing_boiler_fuel_type) == 'diesel_oil' || (this.run.site.chp.existing_boiler_fuel_type || this.run.site.ghp.existing_boiler_fuel_type) == 'propane')) {
          return "Annual existing heating system fuel cost ($/gallon)"
        } else if(this.attribute == 'emissions_factor_lb_CO2_per_mmbtu' && ((this.run.site.chp.existing_boiler_fuel_type || this.run.site.ghp.existing_boiler_fuel_type) == 'diesel_oil' || (this.run.site.chp.existing_boiler_fuel_type || this.run.site.ghp.existing_boiler_fuel_type) == 'propane')) {
          return "Heating system natural gas emissions factor (lbs CO₂/gallon)"
        } else if(this.association_3 == "boiler" && this.attribute == "fuel_percent_RE") {
          return `Existing heating ${this.findSelectOptionGivenValue()} percent renewable energy (%)`;
        } else {
          return this.labels[this.association_1][this.association_2][this.association_3][this.attribute];
        }
      },
      placeholder: function() {
        var value = this.placeholders[this.association_1][this.association_2][this.association_3][this.attribute];

        if(value) {
          if(['emissions_factor_lb_CO2_per_mmbtu', 'cop_electric'].indexOf(this.attribute) >= 0) {
            return this.$store.getters.formatAsIntegerWithCommas({value: value, decimals: 1});
          } else if(['max_thermal_factor_on_peak_load', 'cop_thermal'].indexOf(this.attribute) >= 0) {
            return this.$store.getters.formatAsIntegerWithCommas({value: value, decimals: 2});
          } else if(Number(value)) {
            if(Number(value).toString().split(".")[1]) {
              var decimalPlaces = Number(value).toString().split(".")[1].length;
            } else {
              var decimalPlaces = 0;
            }
            
            return this.$store.getters.formatAsIntegerWithCommas({value: value, decimals: decimalPlaces});
          } else {
            return value;
          }
        }
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.association_3}_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.association_3}_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.association_3}_attributes][${this.attribute}]`;
      },
      validateErrorMessage: function() {
        return this.clientSideValidations.run[this.association_1][this.association_2][this.association_3][this.attribute];
      }
    },

    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.association_2][this.association_3][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.association_2][this.association_3][this.attribute]) {
          this.validateError = false;
        }
      },
      toggleDisplayDefault: function() {
        var value = this.run[this.association_1][this.association_2][this.association_3][this.attribute];

        if(!value) {
          this.displayDefault = false;
        } else {
          this.displayDefault = true;
        }
      },
      findSelectOptionGivenValue: function() {
        var selected;
        var self = this;

        if(this.attribute == "fuel_percent_RE") {
          var attribute = 'existing_boiler_fuel_type';
        }
        var options = this.selectOptions.site.chp[attribute].options;

        options.forEach(function(el, i) {
          if(self.run.analyze_chp && el.value == self.run.site.chp[attribute]) {
            selected = el.label;
          } else if (self.run.analyze_ghp && el.value == self.run.site.ghp[attribute]) {
            selected = el.label;
          }
        })

        return selected;
      }
    },

    watch: {
      'property': function() {
        if(this.property) {
          this.displayDefault = true;
        } else {
          this.displayDefault = false;
        }
      },
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    },

    mounted: function() {
      // needed for when user enters value, then section disappears, then reappears
      // should display value in field & `default = XYZ` in side column
      if(this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    }
  }
</script>
