<template>
  <div class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="closeLoadProfile()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Cooling Plant Thermal Load Profile Based on Your Inputs</h4>
          </div>
          <div class="modal-body">
            <highcharts :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
            <div class="row row-flex text-center">
              <div class="col form-control-static"><strong>Avg Load:</strong> {{ meanTonHour.toFixed(2) }} Tons</div>
              <div class="col form-control-static"><strong>Min Load:</strong> {{ minTonHour.toFixed(2) }} Tons ({{ minTonHourDate }})</div>
              <div class="col form-control-static"><strong>Max Load:</strong> {{ maxTonHour.toFixed(2) }} Tons ({{ maxTonHourDate }})</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [ 'loadType' ],

    data: function() {
      return {
        chartOptions: {
          credits: {
            enabled: false
          },
          yAxis: {
            title: {
              text: 'Cooling Plant Thermal Load (Tons)',
            },
            plotLines: [{
              color: 'green',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Minimum Load',
              },
            }, {
              color: 'black',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Average Load',
              },
            }, {
              color: 'red',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Maximum Load',
              },
            }]
          },
          series: [
            {
              name: 'Cooling Plant Thermal Load',
              data: [],
              pointStart: 0,
              pointInterval: 60 * 60 * 1000,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' Tons'
              }
            }
          ]
        }
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'coldSimBuildLoadProfile',
        'coldSimBuildAnnualLoadProfile',
        'coldSimBuildMonthlyLoadProfile',
        'coldSimCampusFromElecLoadProfile',
        'coldSimCampusLoadProfile',
        'coldUploadedLoadProfile',
        'coldCustomAnnualPctLoadProfile',
        'coldCustomMonthlyPctLoadProfile',
        'months',
        'year'
      ]),
      valueInterval: function() {
        // Based on how big the series is, determine how much time each
        // value represents.
        var interval = 60 * 60 * 1000; // 1 hour
        if(this[this.loadType].loadsTonHour.length == 17520) {
          interval = 30 * 60 * 1000; // 30 minutes
        } else if(this[this.loadType].loadsTonHour.length == 35040) {
          interval = 15 * 60 * 1000; // 15 minutes
        }

        return interval
      },
      minTonHour: function() {
        return this[this.loadType].minTonHour;
      },
      maxTonHour: function() {
        return this[this.loadType].maxTonHour;
      },
      meanTonHour: function() {
        // parseFloat() meanTonHour because for some reason if a building is already selected for a simulate bulding cold load
        // and then the building is changed, the meanTonHour is as a string sometimes instead of a float.
        return parseFloat(this[this.loadType].meanTonHour);
      },
      minTonHourDate: function() {
        var minIndex = this[this.loadType].loadsTonHour.indexOf(this.minTonHour);
        var startDate = new Date(this.year, 0, 1);
        var minDate = new Date(startDate.getTime() + minIndex * this.valueInterval);

        return `${this.months[minDate.getMonth()].substring(0,3)} ${minDate.getDate()}`;
      },
      maxTonHourDate: function() {
        var maxIndex = this[this.loadType].loadsTonHour.indexOf(this.maxTonHour);
        var startDate = new Date(this.year, 0, 1);
        var maxDate = new Date(startDate.getTime() + maxIndex * this.valueInterval);

        return `${this.months[maxDate.getMonth()].substring(0,3)} ${maxDate.getDate()}`;
      }
    },

    methods: {
      closeLoadProfile: function() {
        if(this.loadType == "coldSimBuildLoadProfile") {
          var object = "showColdSimBuildLoadProfile";
        } else if(this.loadType == "coldSimBuildAnnualLoadProfile") {
          var object = "showColdSimBuildAnnualLoadProfile";
        } else if(this.loadType == "coldSimBuildMonthlyLoadProfile") {
          var object = "showColdSimBuildMonthlyLoadProfile";
        } else if(this.loadType == "coldSimCampusFromElecLoadProfile") {
          var object = "showColdSimCampusFromElecLoadProfile";
        } else if(this.loadType == "coldSimCampusLoadProfile") {
          var object = "showColdSimCampusLoadProfile";
        } else if(this.loadType == "coldUploadedLoadProfile") {
          var object = "showColdUploadedLoadProfile";
        } else if(this.loadType == "coldCustomAnnualPctLoadProfile") {
          var object = "showColdCustomAnnualPctLoadProfile";
        } else if(this.loadType == "coldCustomMonthlyPctLoadProfile") {
          var object = "showColdCustomMonthlyPctLoadProfile";
        }

        this.$store.commit('updateObject', { object: object, value: false });
      }
    },

    mounted: function() {
      this.chartOptions.yAxis.min = this[this.loadType].minTonHour;
      this.chartOptions.yAxis.max = this[this.loadType].maxTonHour;
      this.chartOptions.yAxis.plotLines[0].value = this[this.loadType].minTonHour;
      this.chartOptions.yAxis.plotLines[1].value = this[this.loadType].meanTonHour;
      this.chartOptions.yAxis.plotLines[2].value = this[this.loadType].maxTonHour;
      this.chartOptions.series[0].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[0].data = this[this.loadType].loadsTonHour;
    }
  }
</script>
