<template>
  <div class="form-group string optional" :class="formGroupClass">
    <input
      v-model="run[association_1][association_2][association_3][attribute]"
      class="form-control string optional"
      :placeholder="placeholder"
      type="text"
      :name="inputName"
      :id="inputID" />
    <div v-if="placeholder && run[association_1][association_2][association_3][attribute]" class="default-value-block">
      <div class="default-value" :data-default-value="placeholder" :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [
      'association_1',
      'association_2',
      'association_3',
      'attribute',
    ],

    data: function() {
      return {
        displayDefault: false,
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'placeholders'
      ]),
      placeholder: function() {
        return this.placeholders[this.association_1][this.association_2][this.association_3][this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.association_3}_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.association_3}_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.association_3}_attributes][${this.attribute}]`;
      }
    }
  }
</script>
