<template>
  <div class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="closeLoadProfile()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 v-if="loadType.includes('critical')" class="modal-title">Critical Electric Load Profile Based on Your Inputs</h4>
            <h4 v-else class="modal-title">Typical Electric Load Profile Based on Your Inputs</h4>
          </div>
          <div class="modal-body">
            <highcharts :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
            <div class="row row-flex text-center">
              <div class="col form-control-static"><strong>Avg Load:</strong> {{ Number(this.meanKw).toFixed(2) }} kW</div>
              <div class="col form-control-static"><strong>Min Load:</strong> {{ Number(this.minKw).toFixed(2) }} kW ({{ minKwDate }})</div>
              <div class="col form-control-static"><strong>Max Load:</strong> {{ Number(this.maxKw).toFixed(2) }} kW ({{ maxKwDate }})</div>
              <div v-if="showStartOutageOnPeakButton" class="col">
                <button @click="startOutageOnPeak($event)" type="button" class="btn btn-primary">Start Outage On Peak</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: [ 'loadType' ],

    data: function() {
      return {
        chartOptions: {
          credits: {
            enabled: false
          },
          yAxis: {
            title: {
              text: 'Power (Kilowatts)',
            },
            plotLines: [{
              color: 'green',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Minimum Load',
              },
            }, {
              color: 'black',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Average Load',
              },
            }, {
              color: 'red',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: 'Maximum Load',
              },
            }]
          },
          series: [
            {
              name: 'Power',
              data: [],
              pointStart: 0,
              pointInterval: this.valueInterval,
              lineWidth: 1,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              dataGrouping: {
                enabled: false,
              },
              tooltip: {
                valueDecimals: 4,
                valueSuffix: ' kW'
              }
            }
          ]
        }
      }
    },

    computed: {
      ...mapGetters([
        'criticalPercentLoadProfile',
        'criticalUploadedLoadProfile',
        'criticalBuildLoadProfile',
        'run',
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',
        'showStartOutageOnPeakButton',
        'months',
        'year'
      ]),
      valueInterval: function() {
        // Based on how big the series is, determine how much time each
        // value represents.
        var interval = 60 * 60 * 1000; // 1 hour
        if(this[this.loadType].loadsKw.length == 17520) {
          interval = 30 * 60 * 1000; // 30 minutes
        } else if(this[this.loadType].loadsKw.length == 35040) {
          interval = 15 * 60 * 1000; // 15 minutes
        }

        return interval
      },
      loadsKw: function() {
        // Need this in order to find the adjusted min/max/mean values in loadsKw.
        // When minKw, maxKw, meanKw are multiplied by the factor, but there is no
        // adjusted loadsKw, then it's unable to find the index of that value to 
        // display the correct min/max dates.
        if(Number(this.run.site.load_profile.load_reduction) == 100) {
          return this[this.loadType].loadsKw
        } else {
          var factor = this.run.site.load_profile.load_reduction / 100.0;
          var adjustedLoadsKw = this[this.loadType].loadsKw.map(function(el, i) {
            return el * factor;
          })
          return adjustedLoadsKw;
        }
      },
      minKw: function() {
        var factor = this.run.site.load_profile.load_reduction / 100.0;
        if(Number(this.run.site.load_profile.load_reduction) == 100) {
          return this[this.loadType].minKw;
        } else {
          return this[this.loadType].minKw * factor;
        }
      },
      minKwDate: function() {
        var minIndex = this.loadsKw.indexOf(parseFloat(this.minKw));
        var startDate = new Date(this.year, 0, 1);
        var minDate = new Date(startDate.getTime() + minIndex * this.valueInterval);

        return `${this.months[minDate.getMonth()].substring(0,3)} ${minDate.getDate()}`;
      },
      maxKwDate: function() {
        var maxIndex = this.loadsKw.indexOf(parseFloat(this.maxKw));
        var startDate = new Date(this.year, 0, 1);
        var maxDate = new Date(startDate.getTime() + maxIndex * this.valueInterval);

        return `${this.months[maxDate.getMonth()].substring(0,3)} ${maxDate.getDate()}`;
      },
      maxKw: function() {
        var factor = this.run.site.load_profile.load_reduction / 100.0;
        if(Number(this.run.site.load_profile.load_reduction) == 100) {
          return this[this.loadType].maxKw;
        } else {
          return this[this.loadType].maxKw * factor;
        }
      },
      meanKw: function() {
        var factor = this.run.site.load_profile.load_reduction / 100.0;
        if(Number(this.run.site.load_profile.load_reduction) == 100) {
          return this[this.loadType].meanKw;
        } else {
          return this[this.loadType].meanKw * factor;
        }
      }
    },

    methods: {
      closeLoadProfile: function() {
        if(this.loadType == "criticalPercentLoadProfile") {
          var object = "showCriticalPercentLoadProfile";
        } else if(this.loadType == "criticalUploadedLoadProfile") {
          var object = "showCriticalUploadedLoadProfile";
        } else if(this.loadType == "criticalBuildLoadProfile") {
          var object = "showCriticalBuildLoadProfile";
        } else if(this.loadType == "elecSimBuildAnnualLoadProfile") {
          var object = "showElecSimBuildAnnualLoadProfile";
        } else if(this.loadType == "elecSimBuildMonthlyLoadProfile") {
          var object = "showElecSimBuildMonthlyLoadProfile";
        } else if(this.loadType == "elecSimCampusLoadProfile") {
          var object = "showElecSimCampusLoadProfile";
        } else if(this.loadType == "elecUploadedLoadProfile") {
          var object = "showElecUploadedLoadProfile";
        }

        this.$store.commit('updateObject', { object: object, value: false });
        this.$store.commit('updateObject', { object: 'showStartOutageOnPeakButton', value: false });
      },
      startOutageOnPeak: function(event) {
        var max = this[this.loadType].maxKw;
        var maxIndex = this[this.loadType].loadsKw.indexOf(max);
        var startDate = new Date(this.year, 0, 1);
        var maxDate = new Date(startDate.getTime() + maxIndex * 3600000);
        maxDate = new Date(maxDate.getTime());

        this.$emit('start-outage-on-peak', event, maxDate);
        this.closeLoadProfile();
      }
    },

    mounted: function() {
      this.chartOptions.yAxis.min = this[this.loadType].minKw;
      this.chartOptions.yAxis.max = this[this.loadType].maxKw;
      this.chartOptions.yAxis.plotLines[0].value = this[this.loadType].minKw;
      this.chartOptions.yAxis.plotLines[1].value = this[this.loadType].meanKw;
      this.chartOptions.yAxis.plotLines[2].value = this[this.loadType].maxKw;
      this.chartOptions.series[0].pointStart = new Date(Date.UTC(this.year, 0, 1)).getTime();
      this.chartOptions.series[0].pointInterval = this.valueInterval;
      this.chartOptions.series[0].data = this[this.loadType].loadsKw;

      // display adjusted load profile if load_reduction has been changed by user
      if(Number(this.run.site.load_profile.load_reduction) !== 100) {
        if(this.run.site.load_profile.load_reduction > 100) {
          this.chartOptions.yAxis.min = this.minKw / (this.run.site.load_profile.load_reduction / 100.0);
          this.chartOptions.yAxis.max = this.maxKw;
        } else {
          this.chartOptions.yAxis.min = this.minKw;
          this.chartOptions.yAxis.max = this.maxKw / (this.run.site.load_profile.load_reduction / 100.0);
        }
        this.chartOptions.yAxis.plotLines[0].value = this.minKw;
        this.chartOptions.yAxis.plotLines[1].value = this.meanKw;
        this.chartOptions.yAxis.plotLines[2].value = this.maxKw;
        this.chartOptions.series[0].name = "Original Power";

        var originalSeries = { ...this.chartOptions.series[0] };
        var adjustedSeries = { ...this.chartOptions.series[0] };

        adjustedSeries.name = "Adjusted Power";
        var factor = this.run.site.load_profile.load_reduction / 100.0;
        adjustedSeries.data = adjustedSeries.data.map(function(el, i) {
          return el * factor;
        })
        var series = [adjustedSeries, originalSeries]

        this.chartOptions.series = series;
      }
    }
  }
</script>
