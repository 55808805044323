<template>
  <div class="custom-monthly-rate" style="margin-top: 5px;">
    <div class="container">
      <div class="row">
        <div class="col-xs-8 col-sm-8" style="text-align: right;">
        </div>
        <div class="col-xs-2 col-sm-2">
          <p>Monthly heating boiler fuel consumption (MMBtu)</p>
        </div>
        <div class="col-xs-2 col-sm-2">
          <p>Addressable Load Percent (%)</p>
        </div>
      </div>
    </div>
    <div v-for="(month, i) in months" class="container monthly-entry-rows">
      <div class="row">
        <div class="col-xs-8 col-sm-8" style="text-align: right;">
          {{ month }}
        </div>
        <div class="col-xs-2 col-sm-2 monthly-entry-row">
          <input v-model="run.site.load_profile.load_profile_boiler_fuel[`thermal_mmbtu_monthly_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly boiler fuel consumption (MMBtu)`" type="number" step="any" :name="fuelName(i)" />
        </div>
        <div class="col-xs-2 col-sm-2 monthly-entry-row">
          <input v-model="run.site.load_profile.load_profile_boiler_fuel[`addressable_load_fraction_monthly_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-percent-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} addressable load fraction`" placeholder="100%" type="number" step="any" :name="percentName(i)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  export default {
    components: {
      Tooltip
    },
    props: ['type'],
    data: function() {
      return {
        months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
      }
    },
    computed: {
      ...mapGetters([
        'run',
      ]),
    },
    methods: {
      fuelName: function(i) {
        return `run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][thermal_mmbtu_monthly_${i + 1}]`;
      },
      percentName: function(i) {
        return `run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][addressable_load_fraction_monthly_${i + 1}]`;
      }
    }
  }
</script>