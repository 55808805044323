<template>
  <div>
    <horizontal-string-input-nested-3
      association_1='site'
      association_2='load_profile'
      association_3='load_profile_boiler_fuel'
      attribute='campus_annual_mmbtu'
      v-on:input-change="getThermalSimCampusLoad()"
      required='true' />
    <input
      v-model="run.site.load_profile.load_profile_boiler_fuel.campus_space_annual_mmbtu"
      type="hidden"
      name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][campus_space_annual_mmbtu]"
      id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_campus_space_annual_mmbtu" />
    <input
      v-model="run.site.load_profile.load_profile_boiler_fuel.campus_dhw_annual_mmbtu"
      type="hidden"
      name="run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][campus_dhw_annual_mmbtu]"
      id="run_site_attributes_load_profile_attributes_load_profile_boiler_fuel_attributes_campus_dhw_annual_mmbtu" />
    <div class="row">
      <div class="col-sm-offset-3 col-sm-7">
        <table id="thermal-campus" class="simulated-campus">
          <thead>
            <tr>
              <th>Select Building Type <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'campus_select_building'"></tooltip></th>
              <th>% of Total Heating System Fuel Consumption (MMBtu) <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'percent_of_energy_consump'"></tooltip></th>
            </tr>
          </thead>
          <tbody class="fields">
            <tr v-for="(building, i) in run.site.load_profile.load_profile_boiler_fuel.campus_buildings" >
              <td class="campus-building-type">
                <select v-model="building.doe_reference_name" @change="getThermalSimCampusLoad()" label="false" class="select optional" :name="`run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" >
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
              </td>
              <td>
                <input v-model="building.percent_of_total" @change="getThermalSimCampusLoad()" label="false" class="string optional building-percent" type="text" :name="`run[site_attributes][load_profile_attributes][load_profile_boiler_fuel_attributes][campus_buildings_attributes][${i}][percent_of_total]`" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-offset-3">
        <div class="campus-table-actions">
          <button type="button" @click="addBuilding()" class="btn btn-primary">Add Building</button>
          <button type="button" @click="removeBuilding()" class="remove-campus-building btn btn-primary">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import HorizontalStringInputNested3 from './HorizontalStringInputNested3';

  export default {
    components: {
      HorizontalStringInputNested3,
      Tooltip
    },

    computed: {
      ...mapGetters([
        'thermalSimCampusLoadProfile',
        'run',
        'selectOptions',
        'urlRoot',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'placeholders',
        'dhwAnnualMMbtuForRatio'
      ]),
      options: function() {
        return this.selectOptions.site.load_profile.doe_reference_name.options;
      },
      watchDependencies:  function() {
        return this.run.site.latitude, 
        this.run.site.longitude,
        new Date();
      },
    },

    methods: {
      addBuilding: function() {
        if(this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.length == 5) {
          bootbox.alert("Can only have a maximum of 5 building types.");
          return false;
        }

        var campusBuildings = [
          ...this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings,
          {
            doe_reference_name: '',
            percent_of_total: '',
          }
        ]

        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          association_3: 'load_profile_boiler_fuel',
          attribute: 'campus_buildings',
          value: campusBuildings,
        }
        this.$store.commit('updateValueNested3', payload);
        this.getThermalSimCampusLoad();
      },
      removeBuilding: function() {
        if(this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.length == 1) {
          bootbox.alert("Must have at least 1 building type.");
          return false;
        }

        this.$store.commit('removeThermalCampusBuilding');
        this.getThermalSimCampusLoad();
      },
      verifyAllBuildingsSelected: function() {
        var selected = this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.map(function(el, i) {
          if(el.doe_reference_name) {
            return true;
          } else {
            return false;
          }
        })

        if(selected.includes(false)) {
          return false;
        } else {
          return true;
        }
      },
      verifyPercentsSumTo100: function() {
        var sum = this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.map(function(el, i) {
          return Number(el.percent_of_total);
        }).reduce(function(total, num) {
          return total + num;
        })

        if(sum == 100) {
          return true;
        } else {
          return false;
        }
      },
      updateStoreNested3: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          association_3: payload.association_3,
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested3', payload);
      },
      getThermalSimCampusLoad: function() {
        if(this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.load_profile_boiler_fuel.campus_annual_mmbtu && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
          this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });

          var doeReferenceNames = this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.map(function(el, i) {
            return el.doe_reference_name
          })
          var percentShares = this.run.site.load_profile.load_profile_boiler_fuel.campus_buildings.map(function(el, i) {
            return el.percent_of_total/100.0
          })

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            campus_annual_mmbtu: this.run.site.load_profile.load_profile_boiler_fuel.campus_annual_mmbtu,
            doe_reference_names: doeReferenceNames,
            percent_shares: percentShares,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + '/simulated-campus-thermal-load',
            contentType: 'application/json',
            data: params,
            success: function handleResults(data) {
              var payload = {
                loadsMMBtu: data.loads_mmbtu_per_hour,
                minMMBtu: data.min_mmbtu_per_hour,
                meanMMBtu: data.mean_mmbtu_per_hour,
                maxMMBtu: data.max_mmbtu_per_hour,
                dhwLoadsMMBtu: data.dhw_loads_mmbtu_per_hour,
                dhwMinMMBtu: data.dhw_min_mmbtu_per_hour,
                dhwMaxMMBtu: data.dhw_max_mmbtu_per_hour,
                dhwMeanMMBtu: data.dhw_mean_mmbtu_per_hour,
                spaceLoadsMMBtu: data.space_loads_mmbtu_per_hour,
                spaceMinMMBtu: data.space_min_mmbtu_per_hour,
                spaceMaxMMBtu: data.space_max_mmbtu_per_hour,
                spaceMeanMMBtu: data.space_mean_mmbtu_per_hour,
              }

              var campusDhwAnnualMmbtu = data.dhw_annual_mmbtu;
              var campusSpaceAnnualMmbtu = data.space_annual_mmbtu;

              self.updateStoreNested3({
                association_3: 'load_profile_boiler_fuel',
                attribute: 'campus_dhw_annual_mmbtu',
                value: Math.round(campusDhwAnnualMmbtu)
              });

              self.updateStoreNested3({
                association_3: 'load_profile_boiler_fuel',
                attribute: 'campus_space_annual_mmbtu',
                value: Math.round(campusSpaceAnnualMmbtu)
              });

              self.$store.commit('updateObject', { object: 'thermalSimCampusLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });

              if (self.run.analyze_ghp) {
                var annualMmbtuForRatio = data.annual_mmbtu;
                self.$store.commit('updateObject', { object: 'dhwAnnualMMbtuForRatio', value: campusDhwAnnualMmbtu });
                self.$store.commit('updateObject', { object: 'annualMmbtuForRatio', value: annualMmbtuForRatio });
              }

              if (self.run.analyze_ghp && self.annualTonHourForRatio) {
                self.updateRatioHeatingToCooling();
              }
            },
            error: function() {
              bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
              self.$store.commit('updateObject', { object: 'thermalSimCampusLoadProfile', value: {} });
            },
          })
        } else {
          this.$store.commit('updateObject', { object: 'thermalSimCampusLoadProfile', value: {} });
        }
      },
      updateRatioHeatingToCooling: function() {
        var annualMmbtu = this.annualMmbtuForRatio;
        var annualTonHour = this.annualTonHourForRatio;

        if (this.run.site.ghp.boiler.boiler_efficiency) {
          var boilerEfficiency = this.run.site.ghp.boiler.boiler_efficiency / 100;
        } else {
          var boilerEfficiency = this.placeholders.site.ghp.boiler.boiler_efficiency.split('%')[0] / 100;
        }

        if (this.run.site.ghp.space_heating_efficiency_thermal_factor) {
          var heatingThermalFactor = this.run.site.ghp.space_heating_efficiency_thermal_factor / 100;
        } else {
          var heatingThermalFactor = this.placeholders.site.ghp.space_heating_efficiency_thermal_factor.split('%')[0] / 100;
        }

        if (this.run.site.ghp.cooling_efficiency_thermal_factor) {
          var coolingThermalFactor = this.run.site.ghp.cooling_efficiency_thermal_factor / 100;
        } else {
          var coolingThermalFactor = this.placeholders.site.ghp.cooling_efficiency_thermal_factor.split('%')[0] / 100;
        }
        if (!this.run.site.ghp.can_serve_dhw) {
          var calculateRatio = ((annualMmbtu - this.dhwAnnualMMbtuForRatio) * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
        } else {
          var calculateRatio = (annualMmbtu * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
        }
        this.$store.commit('updateObject', { object: 'ratioHeatingToCooling', value: calculateRatio });
      },
    },

    watch: {
      watchDependencies: function() {
        var heating_type = this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type;
        if(heating_type == "simulated_campus") {
          this.getThermalSimCampusLoad();
        }
      }
    },

    watch: {
      'run.site.address': function() {
        var heating_type = this.run.site.load_profile.load_profile_boiler_fuel.thermal_load_type;
        if(heating_type == "simulated_campus") {
          this.getThermalSimCampusLoad();
        }
      }
    },

    mounted: function() {
      if(this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.load_profile_boiler_fuel.campus_annual_mmbtu && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
        this.getThermalSimCampusLoad();
      }
    }
  }
</script>
