<template>
  <div>
    <div class="col-sm-offset-6 col-sm-4" style="margin-bottom: 15px">
      <input
        v-model="run.site.load_profile.custom_multiple_outage_dates"
        class="checkbox-inline"
        type="checkbox"
        name="run[site_attributes][load_profile_attributes][custom_multiple_outage_dates]"
        id="run_site_attributes_load_profile_attributes_custom_multiple_outage_dates" />
      <label class="custom-electricity-rate-label" for="run_site_attributes_load_profile_attributes_custom_multiple_outage_dates">
        Use custom outage dates
      </label>
      <tooltip
        association_1="site"
        association_2="load_profile"
        attribute="custom_multiple_outage_dates" />
    </div>
    <template v-for="(outage, i) in run.site.load_profile.multiple_outages">
      <input
        v-model="outage.outage_duration"
        type="hidden"
        :name="`run[site_attributes][load_profile_attributes][multiple_outages_attributes][${i}][outage_duration]`"
        :id="`multiple_outages_${i}_outage_duration`" />
    </template>
    <table class="table multiple-outages-inputs">
      <thead>
        <tr>
          <th scope="col" class="multioutage" style="width: 16%;"></th>
          <template v-for="outage in outages">
            <th scope="col" class="multioutage" style="text-align: center">{{ outage.id }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="form-horizontal multioutage">
            <label class="string multioutage">
              <abbr title="Required" class="required">*</abbr>
              Outage start date<tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'outage_start_date'"></tooltip>
            </label>
          </th>
          <template v-for="(outage, i) in run.site.load_profile.multiple_outages">
            <td class="multioutage">
              <div class="input-group">
                <div id="date-picker" class="has-feedback has-clear">
                  <flat-pickr
                    v-model="outage.outage_start_date"
                    @input="$emit('input-change'); getCustomOutageTimeSteps()"
                    :disabled = "!run.site.load_profile.custom_multiple_outage_dates"
                    :name="`run[site_attributes][load_profile_attributes][multiple_outages_attributes][${i}][outage_start_date]`"
                    :id="`multiple_outages_${i}_outage_start_date`"
                    :config="flatPickrConfig"/>
                </div>
                <span @click="" class="input-group-addon" data-toggle>
                  <span class="glyphicon glyphicon-calendar" aria-hidden="true">
                  </span>
                </span>
              </div>
              <!--<span v-if="`validateOutageDateError_${i}`" class="help-block">
                {{ validateOutageDateErrorMessage }}
              </span>-->
            </td>
          </template>
        </tr>
        <tr>
          <th scope="row" class="form-horizontal multioutage">
            <label class="string multioutage">
              <abbr title="Required" class="required">*</abbr>
              Outage start time<tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'outage_start_hour'"></tooltip>
            </label>
          </th>
          <template v-for="(outage, i) in run.site.load_profile.multiple_outages">
            <input
              v-model="outage.outage_start_hour"
              :name="`run[site_attributes][load_profile_attributes][multiple_outages_attributes][${i}][outage_start_hour]`"
              id="outage_start_hour_hidden"
              type="hidden" />
            <td class="multioutage">
              <select
                v-model="outage.outage_start_hour"
                @change="$emit('input-change'); getCustomOutageTimeSteps()"
                :disabled = "!run.site.load_profile.custom_multiple_outage_dates"
                class="form-control select optional"
                :name="`run[site_attributes][load_profile_attributes][multiple_outages_attributes][${i}][outage_start_hour]`"
                :id="`multiple_outages_${i}_outage_start_hour`">
                <option v-for="option in options" :value="option['value']">
                  {{ option['label'] }}
                </option>
              </select>
              <!-- <span v-if="validateOutageHourError_1" class="help-block">
                {{ validateOutageHourErrorMessage }}
              </span> -->
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <template v-for="(outage, i) in run.site.load_profile.multiple_outages">
      <input
        v-model="outage.outage_start_time_step"
        type="hidden"
        :name="`run[site_attributes][load_profile_attributes][multiple_outages_attributes][${i}][outage_start_time_step]`"
        :id="`multiple_outages_${i}_outage_start_time_step`" />
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from "../Tooltip";
  import IncentivesTableStringInputNested2 from "./IncentivesTableStringInputNested2";
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';


  export default {
    components: {
      Tooltip,
      IncentivesTableStringInputNested2,
      flatPickr,
    },

    props: ["number_of_outages"],

    data: function() {
      return {
        outages: [
          { id: 'Outage 1' }, 
          { id: 'Outage 2' }, 
          { id: 'Outage 3' }, 
          { id: 'Outage 4' },
        ]
      }
    },
    
    computed: {
      ...mapGetters([
        'clientSideValidations',
        'run',
        'selectOptions',
        'flatPickrConfig'
      ]),
      options: function() {
        return this.selectOptions.site.load_profile.outage_start_hour.options;
      },
    },

    methods: {
      verifyAllOutagesSelected: function() {
        var selected = this.run.site.load_profile.multiple_outages.map(function(el, i) {
          if(el.outage_start_date.length != 0 && el.outage_start_hour.length != 0) {
            return true;
          } else {
            return false;
          }
        })
        
        if(selected.includes(false)) {
          return false;
        } else {
          return true;
        }
      },
      getCustomOutageTimeSteps: function() {
        if(this.run.site.load_profile.custom_multiple_outage_dates && this.verifyAllOutagesSelected()) {
          var outage_start_dates = this.run.site.load_profile.multiple_outages.map(function(el, i) {
            return el.outage_start_date;
          })
          var outage_start_hours = this.run.site.load_profile.multiple_outages.map(function(el, i) {
            return el.outage_start_hour;
          })
        
          var self = this;
          for(var i=0; i < 4; i++){
            let dateUTC = new Date(outage_start_dates[i] + 'T00:00:00.000');
            var dayOfYear = (Date.UTC(dateUTC.getFullYear(), dateUTC.getMonth(), dateUTC.getDate()) - Date.UTC(dateUTC.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
            var payload = {
              index: i,
              outage_duration: Number(self.run.site.load_profile.outage_duration),
              outage_start_date: outage_start_dates[i],
              outage_start_hour: outage_start_hours[i],
              outage_start_time_step: (dayOfYear - 1) * 24 + outage_start_hours[i],
            }
            self.$store.commit('updateOutageDateHour', payload);
          }
        } else {
          return false;
        }
      },
      validateOutageDate: function(i) {
        if(!this.run.site.load_profile.multiple_outages[outage_start_date]) {
          this[`validateOutageDateError_${i}`] = true;
        } else if(this.run.site.load_profile.multiple_outages[outage_start_date]){
          this[`validateOutageDateError_${i}`] = false;
        }
      },
      validateOutageHour: function(i) {
        if(!this.run.site.load_profile[`outage_start_hour_${i}`]) {
          this[`validateOutageHourError_${i}`] = true;
        } else if(this.run.site.load_profile[`outage_start_hour_${i}`]) {
          this[`validateOutageHourError_${i}`] = false;
        }
      },
      
    },

    watch: {
      'validateOutageDateError': function() {
        if(this.validateOutageDateError) {
          document.getElementsByClassName('run_site_load_profile_multiple_outages_outage_start_date')[0].classList.add('has-error');
        } else {
          document.getElementsByClassName('run_site_load_profile_multiple_outages_outage_start_date')[0].classList.remove('has-error');
        }
      },
      'validateOutageHourError': function() {
        if(this.validateOutageHourError) {
          document.getElementsByClassName('run_site_load_profile_outage_start_hour')[0].classList.add('has-error');
        } else {
          document.getElementsByClassName('run_site_load_profile_outage_start_hour')[0].classList.remove('has-error');
        }
      }
    },

    mounted: function() {
      // for copied runs
      if(this.run.site.load_profile.outage_duration) {
        if(this.run.site.load_profile.custom_multiple_outage_dates) {
          this.getCustomOutageTimeSteps();
        }
      }
    }
  };
</script>
