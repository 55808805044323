<template>
  <div class="form-group select optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="select optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip
        :association_1="this.association_1"
        :association_2="this.association_2"
        :association_3="this.association_3"
        :attribute="this.attribute" />
    </div>
    <div class="col-sm-4">
      <select
        v-model="run[association_1][association_2][association_3][attribute]"
        @change="$emit('input-change');"
        @blur="validate()"
        class="form-control select optional"
        :name="inputName"
        :id="inputID">
        <option v-for="option in options" :value="option['value']">
          {{ option['label'] }}
        </option>
      </select>
      <p v-if="attribute == 'doe_reference_name'" class="building-details">
        <a href="#" rel="tooltip" :title="tooltips.site.load_profile.building_details" target="_blank">
          Building Details
        </a>
      </p>
      <span v-if="validateError" class="help-block">
        {{ validateErrorMessage }}
      </span>
    </div>
    <div v-if="run.site.load_profile.doe_reference_name && attribute == 'doe_reference_name'" class="col-sm-2">
      <div class="default-value-block">
        Recommended = {{ recDoeRefName }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    data: function() {
      return {
        validateError: false,
      }
    },

    components: {
      Tooltip
    },

    props: [
      'association_1',
      'association_2',
      'association_3',
      'attribute',
      'required'
    ],

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'labels',
        'run',
        'selectOptions',
        'tooltips'
      ]),
      label: function() {
        return this.labels[this.association_1][this.association_2][this.association_3][this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.association_3}_${this.attribute}`;
      },
      inputID: function() {
        return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.association_3}_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.association_3}_attributes][${this.attribute}]`;
      },
      options: function() {
        return this.selectOptions[this.association_1][this.association_2][this.association_3][this.attribute]["options"];
      },
      validateErrorMessage: function() {
        return this.clientSideValidations.run[this.association_1][this.association_2][this.association_3][this.attribute];
      },
      recDoeRefName: function() {
        var self = this;
        return this.selectOptions.site.load_profile.doe_reference_name.options.find(function(el) {
          return el.value == self.run.site.load_profile.doe_reference_name;
        }).label;
      }
    },

    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.association_2][this.association_3][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.association_2][this.association_3][this.attribute]) {
          this.validateError = false;
        }
      }
    },

    watch: {
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    }
  }
</script>
