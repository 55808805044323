<template>
  <div class="form-horizontal">
    <h4>Renewable Electricity or Emissions Target</h4>
    <horizontal-select-input
      attribute='clean_energy_target'
      v-on:input-change="toggleInputs()"/>
    <horizontal-string-input-nested-1
      association_1='site'
      attribute='renewable_electricity_min_fraction' />
    <horizontal-string-input-nested-1
      association_1='site'
      attribute='renewable_electricity_max_fraction' />
    <horizontal-string-input-nested-1
      association_1='site'
      attribute='CO2_emissions_reduction_min_fraction' />
    <horizontal-string-input-nested-1
      association_1='site'
      attribute='CO2_emissions_reduction_max_fraction' />

    <br>
    <h4>Treatment of Emissions Costs</h4>
    <horizontal-select-input
      attribute='include_climate_in_objective' />
    <horizontal-select-input
      attribute='include_health_in_objective' />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested1 from './inputs/HorizontalStringInputNested1';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import HorizontalSelectInput from './inputs/HorizontalSelectInput';

  export default {
    components: {
      HorizontalSelectInput,
      HorizontalSelectInputNested2,
      // nested 1 b/c run.site - measured from run
      HorizontalStringInputNested1,
      HorizontalStringInputNested2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
      }
    },

    computed: {
      ...mapGetters([
        'run'
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      toggleInputs: function() {
        // its odd this is needed - another weird Vue reactivity issue, but if
        // v-if is used then the tooltips don't toggle properly (another thing that will prob go away w/ Vue-Bootstrap)
        if(this.run.clean_energy_target == 'emissions') {
          document.getElementById('run_site_attributes_renewable_electricity_min_fraction').disabled = true;
          document.getElementsByClassName('run_site_renewable_electricity_min_fraction')[0].style.display = 'none';
          document.getElementById('run_site_attributes_renewable_electricity_max_fraction').disabled = true;
          document.getElementsByClassName('run_site_renewable_electricity_max_fraction')[0].style.display = 'none';

          document.getElementById('run_site_attributes_CO2_emissions_reduction_min_fraction').disabled = false;
          document.getElementsByClassName('run_site_CO2_emissions_reduction_min_fraction')[0].style.display = '';
          document.getElementById('run_site_attributes_CO2_emissions_reduction_max_fraction').disabled = false;
          document.getElementsByClassName('run_site_CO2_emissions_reduction_max_fraction')[0].style.display = '';
        } else if(this.run.clean_energy_target == 're') {
          document.getElementById('run_site_attributes_CO2_emissions_reduction_min_fraction').disabled = true;
          document.getElementsByClassName('run_site_CO2_emissions_reduction_min_fraction')[0].style.display = 'none';
          document.getElementById('run_site_attributes_CO2_emissions_reduction_max_fraction').disabled = true;
          document.getElementsByClassName('run_site_CO2_emissions_reduction_max_fraction')[0].style.display = 'none';

          document.getElementById('run_site_attributes_renewable_electricity_min_fraction').disabled = false;
          document.getElementsByClassName('run_site_renewable_electricity_min_fraction')[0].style.display = '';
          document.getElementById('run_site_attributes_renewable_electricity_max_fraction').disabled = false;
          document.getElementsByClassName('run_site_renewable_electricity_max_fraction')[0].style.display = '';
        }
      }
    },

    watch: {
      'run.clean_energy_target': function() {
        if (this.run.clean_energy_target === 're' && (this.run.site.CO2_emissions_reduction_min_fraction || this.run.site.CO2_emissions_reduction_max_fraction)) {
          this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'CO2_emissions_reduction_min_fraction', value: null })
          this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'CO2_emissions_reduction_max_fraction', value: null })
        } else if (this.run.clean_energy_target === 'emissions' && (this.run.site.renewable_electricity_min_fraction || this.run.site.renewable_electricity_max_fraction)) {
          this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'renewable_electricity_min_fraction', value: null });
          this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'renewable_electricity_max_fraction', value: null });
        }
      }
    },

    mounted: function() {
      if(!this.run.clean_energy_target) {
        this.$store.commit('updateValue', { attribute: 'clean_energy_target', value: 're' });
        $("#run_clean_energy_target").val('re')
      }

      this.toggleInputs();
    }
  }
</script>
